/* eslint-disable max-nested-callbacks */
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from 'react-toastify';
import { Card, CardBody, CardHeader, Col, Container, Input, Row } from 'reactstrap';
import android1 from '../../../assets/images/android1.png';
import toastMessages from '../../../common/messages/toastMessages';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import CustomStepperComponent from '../../../Components/Common/CustomStepperComponent';
import Loader from '../../../Components/Common/Loader';
import NoRecordsFound from '../../../Components/Common/NoRecordsFound';
import TableContainer from '../../../Components/Common/TableContainer';
import { getAppProtectionPathParams } from '../../../Components/Common/Util';
import { useEnv } from '../../../envContext';
import { APIClient } from '../../../helpers/api_helper';
import * as domains from '../../../helpers/domain_helper';
import * as url from '../../../helpers/url_helper';
import { DynamicComponents } from './AppComponents';
import { ACCESS_REQ_SCHEMA, ALLOW, DATA_PROTECT_SCHEMA, EMPTY_OBJ, groupsSchema, NOT_REQUIRED, REQUIRED, RESTRICT } from './Schema';
import EllipsisToolTip from '../../../Components/Common/Tooltip/Tooltip';

const AddConditionalAccess = () => {
    const urlconf = useEnv();
    const api = new APIClient();
    const [formValues, setFormValues] = useState({ ...EMPTY_OBJ });
    const [appsArray, setAppsArray] = useState([]);
    const [groups, setGroups] = useState([]);
    const [loading, setLoading] = useState(false);
    const [applications, setApplications] = useState([]);
    const [applicationsBackup, setApplicationsBackup] = useState([]);
    const history = useHistory();
    const [locations, setLocations] = useState([]);
    const formTypeAndId = getAppProtectionPathParams(window.location.hash);
    const [editIPDetails, setEditIPDetails] = useState('');
    const formType = formTypeAndId['formType'];
    document.title = `${formType === 'add' ? 'Add ' : 'Edit '}Conditional Access`;

    useEffect(() => {
        setLoading(true);
        handlePromise();
    }, []);

    const handlePromise = () => {
        const appsPromise = new Promise((resolve, reject) => {
            /*
             * api.get(url.APP_PROTECTION_APPS, '', domains.MDM_COMMONS)
             *     .then((resp) => {
             *         resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
             *         if (resp.data) resolve(resp.data);
             *         else reject('Apps failed');
             *     })
             *     .catch((err) => reject(err));
             */
            reject('no apps for now');
        });

        const groupsPromise = new Promise((resolve, reject) => {
            api.get(url.APP_PROTECTION_GROUPS, '', domains.MDM_COMMONS)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp?.data) resolve(resp.data);
                    else reject('Groups failed.');
                })
                .catch((err) => reject(err));
        });

        const locationsPromise = new Promise((resolve, reject) => {
            api.get(url.MS_CONDITIONAL_ACCESS + '/locations', '', domains.MDM_COMMONS)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp.status === 'success') resolve(resp);
                    else reject('Locations failed');
                })
                .catch((err) => reject(err));
        });

        const policyPromise = new Promise((resolve, reject) => {
            if (formTypeAndId.recordID)
                api.get(url.MS_CONDITIONAL_ACCESS + '/' + formTypeAndId?.recordID, '', domains.MDM_COMMONS)
                    .then((resp) => {
                        resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                        if (resp.status === 'success') resolve(resp);
                        else reject('get Access failed');
                    })
                    .catch((err) => reject(err));
            else reject('No Policy');
        });

        Promise.allSettled([appsPromise, groupsPromise, locationsPromise, policyPromise])
            .then(async (result) => {
                if (result[0].status === 'fulfilled') {
                    result[0].value = result[0].value?.filter((app) =>
                        formTypeAndId.platform === 'android' ? app.packageId : formTypeAndId.platform === 'ios' ? app.bundleId : ''
                    );
                    setApplications(result[0].value);
                    setApplicationsBackup(result[0].value);
                }
                if (result[1].status === 'fulfilled') {
                    let assigned =
                        result[3].status === 'fulfilled' ? result[3].value.data?.conditions?.users?.includeGroups?.join(',') : '';
                    result[1].value.forEach((group) => {
                        group.members = group.members || '—';
                        group.checked = assigned?.includes(group.groupId);
                    });
                    setGroups(result[1].value);
                }
                let locs = result[2].status === 'fulfilled' ? result[2].value?.data : [];
                let savedLocs = [];
                let includeLocs = [];
                let excludeLocs = [];
                if (result[2].status === 'fulfilled') {
                    if (result[3].status === 'fulfilled') {
                        includeLocs =
                            result[3].value.data.conditions?.locations?.includeLocations?.length > 0
                                ? result[3].value.data.conditions?.locations?.includeLocations
                                : [];
                        excludeLocs =
                            result[3].value.data.conditions?.locations?.excludeLocations?.length > 0
                                ? result[3].value.data.conditions?.locations?.excludeLocations
                                : [];
                        savedLocs = [...includeLocs, ...excludeLocs];
                    }
                    locs.forEach((data) => {
                        data.ipAddresses = data.ipRanges?.map((ip) => ip.cidrAddress)?.join(',');
                        data.checked = savedLocs.includes(data.id);
                        data.allowed = result[3]?.value?.data?.conditions?.locations?.includeLocations?.includes(data.id)
                            ? 'allowed'
                            : result[3].value?.data?.conditions?.locations?.excludeLocations?.includes(data.id)
                            ? 'restricted'
                            : undefined;
                    });
                    setLocations(locs);
                }

                if (result[3].status === 'fulfilled') {
                    let data = result[3].value?.data;
                    let checkedLocs = locs.filter((loc) => loc.checked);
                    let obj = {
                        name: data?.displayName,
                        allowedIps:
                            checkedLocs?.filter((loc) => loc.allowed === 'allowed')?.length > 0
                                ? 'specific'
                                : result[3].value.data.conditions?.locations?.includeLocations?.[0],
                        /*
                         *  === 'All' || result[3].value.data.conditions?.locations?.includeLocations?.[0] === 'AllTrusted'
                         * ? result[3].value.data.conditions?.locations?.includeLocations?.[0]
                         * : 'All'
                         */
                        restrictedIps: checkedLocs?.filter((loc) => loc.allowed !== 'allowed')?.length > 0 ? 'specific' : undefined,
                        /*
                         *  result[3].value.data.conditions?.locations?.excludeLocations?.[0] === 'AllTrusted'
                         * ? result[3].value.data.conditions?.locations?.excludeLocations?.[0]
                         * : 'All',
                         */
                        mfaRequired: REQUIRED,
                        allowAccess: result[3].value?.data?.grantControls?.builtInControls?.includes('block') ? RESTRICT : ALLOW,
                        compliantDevice: result[3].value?.data?.grantControls?.builtInControls?.includes('compliantDevice')
                            ? REQUIRED
                            : NOT_REQUIRED

                        /*
                         * [groups.checked]: data?.conditions?.users?.includeUsers?.include(result[1].value.filter((group) => group.groupId))
                         *     ? true
                         *     : false
                         */
                    };
                    setFormValues(obj);
                }

                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    const handleLocations = async (values) => {
        let obj = {};
        let checkedLocatoins = (locations || []).filter((location) => location.checked);
        let allowedLocations = (checkedLocatoins || [])
            // ?.filter((location) => location.allowed === 'allowed')
            ?.map((location) => location.id);
        let blockedLocations = (checkedLocatoins || [])
            ?.filter((location) => location.allowed !== 'allowed')
            ?.map((location) => location.id);

        obj.includeLocations = validation.values.allowedIps === 'specific' ? allowedLocations : [validation.values.allowedIps];
        // obj.excludeLocations = validation.values.restrictedIps === 'specific' ? blockedLocations : [validation.values.restrictedIps];

        return obj;
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: { ...formValues },
        onSubmit: async (values) => {
            setLoading(true);
            let obj = {
                conditions: {
                    applications: {
                        includeApplications: ['Office365']
                    },
                    users: {
                        includeGroups: groups.filter((group) => group?.checked)?.map((group) => group?.groupId)
                    },
                    locations: await handleLocations(values)
                },
                displayName: values.name,
                grantControls: {
                    operator: 'AND',
                    builtInControls: [values?.allowAccess === ALLOW ? 'mfa' : 'block'],
                    customAuthenticationFactors: [],
                    termsOfUse: []
                }
            };
            if (validation.values.allowAccess === ALLOW && validation.values.compliantDevice === REQUIRED)
                obj.grantControls?.builtInControls?.push('compliantDevice');
            if (formType === 'add') {
                api.create(url.MS_CONDITIONAL_ACCESS, obj, false, domains.MDM_COMMONS)
                    .then((resp) => {
                        resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                        if (resp.status === 'success') {
                            toast.success(toastMessages.conditionalAccessCreated);
                            history.push(`/appprotectionpolicy/${formTypeAndId.domain}`);
                        } else setLoading(false);
                    })
                    .catch((_err) => setLoading(false));
            } else {
                let dataObj = {
                    conditions: {
                        applications: {
                            includeApplications: ['Office365'],
                            excludeApplications: []
                        },
                        users: {
                            includeUsers: [],
                            excludeUsers: [],
                            includeGroups: groups.filter((group) => group.checked)?.map((group) => group.groupId),
                            excludeGroups: [],
                            includeRoles: [],
                            excludeRoles: []
                        },
                        locations: await handleLocations(values)
                    },
                    displayName: values.name,
                    grantControls: {
                        operator: 'AND',
                        builtInControls: [values?.allowAccess === ALLOW ? 'mfa' : 'block'],
                        customAuthenticationFactors: [],
                        termsOfUse: []
                    }
                };
                if (validation.values.allowAccess === ALLOW && validation.values.compliantDevice === REQUIRED)
                    dataObj.grantControls?.builtInControls?.push('compliantDevice');
                api.patch(url.MS_CONDITIONAL_ACCESS + '/' + formTypeAndId?.recordID, dataObj, false, domains.MDM_COMMONS)
                    .then((resp) => {
                        resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                        if (resp.status === 'success') {
                            toast.success(toastMessages.conditionalAccessUpdated);
                            history.push(`/appprotectionpolicy/${formTypeAndId.domain}`);
                        } else setLoading(false);
                    })
                    .catch((_err) => setLoading(false));
            }
        }
    });

    const handleKeyDown = (e) => {
        if (e.keyCode === 13) e.preventDefault();
    };

    const handleShowOn = (field) => {
        return validation.values[field.showOn] === field.showValue;
    };

    const handleCheckboxChange = (e, ind, row) => {
        let obj = row.original;
        obj.checked = e.target.checked;
        let groupArr = JSON.parse(JSON.stringify(groups));
        // groupArr.splice(ind, 1, obj);
        setGroups(groupArr);
    };

    const handleLocationSave = (field) => {
        setLoading(true);
        let ipRanges = validation.values?.[field.ipValue]?.split(',')?.map((ip) => {
            return { cidrAddress: ip };
        });
        api.create(
            url.MS_CONDITIONAL_ACCESS + '/locations',
            {
                displayName: validation.values[field.nameValue],
                isTrusted: validation.values[field.trustValue] === 'trusted',
                ipRanges: ipRanges
            },
            false,
            domains.MDM_COMMONS
        )
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    let locationArr = JSON.parse(JSON.stringify(locations));
                    resp.data.checked = true;
                    resp.data.ipAddresses = resp.data.ipRanges?.map((ip) => ip.cidrAddress)?.join(',');
                    resp.data.isTrusted = field.value === 'trustedAddresses';
                    locationArr.push(resp.data);
                    setLocations(locationArr);
                }
                validation.setValues({ ...validation.values, [field.nameValue]: '', [field.ipValue]: '' });
                setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    const handleLocationSelect = (e, location, allowed) => {
        let locationsArr = JSON.parse(JSON.stringify(locations));
        let findIndex = locationsArr.findIndex((locationObj) => locationObj.id === location.id);
        locationsArr[findIndex].checked = e.target.checked;
        if (e.target.checked) locationsArr[findIndex].allowed = allowed;
        else delete locationsArr[findIndex].allowed;
        setLocations(locationsArr);
    };

    const handleCheckAll = (e) => {
        let arr = JSON.parse(JSON.stringify(groups));
        arr.forEach((group) => (group.checked = !!e.target.checked));
        setGroups(arr);
    };

    const getIpLocations = () => {
        setLoading(true);
        api.get(url.MS_CONDITIONAL_ACCESS + '/locations', '', domains.MDM_COMMONS)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    let savedLocs = locations.filter((loc) => loc.checked)?.map((loc) => loc.id);
                    resp.data.forEach((data) => {
                        data.ipAddresses = data.ipRanges?.map((ip) => ip.cidrAddress)?.join(',');
                        data.checked = savedLocs.includes(data.id);
                    });
                    setLocations(resp.data || []);
                }
                setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    const handleEditIp = (location, index, field) => {
        validation.setValues({
            ...validation.values,
            [field.editNameValue]: location.displayName,
            [field.editIpValue]: location.ipAddresses,
            [field.editTrustValue]: location.isTrusted ? 'trusted' : 'unTrusted'
        });
        setEditIPDetails({ id: location.id, index: index, field: field });
    };

    const handleDeleteIp = (location, index) => {
        setLoading(true);
        api.delete(url.COND_ACCESS_LOCATION_DELETE + '/' + location?.id, '', domains.MDM_COMMONS)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') getIpLocations();
                else setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    const handleEditLocation = (field) => {
        setLoading(true);
        const ipRanges = validation.values?.[field.editIpValue]?.split(',')?.map((ip) => {
            return { cidrAddress: ip };
        });
        let obj = {
            '@odata.type': '#microsoft.graph.ipNamedLocation',
            displayName: validation.values[field.editNameValue],
            isTrusted: validation.values[field.editTrustValue] === 'trusted',
            ipRanges: ipRanges
        };
        api.patch(url.COND_ACCESS_LOCATION_DELETE + '/' + editIPDetails?.id, obj, false, domains.MDM_COMMONS)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                setEditIPDetails('');
                if (resp.status === 'success') getIpLocations();
                else setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    const stepperData = [
        {
            title: 'Applications',
            icon: 1,
            disabled: false,
            renderTab: (
                <Row>
                    <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                        <div className="fw-medium mb-1">Applications: </div>
                        <div className="fs-10 mb-4">Choose the application for which conditional access policies are enforced.</div>
                        <Row className="mt-3">
                            <Col sm={3}>
                                <Card className="card-height-100 shadow-card mb-4 border-top">
                                    <CardBody className="position-relative text-center">
                                        <div className="pt-1 fw-semibold">
                                            <EllipsisToolTip options={{ effect: 'solid', place: 'bottom' }}>Office365</EllipsisToolTip>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        {/* <div className="fs-10 mb-4">Choose the applications for which conditional access policies are enforced</div>
                           <DynamicComponents field={radioField} validation={validation} />
                          {validation.values.appsType === 'specificApps' && (
                              <div>
                                  <div className="fs-13 fw-medium my-1">Specific App</div>
                                  <div className="fs-11 text-muted mb-1	">App protection policies are enforced on selected applications</div>
                                  <Select
                                      getOptionValue={(option) => option.packageId}
                                      getOptionLabel={(option) => option.name}
                                      options={sortBy(applicationsBackup, 'name')}
                                      placeholder="Select"
                                      onChange={(selectedOption) => {
                                          handleChange(selectedOption);
                                      }}
                                      value={''}
                                      isSearchable={true}
                                      noOptionsMessage={() => 'No data found'}
                                  />
                                  <Row className="mt-3">
                                      {appsArray?.length > 0 &&
                                          appsArray.map((app, ind) => (
                                              <Col key={ind} sm={3}>
                                                  <Card className="card-height-100 shadow-card mb-4 border-top">
                                                      <CardBody className="position-relative text-center">
                                                          <div
                                                              className="position-absolute top-0 end-0 cursor-pointer"
                                                              onClick={() => deleteApp(ind)}
                                                          >
                                                              <i className="ri-close-line fs-14" />
                                                          </div>
                                                          <div className="pt-1 fw-semibold" id={`app-${ind}`}>
                                                              <EllipsisToolTip options={{ effect: 'solid', place: 'bottom' }}>
                                                                  {app.name}
                                                              </EllipsisToolTip>
                                                          </div>
                                                      </CardBody>
                                                  </Card>
                                              </Col>
                                          ))}
                                  </Row>
                              </div>
                          )}
                          {(validation.values.appsType === 'allApps' || validation.values.appsType === 'microsoftApps') && (
                              <div className="bg-soft-info py-2 px-4 rounded-3 fs-11">
                                  <span className="pe-1 fs-13 fw-medium">Note :</span>App protection policies are enforced on all the
                                  Microsoft apps including partnered apps
                              </div>
                          )} */}
                    </Col>
                </Row>
            )
        },
        {
            title: 'Network',
            icon: 2,
            disabled: false,
            renderTab: (
                <div>
                    <div className="fw-medium">Network :</div>
                    <div className="fs-11 mb-3">
                        Use allowed and blocked networks to enhance security by ensuring only trusted locations can access sensitive data in
                        applications. Apps are accessed only when device is connected to allowed IP networks.
                    </div>
                    <Row>
                        <Col sm={12} md={12} lg={7} xl={7} xxl={7}>
                            {DATA_PROTECT_SCHEMA.map(
                                (field, index) =>
                                    (field?.showOn ? handleShowOn(field) : true) && (
                                        <DynamicComponents
                                            key={index}
                                            field={field}
                                            allowedLocs={
                                                locations?.filter((location) => location.allowed === 'restricted')?.length > 0
                                                    ? locations?.filter((location) => location.allowed !== 'restricted')
                                                    : locations
                                            }
                                            notAllowedLocs={
                                                locations?.filter((location) => location.allowed === 'allowed')?.length > 0
                                                    ? locations?.filter((location) => location.allowed !== 'allowed')
                                                    : locations
                                            }
                                            options={locations}
                                            setLocations={setLocations}
                                            handleEditLocation={handleEditLocation}
                                            editIPDetails={editIPDetails}
                                            validation={validation}
                                            handleKeyDown={handleKeyDown}
                                            handleLocationSelect={handleLocationSelect}
                                            handleAddLocation={handleLocationSave}
                                            handleEditIp={handleEditIp}
                                            handleDeleteIp={handleDeleteIp}
                                        />
                                    )
                            )}
                        </Col>
                    </Row>
                </div>
            )
        },
        {
            title: 'Access Requirements',
            icon: 3,
            disabled: false,
            renderTab: (
                <div>
                    <div className="fw-medium">Access Requirements :</div>
                    <div className="fs-11 mb-3">
                        Access requirements define the criteria that must be met for users to gain access to applications, ensuring security
                        based on various conditions.
                    </div>
                    <Row>
                        {/* <div className="fw-semibold mb-2">MFA :</div> */}
                        {ACCESS_REQ_SCHEMA.map(
                            (field, index) =>
                                (field?.showOn ? handleShowOn(field) : true) && (
                                    <DynamicComponents key={index} editIPDetails={editIPDetails} field={field} validation={validation} />
                                )
                        )}
                    </Row>
                </div>
            )
        },
        {
            title: 'Assignment',
            icon: 4,
            disabled: false,
            renderTab: (
                <div>
                    <div className="fw-medium">Assignment :</div>
                    <div className="fs-11 mb-3">
                        After configuring the policy settings, assign the policy to user groups. You can either select specific groups or
                        apply the policy to all users.
                    </div>
                    <div className="h-100 ">
                        <div className="mb-3 mb-lg-0 d-flex flex-column gap-4">
                            <div className="mb-3 mb-lg-0 w-100 shadow-card">
                                <div className="padding-8 fs-14 border-radius-top-left-4 border-radius-top-right-4">
                                    <div className="d-flex align-items-center fw-medium fs-12 justify-content-between">
                                        <div className="d-flex align-items-center ">
                                            <i className="ri-database-2-fill me-2" />
                                            <span>All Groups </span>({groups.length})
                                        </div>
                                    </div>
                                </div>
                                <div className="height-400 border-radius-bottom-left-4 border-radius-bottom-right-4 p-3 overflow-auto">
                                    {groups?.length > 0 ? (
                                        <TableContainer
                                            loading={loading}
                                            columns={groupsSchema}
                                            data={groups}
                                            handleCheckboxChange={handleCheckboxChange}
                                            handleCheckAll={handleCheckAll}
                                            isGlobalFilter={true}
                                            searchFilter2={true}
                                            className="custom-header-css"
                                            divClass="table-responsive table-card"
                                            tableClass="table table-nowrap table-border table-centered align-middle"
                                            theadClass="bg-light text-muted"
                                        />
                                    ) : (
                                        <NoRecordsFound />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    ];

    const handleDisableSave = () => {
        let flag = !validation?.values?.name;
        return flag;
    };

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className={`page-content ${loading ? 'postion-relative mask' : ''}`}>
                <Container fluid>
                    <BreadCrumb
                        pageTitle={`${formType === 'add' ? 'Add ' : 'Edit '}Conditional Access`}
                        showBack={true}
                        backLink={`appprotectionpolicy/${formTypeAndId.domain}`}
                        history={history}
                        homeLink="Dashboard"
                    />
                    <Card>
                        <CardHeader>
                            <div className="fw-semibold fs-15 mb-1">
                                ConditionalAccess
                                {/* <span className="badge-soft-success p-1 rounded-3 fs-12">
                                    <img src={android1} alt="img" height={15} className="pe-1" />
                                    Android
                                </span> */}
                            </div>
                            <div className="fs-13">
                                Conditional Access in Mobile Device Management (MDM) refers to a set of policies and rules that control
                                access to corporate resources based on specific conditions related to the user’s device and their
                                environment. This approach ensures that only compliant and secure devices can access sensitive data and
                                applications.
                            </div>
                            <div className="d-flex align-items-center mt-3">
                                Configuration Name
                                {formType !== 'view' ? <span className="red-color ps-1">*</span> : <span className="ps-1">:</span>}
                                {formType !== 'view' ? (
                                    <div className="w-30 ms-5">
                                        <Input
                                            name={'name'}
                                            id={'name'}
                                            className={'form-control'}
                                            placeholder={'Enter Configuration Name'}
                                            type={'text'}
                                            maxLength={'30'}
                                            validate={{ required: { value: true } }}
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values['name'] || ''}
                                            invalid={validation.touched['name'] && validation.errors['name'] ? true : false}
                                        />
                                    </div>
                                ) : validation?.values['name'] ? (
                                    <span className="ps-1">{validation?.values['name']}</span>
                                ) : (
                                    '–'
                                )}
                                {validation.touched['name'] && validation.errors['name'] ? (
                                    <p className="m-0 mt-2 text-danger">{validation.errors['name']}</p>
                                ) : null}
                            </div>
                        </CardHeader>
                        <CardBody>
                            <CustomStepperComponent
                                cancelBtn={true}
                                stepperData={stepperData}
                                routeText={`appprotectionpolicy/${formTypeAndId.domain}`}
                                enableSaveBtn={true}
                                handleSave={validation.handleSubmit}
                                formType={formType}
                                handleDisableSave={handleDisableSave()}
                            />
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default AddConditionalAccess;
