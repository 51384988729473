/* eslint-disable max-lines */
/* eslint-disable max-nested-callbacks */
/* eslint-disable max-depth */
/* eslint-disable no-inline-styles/no-inline-styles */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Alert, Button, Card, Col, Container, Form, FormFeedback, Input, Label, Row } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import Loader from '../../../Components/Common/Loader';
import TableContainer from '../../../Components/Common/TableContainer';
import {
    AuthUser,
    IsAuthorized,
    TenantConfig,
    Tooltip,
    convertBytesTo,
    convertDate,
    convertSecondsTo,
    convertUTCtoIST,
    formatDate,
    getDate,
    getDateOnly,
    getFormTypeAndRecordId,
    noCaseSensitiveSortBy,
    secondsToHms,
    sortByAsc
} from '../../../Components/Common/Util';
import { APIClient } from '../../../helpers/api_helper';
import * as url from '../../../helpers/url_helper.js';
import { antivirusHistorySchema, applicationSchema, nonComplianceSchema, threatsSchema } from './TableSchema';

// Image imports
import nonCompliance from '../../../assets/images/nonCompliance.svg';
// import lenovoIcon from '../../../../assets/images/lenovo.png';
import antivirusImage from '../../../assets/images/antivirusImage.svg';
import applicationsIcon from '../../../assets/images/applications.svg';

import { useFormik } from 'formik';
import QRCode from 'qrcode';
import Flatpickr from 'react-flatpickr';
import Select from 'react-select';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import CommonModal from '../../../Components/Common/CommonModal';
import DeleteModal from '../../../Components/Common/DeleteModal';
import { CustomDPCDeviceActions, commonActionsService } from '../../../Components/Common/DeviceUtils.js';
import OffcanvasModal from '../../../Components/Common/OffcanvasModal';
import Pagination from '../../../Components/Common/Pagination/Pagination';
import TabsComponent from '../../../Components/Common/TabsComponent';
import {
    togglePassword,
    togglePasswordChange,
    togglePasswordChangeVal,
    tooglePasswordStrengthIn,
    tooglePasswordStrengthOut
} from '../../../Components/Hooks/UserHooks';
import { APP_USAGE_INTERVALS, priviliges } from '../../../Components/constants/constants';
import remoteController from '../../../assets/images/controller.png';
import { nonComplianceReasons } from '../../../common/data/dataEnums';
import toastMessages from '../../../common/messages/toastMessages';
import { useEnv } from '../../../envContext.js';
import * as domains from '../../../helpers/domain_helper.js';
import {
    deviceGroupBody,
    getViolationsSummary,
    handleAppsUsageFunc,
    handleDeleteBody,
    handleGeoViolationHistoryFunc,
    handleLocationsHistoryFunc,
    monitoringItemsFunc
} from './AndroidEnterpriseViewDevicesMetrics';
import {
    actionHistoryFunc,
    actionMessage,
    appsInPolicyFunc,
    handleAntiVirusFunc,
    handleDeviceIdentityDetailsFunc,
    handleDeviceSecurityFunc,
    handleDeviceSummaryFunc,
    handleHardwareInfoFunc,
    handleKioskExitBody,
    handleLauncherPasswordModalBody,
    handleSimInfoFunc,
    handleSoftwareInfoFunc,
    message,
    onSubmit,
    violationsBody
} from './AndroidEnterpriseViewDevicesSummary';

const AndroidEnterpriseViewDevices = () => {
    document.title = 'View Devices';
    const urlconf = useEnv();
    const api = new APIClient();
    let history = useHistory();
    const tenantConfig = TenantConfig();
    const fp1 = useRef(null);
    const formTypeAndId = getFormTypeAndRecordId(window.location.hash);
    let formType = formTypeAndId['formType'];
    let recordID = formTypeAndId['recordID'];

    const [mode, setMode] = useState(formType);
    const [loading, setLoading] = useState(false);
    const [deviceData, setDeviceData] = useState({});
    const [hardwareData, setHardwareData] = useState({});
    const [softwareData, setSoftwareData] = useState({});
    const [applications, setApplications] = useState([]);
    const [nonComplianceDetails, setNonComplianceDetails] = useState([]);
    const [commandHistory, setCommandHistory] = useState([]);
    const [showPolicyDropdown, setShowPolicyDropdown] = useState(false);
    const [showDeviceState, setShowDeviceState] = useState(false);
    const [showDeviceGroup, setShowDeviceGroup] = useState(false);
    const [policyOptions, setPolicyOptions] = useState([]);
    const [deviceGroupOptions, setDeviceGroupsOptions] = useState([]);
    const [deviceGroupOptionsBackup, setDeviceGroupsOptionsBackup] = useState([]);
    const [deviceState, setDeviceState] = useState();
    const [policy, setPolicy] = useState();
    const [policyBackup, setPolicyBackup] = useState();
    const [deviceGroup, setDeviceGroup] = useState();
    const [selectedDeviceGroup, setSelectedDeviceGroup] = useState();
    const [defaultSelectedGroup, setDefaultSelectedGroup] = useState();
    const [showModal, setShowModal] = useState(false);
    const [resetLoader, setResetLoader] = useState(false);
    const [sidePanelLoader, setSidePanelLoader] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [selectedMonitor, setSelectedMonitor] = useState();
    const [offcanvasModal, setOffcanvasModal] = useState(false);
    const [col, setCol] = useState({});
    const [locationsHistory, setLocationsHistory] = useState([]);
    const [violationSummary, setViolationSummary] = useState([]);
    const [appUsage, setAppUsage] = useState({ time: [], data: [] });
    const [refreshTime, setRefreshTime] = useState(0);
    const [totalAppDetails, setTotalAppDetails] = useState({ time: '0 ss', data: '0 KB', apps: 0 });
    const [appUsageType, setAppUsageType] = useState({ label: 'Custom Apps', value: 'custom' });
    const [usageTime, setUsageTime] = useState({ label: 'Daily', value: 'daily' });
    const [violationDate, setViolationDate] = useState('');
    const [applicationsInPolicy, setApplicationsInPolicy] = useState([]);
    const [clearCacheApp, setClearCacheApp] = useState('');
    const [showClearCache, setShowClearCache] = useState(false);
    const [activePage, setActivePage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [locationsHistoryTotalRecords, setLocationHistoryTotalRecords] = useState(0);
    const [type, setType] = useState('');
    const [monitoring, setMonitoring] = useState('');
    const [actionModal, setActionModal] = useState(false);
    const [actionText, setActionText] = useState('');
    const [subBreadcrumbItems, setSubBreadcrumbItems] = useState([{ name: 'Main Group' }]);
    const [disableActions, setDisableActions] = useState({ appUsageReport: true, violations: true, locationhistory: true, geofence: true });
    const [showLauncherPasswordModal, setShowLauncherPasswordModal] = useState(false);
    const [customLauncherPassword, setCustomLauncherPassword] = useState('');
    const [toDate, setToDate] = useState('');
    const [fromDate, setFromDate] = useState('');
    const [toDateValue, setToDateValue] = useState('');
    const [fromDateValue, setFromDateValue] = useState('');
    const [showAntivirusModal, setShowAntivirusModal] = useState(false);
    const [wipeDevice, setWipeDevice] = useState(true);
    const [wipeExternalStorage, setWipeExternalStorage] = useState(false);
    const [wipeReasonMessage, setWipeReasonMessage] = useState(false);
    const [wipeReasonMessageValue, setWipeReasonMessageValue] = useState('');
    const [antivirusData, setAntivirusData] = useState('');
    const [showAntivirusHistory, setShowAntivirusHistory] = useState(false);
    const [antivirusHistory, setAntivirusHistory] = useState([]);
    const [antivirus, setAntivirus] = useState('');
    const [showScanNow, setShowScanNow] = useState(false);
    const [antivirusRecordsCount, setAntivirusRecordsCount] = useState(0);
    const [showAntivirusThreats, setShowAntivirusThreats] = useState(false);
    const [threats, setThreats] = useState('');
    const [selectedThreatTab, setSelectedThreatTab] = useState('apps');
    const [showActions, setShowActions] = useState(true);
    const [antivirusAction, setAntivirusAction] = useState('START');
    const [factoryMode, setFactoryMode] = useState('enable');
    const [simValue, setSimValue] = useState({ sim1: '', sim2: '' });
    const [showEditSim, setShowEditSim] = useState({ sim1: false, sim2: false });
    const [simData, setSimData] = useState('');
    const [policyShift, setPolicyShift] = useState({ confirmation: false, details: '' });
    const [remoteTroubleshoot, setRemoteTroubleshoot] = useState({ show: false, details: '' });
    const [geoViolationData, setGeoViolationData] = useState({ inCount: 0, outCount: 0, records: [] });
    const [geoViolationPage, setGeoViolationPage] = useState(1);
    const [searchParams, setSearchParams] = useState({ page: 1, size: 20, serial: deviceData?.serial });
    const [geoTotalRecords, setGeoTotalRecords] = useState(0);
    let fp = useRef();
    const [dateVal, setDateVal] = useState([]);
    const [showKioskExit, setShowKioskExit] = useState(false);
    const [kioskExitQR, setKioskExitQR] = useState('');
    const [dateChange, setDateChange] = useState({});
    let userString = AuthUser();
    let user = userString ? JSON.parse(userString) : '';

    const deviceStateOptions = [
        { label: 'ACTIVE', name: 'ACTIVE', value: 'ACTIVE' },
        { label: 'DISABLED', name: 'DISABLED', value: 'DISABLED' }
    ];

    useEffect(() => {
        setMode(formType);
        let findPriv = !user?.data?.privileges?.filter((priv) => priv === priviliges.DEVICE_EDITOR || priv === priviliges.AVSCAN_EDITOR);
        setShowActions(!findPriv);
        setLoading(true);
        handlePromise();
    }, []);

    const handlePoliciesPromise = async ({ policiesParam, polArr, policiesArr, deviceValue, simObj, deviceSimDetails }) => {
        api.get(url.POLICIES, policiesParam)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.data.length > 0) {
                    resp?.data.forEach((data) => {
                        if (data.status?.toLowerCase() === 'active') {
                            polArr.push(data);
                            policiesArr.push({ name: data.code + ' - ' + data.name, label: data.name, value: data.code });
                        }
                    });
                    let policyApplied = polArr.find((policyObj) => policyObj.code === deviceValue?.policyApplied);
                    simObj.operator1 = policyApplied?.config?.SIM
                        ? policyApplied?.config?.SIM?.restrictions?.enable_sim
                            ? policyApplied?.config?.SIM?.restrictions?.sim1?.allowedOperator
                            : 'None'
                        : 'Any';
                    simObj.operator2 = policyApplied?.config?.SIM
                        ? policyApplied?.config?.SIM?.restrictions?.enable_sim
                            ? policyApplied?.config?.SIM?.restrictions?.sim2?.allowedOperator
                            : 'None'
                        : 'Any';
                    setSimData(simObj);
                    setDisableActions({
                        ...disableActions,
                        appUsageReport: !!policyApplied?.config?.feature?.enable_app_usage_report,
                        violations: !!policyApplied?.config?.feature?.enable_violations,
                        geofence: !!policyApplied?.config?.GEOFENCE?.featureEnabled
                    });
                }
                setPolicyOptions(policiesArr);
            })
            .catch((err) => toast.error('Policies Api failed.'));
    };

    const handlePromise = () => {
        clearTimeout();
        if (!window.location.hash?.includes('/aedevices/view') && !!tenantConfig?.AUTO_REFRESH) return;
        let policiesParam = { page: 1, size: 2000 };

        const deviceGroupPromise = new Promise((resolve, reject) => {
            api.get(url.DEVICE_GROUPS, '', domains.IDM)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    resolve(resp.data);
                })
                .catch((err) => reject('Groups failed.'));
        });

        const commandHistoryPromise = new Promise((resolve, reject) => {
            if (recordID) {
                let params = { page: 1, size: 2000 };
                api.get(url.DEVICES + '/' + recordID + '/command', params)
                    .then((resp) => {
                        resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                        resolve(resp.data);
                    })
                    .catch((err) => reject('Command histor failed.'));
            } else {
                reject('No record ID.');
            }
        });

        const devicePromise = new Promise((resolve, reject) => {
            api.get(url.DEVICES + '/' + recordID)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    policiesParam['type'] = resp.data.policyType === 'WP' ? resp.data.policyType : 'FM,SA,KL,TL';
                    resolve(resp.data);
                })
                .catch((err) => reject('Device failed.'));
        });

        const antivirusPromise =
            !!tenantConfig?.DEVICE_ACTIONS?.includes('ANTIVIRUS') && user?.data?.privileges?.includes(priviliges.AVSCAN_READER)
                ? new Promise((resolve, reject) => {
                      api.get(url.ANTI_VIRUS, { deviceid: recordID }, domains.ANTIVIRUS_V1)
                          .then((resp) => {
                              resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                              if (resp) resolve(resp);
                              else reject('Antivirus failed.');
                          })
                          .catch((err) => reject('Antivirus failed.'));
                  })
                : new Promise((resolve, reject) => {
                      reject('No Antivirus found.');
                  });

        Promise.allSettled([deviceGroupPromise, commandHistoryPromise, devicePromise, antivirusPromise])
            .then(async (result) => {
                let simObj = {};
                let policiesArr = [];
                let polArr = [];
                let arr = [];
                if (result[0].status === 'fulfilled') {
                    if (result[0].value?.length > 0) {
                        result[0].value.forEach((group) => {
                            arr.push(group);
                            group.label = group.name;
                            group.key = group.code;
                            group.value = group.code;
                            group.nameCode = group.name + ' - ' + group.code;
                            if (group.children) {
                                group.children = setChildGroups(group.children);
                                arr = setGroups(group.children, arr);
                            }
                            group.childsCount = group.children.length ? group.children.length : 0;
                        });
                        setDeviceGroupsOptions(result[0].value);
                        setDeviceGroupsOptionsBackup(result[0].value);
                    }
                }
                if (result[2].status === 'fulfilled') {
                    setApplicationsInPolicy(result[2].value?.appApplications);
                    result[2].value.showActions = result[2].value?.deviceStatusApplied?.toLowerCase() === 'active';
                    result[2].value.hideFactoryReset =
                        (window.location.hostname === 'apse.emmprod1.tectoro.com' ||
                        window.location.hostname === 'portal.emmprod1.tectoro.com'
                            ? (window.location.hostname === 'apse.emmprod1.tectoro.com'
                                  ? result[2].policyApplied === 'P026' ||
                                    result[2].policyApplied === 'P002' ||
                                    result[2].policyApplied === 'P023' ||
                                    result[2].policyApplied === 'P021' ||
                                    result[2].policyApplied === 'P023' ||
                                    result[2].policyApplied === 'APSE002HRB'
                                  : window.location.hostname === 'portal.emmprod1.tectoro.com'
                                  ? result[2].policyApplied === 'P047' ||
                                    result[2].policyApplied === 'P038' ||
                                    result[2].policyApplied === 'P003'
                                  : false) ||
                              result[2].policy === 'APSE002HRB' ||
                              result[2].policy === 'P1OSPatch' ||
                              result[2].policy === 'P2KSP'
                            : false) ||
                        result[2].value?.deviceStatusApplied?.toLowerCase() === 'deleted' ||
                        result[2].value?.deviceStatusApplied?.toLowerCase() === 'deleting' ||
                        result[2].value?.deviceStatusApplied?.toLowerCase() === 'new' ||
                        result[2].value?.deviceStatusApplied?.toLowerCase() === 'retire';

                    result[2].value.serial = result[2].value.serialEnc ? result[2].value.serialEnc : result[2].value.serial;
                    result[2].value.dataUsed = convertBytesTo(Number(result[2].value.allAppsDailyDataUsage));
                    simObj = {
                        simStatus: result[2]?.value?.details?.sim?.simStatus ? result[2]?.value?.details?.sim?.simStatus : 'Active',
                        iccid1Configured: result[2]?.value?.details?.sim?.iccid1Configured,
                        iccid1Installed: result[2]?.value?.details?.sim?.iccid1Installed,
                        iccid2Configured: result[2]?.value?.details?.sim?.iccid2Configured,
                        iccid2Installed: result[2]?.value?.details?.sim?.iccid2Installed,
                        iccid1Matched:
                            result[2]?.value?.details?.sim?.iccid1Configured !== '' &&
                            result[2]?.value?.details?.sim?.iccid1Configured !== null
                                ? result[2]?.value?.details?.sim?.iccid1Configured === result[2]?.value?.details?.sim?.iccid1Installed
                                : true,
                        iccid2Matched:
                            result[2]?.value?.details?.sim?.iccid2Configured !== '' &&
                            result[2]?.value?.details?.sim?.iccid2Configured !== null
                                ? result[2]?.value?.details?.sim?.iccid2Configured === result[2]?.value?.details?.sim?.iccid2Installed
                                : true
                    };
                    setSimValue({ ...simValue, sim1: result[2]?.value?.details?.Sim1, sim2: result[2]?.value?.details?.Sim2 });
                    setDeviceData(result[2].value);
                    setDeviceState({
                        label: result[2].value?.rawMsg?.state,
                        name: result[2].value?.rawMsg?.state,
                        value: result[2].value?.rawMsg?.state
                    });
                    setPolicy({
                        label: result[2].value?.policyAppliedName,
                        name: result[2].value?.policyApplied + ' - ' + result[2].value?.policyAppliedName,
                        value: result[2].value?.policyApplied
                    });
                    setPolicyBackup({ label: result[2].value?.policyAppliedName, value: result[2].value?.policyApplied });
                    let deviceGroupObj = arr.find((ele) => ele.code === result[2].value?.deviceGroup);
                    setDeviceGroup(deviceGroupObj);
                    setSelectedDeviceGroup(deviceGroupObj);
                    setDefaultSelectedGroup(deviceGroupObj);
                    if (Object.keys(result[2].value?.rawMsg?.hardwareInfo)?.length > 0) {
                        setHardwareData(result[2].value?.rawMsg?.hardwareInfo);
                    }
                    if (result[2].value?.rawMsg?.softwareInfo && Object.keys(result[2].value?.rawMsg?.softwareInfo)?.length > 0) {
                        setSoftwareData(result[2].value?.rawMsg?.softwareInfo);
                    }
                    if (result[2].value?.rawMsg?.applicationReports?.length > 0) {
                        result[2].value.rawMsg.applicationReports.forEach((ele) => {
                            ele.badgeClass = 'success';
                        });
                        let apps = noCaseSensitiveSortBy(result[2].value?.rawMsg?.applicationReports, 'displayName');
                        setApplications(apps);
                    }
                    if (result[2].value?.rawMsg?.nonComplianceDetails?.length > 0) {
                        result[2].value.rawMsg.nonComplianceDetails.forEach((comp) => {
                            comp.packageName = comp.packageName ? comp.packageName : '—';
                            comp.currentValue = comp.currentValue ? comp.currentValue : '—';
                            comp.installationFailureReason = comp.installationFailureReason ? comp.installationFailureReason : '—';
                            comp.specificNonComplianceReason = comp.specificNonComplianceReason ? comp.specificNonComplianceReason : '—';
                            // eslint-disable-next-line max-nested-callbacks
                            nonComplianceReasons.forEach((compReason) => {
                                if (comp.nonComplianceReason === compReason.value) {
                                    comp.reason = compReason.label;
                                }
                            });
                        });
                        setNonComplianceDetails(result[2].value?.rawMsg?.nonComplianceDetails);
                    }
                }
                if (result[1].status === 'fulfilled') {
                    result[1].value?.sort(function (a, b) {
                        return new Date(b?.commandDate) - new Date(a?.commandDate);
                    });
                    setCommandHistory(result[1].value?.slice(0, 20));
                    let deviceValue = result[2].value;
                    let simDetails = result[2].value.details;
                    await handlePoliciesPromise({ policiesParam, polArr, policiesArr, deviceValue, simObj, simDetails });
                }
                if (result[3].status === 'fulfilled') {
                    handleAntivirusData(result[3].value);
                }
                setLoading(false);
            })
            .catch((err) => setLoading(false));
        if (window.location.hash?.includes('/aedevices/view') && !!tenantConfig?.AUTO_REFRESH) setTimeout(handlePromise, 30000);
    };

    const getAntiVirus = (params) => {
        api.get(url.ANTI_VIRUS + '?deviceid=' + recordID, '', domains.ANTIVIRUS_V1)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp?.data) {
                    handleAntivirusData(resp);
                }
                setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    const getGeoFence = (params) => {
        setLoading(true);
        api.get(url.DEVICE_REPORTS + '/geolocation', params)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp?.data) {
                    resp.data.forEach((data) => {
                        data.badgeClass = data.status === 'IN' ? 'success' : 'danger';
                        data.date = data.date ? getDate(data.date, false, true) : '—';
                    });
                    setGeoViolationData({
                        inCount: resp?.data?.[0]?.totalInCount || 0,
                        outCount: resp?.data?.[0]?.totalOutCount || 0,
                        records: resp.data
                    });
                    setGeoTotalRecords(resp.totalRecords);
                }
                setSidePanelLoader(false);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                setSidePanelLoader(false);
            });
    };

    const handleAntivirusData = (value) => {
        let antivirusArr = JSON.parse(JSON.stringify(value?.data));
        antivirusArr.forEach((antivirusObj) => {
            antivirusObj.badgeClass =
                antivirusObj?.status === 0
                    ? 'grey-text'
                    : antivirusObj?.status === 1
                    ? 'info'
                    : antivirusObj?.status === 2
                    ? 'danger'
                    : 'success';
            antivirusObj.filesScanned = antivirusObj?.scannedfiles ? antivirusObj?.scannedfiles : 0;
            antivirusObj.scanDuration = antivirusObj?.duration ? secondsToHms(antivirusObj?.duration) : 0;
            antivirusObj.scannedBy = antivirusObj?.scannedby ? antivirusObj.scannedby : '—';
            antivirusObj.scannedDate = antivirusObj?.starttime && antivirusObj?.status > 0 ? convertUTCtoIST(antivirusObj.starttime) : '—';
            antivirusObj.status =
                antivirusObj?.status === 0
                    ? 'Requested'
                    : antivirusObj?.status === 1
                    ? 'Initiated on device'
                    : antivirusObj?.status === 2
                    ? 'Failed'
                    : 'Success';
            antivirusObj.numberOfThreats = antivirusObj?.threats ? antivirusObj.threats?.length : 0;
        });
        setAntivirusHistory(antivirusArr);
        setAntivirusRecordsCount(value?.totalRecords);
        let obj = value?.data?.[0];
        obj.statusClass = obj?.status === 0 ? 'grey-text' : obj?.status === 1 ? 'info' : obj?.status === 2 ? 'danger' : 'success';
        obj.status = obj?.status === 0 ? 'Requested' : obj?.status === 1 ? 'Initiated on device' : obj?.status === 2 ? 'Failed' : 'Success';
        obj.scannedDate = obj?.starttime && obj?.status > 0 ? getDate(obj?.starttime) : '—';
        obj.numberOfFilesScanned = obj?.scannedfiles;
        obj.duration = obj?.duration ? secondsToHms(obj?.duration) : 'Not completed yet!';
        obj.noOfFilesDetected = obj?.threats ? obj?.threats?.length : 0;
        obj.performedBy = obj?.scannedby;
        let appThreats = obj.threats
            ?.filter((file) => file.type === 'application_threat')
            ?.map((file) => {
                return {
                    ...file,
                    name: file.filePath,
                    badgeClass: file.severity === 'high' ? 'danger' : file.severity === 'low' ? 'success' : 'warning'
                };
            });
        let fileThreats = obj.threats
            ?.filter((file) => file.type === 'file_threat')
            ?.map((file) => {
                return {
                    ...file,
                    name: file.filePath,
                    badgeClass: file.severity === 'high' ? 'danger' : file.severity === 'low' ? 'success' : 'warning'
                };
            });
        let threatsObj = {
            numberOfFiles: obj?.scannedfiles,
            duration: secondsToHms(obj.duration),
            performedBy: obj?.scannedby,
            statusClass: obj.status === '0' ? 'grey-text' : obj.status === '1' ? 'info' : obj.status === '2' ? 'danger' : 'success',
            status:
                obj.status === '0' ? 'Requested' : obj.status === '1' ? 'Initiated on device' : obj.status === '2' ? 'Failed' : 'Success',
            details: {
                apps: appThreats || [],
                files: fileThreats || []
            }
        };
        setThreats(threatsObj);
        setAntivirusData(obj);
    };

    const togglecol = (key) => {
        setCol({ ...col, [key]: !col[key] });
    };

    const setChild = (parents, totalData) => {
        parents.forEach((parent) => {
            parent.children = totalData.filter((data) => data.parent === parent.id);
            if (parent.children?.length > 0) parent.children = setChild(parent.children, totalData);
        });
        return parents;
    };

    const setChildGroups = (groupArr, groupStatus) => {
        groupArr.forEach((group) => {
            group.label = group.name;
            group.key = group.code;
            group.value = group.code;
            group.nameCode = group.name + ' - ' + group.code;
            group.deviceCount = groupStatus?.[group.code] ? groupStatus?.[group.code] : 0;
            if (group.children) group.children = setChildGroups(group.children, groupStatus);
            group.childsCount = group.children.length ? group.children.length : 0;
        });
        return groupArr;
    };

    const setGroups = (groups, arr) => {
        groups.forEach((group) => {
            arr.push(group);
            if (group.children) arr = setGroups(group.children, arr);
        });
        return arr;
    };

    const getCommandHistory = () => {
        setLoading(true);
        let params = { page: 1, size: 2000 };
        api.get(url.DEVICES + '/' + recordID + '/command', params)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp) {
                    resp.data?.sort(function (a, b) {
                        return new Date(b?.commandDate) - new Date(a?.commandDate);
                    });
                    setCommandHistory(resp?.data);
                }
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    const getLocationHistory = (paramsObj) => {
        setLoading(true);
        let params = { ...paramsObj, id: recordID };
        api.get(url.DEVICE_REPORTS + '/location', params)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.data?.length > 0) {
                    resp.data.forEach((ele) => {
                        if (ele?.date) ele.dateListed = getDate(ele.date);
                        if (ele?.featureName) ele.subEntireLocation = ele.featureName;
                        if (ele?.subLocality) ele.subEntireLocation = ele.subEntireLocation + ', ' + ele.subLocality;
                        if (ele?.locality) ele.subEntireLocation = ele.subEntireLocation + ', ' + ele.locality;
                        if (ele?.adminArea) ele.subEntireLocation = ele.subEntireLocation + ', ' + ele.adminArea;
                        if (ele?.countryName) ele.subEntireLocation = ele.subEntireLocation + ', ' + ele.countryName;
                    });
                    setLocationsHistory(resp.data);
                    setLocationHistoryTotalRecords(resp.totalRecords);
                }
                setLoading(false);
                setSidePanelLoader(false);
            })
            .catch((_err) => {
                setLoading(false);
                setSidePanelLoader(false);
            });
    };

    const handleResetPassword = () => {
        setShowModal(true);
    };

    const changeGroups = (groups, checked, group) => {
        let groupsArr = JSON.parse(JSON.stringify(deviceGroupOptions));
        groupsArr.forEach((obj) => {
            if (obj.code === group.code) obj.checked = true;
            else obj.checked = false;
        });
        setDeviceGroupsOptions(groupsArr);
        setSelectedDeviceGroup(group);
    };
    let handleChangeObj = { setPolicy, setDeviceState, setDeviceGroup };
    let onCancelObj = {
        setShowPolicyDropdown,
        setPolicy,
        setShowDeviceState,
        setDeviceState,
        setShowDeviceGroup,
        setDeviceGroup,
        deviceData,
        selectedDeviceGroup
    };
    let onSubmitObj = {
        api,
        selectedDeviceGroup,
        setLoading,
        setShowPolicyDropdown,
        policy,
        deviceState,
        setPolicy,
        setShowDeviceState,
        setDeviceState,
        setShowDeviceGroup,
        setDeviceGroup
    };

    const onCancelSIM = (key) => {
        setSimValue({ ...simValue, [key]: deviceData?.details?.[key === 'sim1' ? 'Sim1' : 'Sim2'] });
        setShowEditSim({ ...showEditSim, [key]: false });
    };

    const onSubmitSIM = (key) => {
        if (!(parseInt(key).toString() === key && simValue[key].length === 22)) {
            toast.error(toastMessages.simIdLength);
            return;
        }
        // API call here
    };

    const handleSIMChange = (e, key) => {
        let pattern = new RegExp(/^[0-9\b]+$/);
        let result = pattern.test(e.target.value);
        if (result || e.target.value === '') setSimValue({ ...simValue, [key]: e.target.value });
    };

    const inputComponent = (key) => {
        return (
            <React.Fragment>
                <Input
                    name={key}
                    type={'text'}
                    maxLength={22}
                    placeholder={`Enter SIM ${key === 'sim1' ? '1' : '2'} ID`}
                    onChange={(e) => handleSIMChange(e, key)}
                    value={simValue?.[key] || ''}
                />
                <i title="Close" className="ri-close-line ms-2 me-2 fs-18 cursor-pointer link-danger" onClick={() => onCancelSIM(key)} />
                <i title="Submit" className="ri-check-line fs-18 cursor-pointer link-primary" onClick={() => onSubmitSIM(key)} />
            </React.Fragment>
        );
    };

    const onClickEditSim = (key) => {
        setShowEditSim({ ...showEditSim, [key]: true });
    };
    let dynamicCompObj = { handleChangeObj, onCancelObj, onSubmitObj };
    let onClickObj = { setShowPolicyDropdown, setShowDeviceState, setShowDeviceGroup };
    let returnDataObj = {
        showDeviceGroup,
        showPolicyDropdown,
        policy,
        policyOptions,
        dynamicCompObj,
        showDeviceState,
        onClickObj,
        deviceState,
        deviceStateOptions,
        showEditSim,
        onClickEditSim,
        inputComponent
    };
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: { newPassword: '', default_password: 'true' },
        validationSchema: Yup.object({
            newPassword: Yup.string().required('Please Enter Your New Password')
        }),
        onSubmit: (values) => {
            handleSubmit(values);
        }
    });

    const handleSubmit = (obj) => {
        commonActionsService({
            deviceID: recordID,
            type: 'resetpassword',
            values: obj,
            setLoading: setResetLoader,
            setShowModal: setShowModal,
            getCommandHistory: getCommandHistory,
            deviceGroupOptions: deviceGroupOptions,
            urlconf: urlconf
        });
        validation.resetForm();
    };

    const handleLauncherPasswordChange = () => {
        setLoading(false);
        api.create(url.DEVICE_ACTIONS + '/' + recordID, {
            command: 'custom_launcher_password',
            params: { launcherPassword: customLauncherPassword }
        })
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                toast.success(toastMessages.launcherPassword);
                setShowLauncherPasswordModal(false);
                getCommandHistory(deviceGroupOptions, true);
            })
            .catch((_err) => setLoading(false));
    };

    const handleModalBody = () => {
        return (
            <Row className={'p-4 justify-content-center ' + (resetLoader ? 'mask' : '')}>
                {resetLoader && <Loader />}
                <div className="text-center mt-2">
                    <h5 className="text-primary">Reset Password?</h5>
                    <p className="text-muted mb-1">
                        Reset your password with <b>{tenantConfig.COMPANY_DEVELOPED_BY}</b>
                    </p>
                    <i className="ri-lock-password-line font-50 text-success" color="primary"></i>
                </div>
                <Alert className="alert-borderless alert-warning text-center mb-2 mx-2" role="alert">
                    Please set as new password for the device - {recordID}
                </Alert>
                <Col className="d-flex align-items-center justify-content-center mt-2">
                    <div className="form-check form-check-inline">
                        <Input
                            type="radio"
                            value={'true'}
                            id={'default_password'}
                            name={'default_password'}
                            className="form-check-input"
                            onBlur={validation.handleBlur}
                            onChange={validation.handleChange}
                            checked={validation.values['default_password'] === 'true'}
                        />
                        <Label className="form-check-label">{'Default Password'}</Label>
                    </div>
                    <div className="form-check form-check-inline">
                        <Input
                            type="radio"
                            value={'false'}
                            id={'default_password'}
                            name={'default_password'}
                            className="form-check-input"
                            onBlur={validation.handleBlur}
                            onChange={validation.handleChange}
                            checked={validation.values['default_password'] === 'false'}
                        />
                        <Label className="form-check-label">{'Custom Password'}</Label>
                    </div>
                </Col>
                <div className="p-2">
                    <Form>
                        {validation.values['default_password'] === 'true' && (
                            <>
                                <div className="d-flex align-items-center justify-content-center mt-4">
                                    <Label className="form-label">Default Password :</Label>
                                    <Alert className="alert-borderless alert-info text-center mb-2 mx-2" role="alert">
                                        {tenantConfig?.DEVICE_DEFAULT_PASSWORD}
                                    </Alert>
                                </div>
                                <div className="text-center mt-4">
                                    <button
                                        type="submit"
                                        className="btn btn-success w-100"
                                        onClick={(e) => handleSubmit({ newPassword: tenantConfig?.DEVICE_DEFAULT_PASSWORD })}
                                    >
                                        Reset Password
                                    </button>
                                </div>
                            </>
                        )}
                        {validation.values['default_password'] === 'false' && (
                            <>
                                <div className="mb-4">
                                    <Label className="form-label">New Password</Label>
                                    <div className="position-relative auth-pass-inputgroup">
                                        <Input
                                            onFocus={() => tooglePasswordStrengthIn('password-contain')}
                                            onBlur={(e) => {
                                                validation.handleBlur(e);
                                                togglePasswordChangeVal(validation.values.newPassword) &&
                                                    tooglePasswordStrengthOut('password-contain');
                                            }}
                                            name="newPassword"
                                            type="password"
                                            autoComplete="off"
                                            className="form-control pe-5 password-input"
                                            onPaste={() => false}
                                            placeholder="Enter new password"
                                            id="password-input"
                                            aria-describedby="passwordInput"
                                            required
                                            onChange={(e) => {
                                                validation.handleChange(e);
                                                togglePasswordChange(e.target.value);
                                            }}
                                            value={validation.values.newPassword || ''}
                                            invalid={validation.touched.newPassword && validation.errors.newPassword ? true : false}
                                        />
                                        {validation.touched.newPassword && validation.errors.newPassword ? (
                                            <FormFeedback type="invalid">
                                                <div>{validation.errors.newPassword}</div>
                                            </FormFeedback>
                                        ) : null}
                                        {validation.values.newPassword && validation.values.newPassword !== '' && (
                                            <Button
                                                color="link"
                                                className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                                                type="button"
                                                onClick={() => togglePassword('password-input', 'password-icon1')}
                                            >
                                                <i id="password-icon1" className="ri-eye-off-fill align-middle"></i>
                                            </Button>
                                        )}
                                    </div>
                                    <div id="passwordInput" className="form-text">
                                        Must be at least 8 characters.
                                    </div>
                                </div>
                                <div id="password-contain" className="p-3 bg-light mb-2 rounded">
                                    <h5 className="fs-13">Password must contain:</h5>
                                    <p id="pass-length" className="invalid fs-12 mb-2">
                                        Minimum <b>8 characters</b>
                                    </p>
                                    <p id="pass-max-length" className="invalid fs-12 mb-2">
                                        Maximum <b>12 characters</b>
                                    </p>
                                    <p id="pass-lower" className="invalid fs-12 mb-2">
                                        At least 1 <b>lowercase</b> letter (a-z)
                                    </p>
                                    <p id="pass-upper" className="invalid fs-12 mb-2">
                                        At least 1 <b>uppercase</b> letter (A-Z)
                                    </p>
                                    <p id="pass-number" className="invalid fs-12 mb-2">
                                        At least 1 <b>number</b> (0-9)
                                    </p>
                                    <p id="pass-special" className="invalid fs-12 mb-0">
                                        At least 1 <b>Special</b> Symbol
                                    </p>
                                </div>
                                <div className="text-center mt-4">
                                    <button
                                        type="submit"
                                        className="btn btn-success w-100"
                                        onClick={(e) => validation.handleSubmit()}
                                        disabled={!togglePasswordChangeVal(validation.values.newPassword)}
                                    >
                                        Reset Password
                                    </button>
                                </div>
                            </>
                        )}
                    </Form>
                </div>
            </Row>
        );
    };

    const handleDeleteConfirmation = () => {
        setLoading(true);
        let params = {};
        if (parseInt(deviceData?.rawMsg?.softwareInfo?.androidVersion) < 7) {
            CustomDPCDeviceActions({ setLoading: setLoading, id: recordID, type: 'WIPE', urlconf: urlconf });
            return;
        }
        params.wipeExternalStorage = wipeExternalStorage;
        if (wipeReasonMessage) params.wipeReasonMessage = wipeReasonMessageValue;
        api.create(url.DEVICES + '/' + recordID, params)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status?.toLowerCase() === 'success') {
                    toast.success(wipeExternalStorage ? toastMessages.factoryResetWithMemorWipe : toastMessages.factoryReset);
                    history.push('/aedevices');
                }
            })
            .catch((_err) => {
                setLoading(false);
                setDeleteModal(false);
            });
    };

    const toggle = useCallback(async () => {
        let date = new Date();
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);
        let date2 = new Date(date);
        date2.setDate(date.getDate() + 1);
        var day = ('0' + date.getDate()).slice(-2);
        var month = ('0' + (date.getMonth() + 1)).slice(-2);
        var year = date.getFullYear();
        var day2 = ('0' + date2.getDate()).slice(-2);
        var month2 = ('0' + (date2.getMonth() + 1)).slice(-2);
        var year2 = date2.getFullYear();
        var formattedDate2 = year2 + '-' + month2 + '-' + day2;
        var formattedDate = year + '-' + month + '-' + day;
        setFromDate(formattedDate);
        setToDate(formattedDate2);
        setFromDateValue(date);
        setToDateValue(date2);
        setAppUsage([]);
        setDateVal([]);
        setActivePage(1);
        if (offcanvasModal) setOffcanvasModal(false);
        else setOffcanvasModal(true);
    }, [offcanvasModal]);

    const toggle1 = useCallback(async () => {
        setDeviceGroupsOptions(deviceGroupOptionsBackup);
        setSubBreadcrumbItems([{ name: 'Main Group' }]);
        setSelectedDeviceGroup({ ...deviceGroup });
        setShowDeviceGroup(!showDeviceGroup);
    }, [showDeviceGroup]);

    const toggleAntivirus = useCallback(async () => {
        setShowAntivirusHistory(!showAntivirusHistory);
    }, [showAntivirusHistory]);

    const toggleAntivirusScan = useCallback(async () => {
        setShowAntivirusModal(!showAntivirusModal);
    }, [showAntivirusModal]);

    const toggleAntivirusThreats = useCallback(async () => {
        setShowAntivirusThreats(!showAntivirusThreats);
    }, [showAntivirusThreats]);

    const handleMonitoring = (key) => {
        setActionModal(false);
        let date = new Date();
        date.setHours(0);
        date.setMinutes(0);
        date.setSeconds(0);
        date.setMilliseconds(0);
        var day = ('0' + date.getDate()).slice(-2);
        var month = ('0' + (date.getMonth() + 1)).slice(-2);
        var year = date.getFullYear();
        var formattedDate = year + '-' + month + '-' + day;
        setFromDate(formattedDate);
        setToDate(formattedDate);
        setFromDateValue(date);
        setToDateValue(date);
        if (key.type === 'REFRESH') {
            setLoading(true);
            api.create(url.DEVICE_ACTIONS + '/' + recordID, { command: 'REFRESH' })
                .then((resp) => {
                    setRefreshTime(new Date());
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    toast.success(toastMessages.deviceSync);
                    getCommandHistory(deviceGroupOptions, true);
                })
                .catch((_err) => setLoading(false));
            return;
        }
        if (key.type === 'scream') {
            setLoading(true);
            let params = { timer: 172800, action: key.action };
            if (key.action === 'STOP') delete params.timer;
            api.create(url.DEVICE_ACTIONS + '/' + recordID, { command: 'Scream', params: params })
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    toast.success(key.action === 'STOP' ? toastMessages.screamSwitchedOff : toastMessages.screamSwitchedOn);
                    getCommandHistory(deviceGroupOptions, true);
                })
                .catch((_err) => setLoading(false));
        } else {
            setSelectedMonitor(key);
            setOffcanvasModal(true);
        }
        if (key.type === 'location_history') {
            setSidePanelLoader(true);
            getLocationHistory({ page: 1, size: 20 });
        } else if (key.type === 'geo_fence_violation') {
            setSidePanelLoader(true);
            setGeoViolationPage(1);
            setSearchParams({ page: 1, size: 20, serial: deviceData.serial });
            getGeoFence({ page: 1, size: 20, serial: deviceData.serial });
        } else if (key.type === 'violations') {
            setSidePanelLoader(true);
            getViolationsSummary({ recordID, setViolationDate, setViolationSummary, setSidePanelLoader, setLoading, api, urlconf });
        } else if (key.type === 'app_usage') {
            getAppUsage(usageTime, { label: 'Custom Apps', value: 'custom' }, formattedDate, formattedDate);
        }
    };

    const handleSync = () => {
        let timeDiff = new Date() - refreshTime;
        if (refreshTime && timeDiff < 30000) {
            let timeAfterOneMinute = refreshTime.getTime() + 30000;
            var date = new Date(timeAfterOneMinute);
            var hours = date.getHours();
            var minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
            var seconds = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
            var formattedTime = hours + ':' + minutes + ':' + seconds;
            toast.error(toastMessages.refreshTime30);
            return;
        }
        setType('REFRESH');
        setActionText('Yes, Sync it!');
        setActionModal(true);
    };

    const handleKioskExit = async () => {
        setLoading(true);
        try {
            const emmId = recordID;
            const qrStringURL = await QRCode.toDataURL(emmId, { width: 600, height: 600 });
            setKioskExitQR(qrStringURL);
            setShowKioskExit(true);
            setLoading(false);
        } catch (error) {
            toast.error('Unable to generate QR code');
            setLoading(false);
        }
    };

    const handleRetire = () => {
        setType('RETIRE');
        setActionText('Yes, Retire it!');
        setActionModal(true);
    };

    const remortBody = () => {
        return <Row></Row>;
    };

    const screamBody = () => {
        return <Row></Row>;
    };

    const getMyColor = () => {
        let n = (Math.random() * 0xfffff * 1000000).toString(16);
        return '#' + n.slice(0, 6);
    };

    const assignColor = (obj, colors) => {
        let color = getMyColor();
        if (colors.includes(color)) {
            assignColor(obj, colors);
        } else {
            colors.push(color);
            obj.color = color;
        }
        return obj;
    };

    const getAppUsage = (duration, usageType, from, to) => {
        setLoading(true);
        setSidePanelLoader(true);
        setSidePanelLoader(true);
        api.create(
            url.APP_USAGE_REPORT,
            tenantConfig?.HOURLY_APP_USAGE
                ? { id: recordID, reportType: usageType?.value, startDate: from, type: 'HOURLY' }
                : { id: recordID, reportType: usageType?.value, startDate: from, endDate: to, type: 'DAILY' }
        )
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (tenantConfig?.HOURLY_APP_USAGE) {
                    let appUsageArr = [];
                    if (resp.status === 'success') {
                        Object.entries(resp.data).forEach((data) => {
                            let dataUsed = data[1].totalData ? JSON.parse(JSON.stringify(data[1].totalData)) : 0;
                            let mobileDataUsed = data[1].mobileData ? JSON.parse(JSON.stringify(data[1].mobileData)) : 0;
                            let wifiDataUsed = data[1].wifiData ? JSON.parse(JSON.stringify(data[1].wifiData)) : 0;
                            let obj = {
                                title: '',
                                spentTime: '',
                                time: 0,
                                datUsage: convertBytesTo(dataUsed),
                                data: dataUsed,
                                mobileData: convertBytesTo(mobileDataUsed),
                                mobileDataInBytes: mobileDataUsed,
                                wifiData: convertBytesTo(wifiDataUsed),
                                wifiDataInBytes: wifiDataUsed,
                                expanded: false,
                                expandedData: []
                            };
                            delete data[1].totalData;
                            delete data[1].mobileData;
                            delete data[1].wifiData;
                            Object.entries(data[1]).forEach((timeInterval) => {
                                obj.title = timeInterval[1].appName ? timeInterval[1].appName : data[0];
                                obj.time = Number(obj.time) + Number(timeInterval?.[1]?.screenTime);
                                let intervalVal = APP_USAGE_INTERVALS[timeInterval?.[0]];
                                let { days, hours, minutes, seconds } = convertSecondsTo(Number(timeInterval[1]?.screenTime) * 1000);
                                let timeVal = formatDate(days, hours, minutes, seconds);
                                let intervalObj = { interval: intervalVal, time: timeVal };
                                obj.expandedData.push(intervalObj);
                            });
                            let newObj = JSON.parse(JSON.stringify(obj));
                            let { days, hours, minutes, seconds } = convertSecondsTo(Number(newObj.time) * 1000);
                            newObj.spentTime = formatDate(days, hours, minutes, seconds);
                            appUsageArr.push(newObj);
                        });
                    }
                    let totalDataUsage = 0;
                    let totalWifiDataUsage = 0;
                    let totalMobileDataUsage = 0;
                    let totalTimeSpent = 0;
                    let totalApps = appUsageArr.length;
                    if (appUsageArr.length > 0) {
                        appUsageArr.forEach((app) => {
                            totalTimeSpent = totalTimeSpent + Number(app.time);
                            totalDataUsage = totalDataUsage + Number(app.data);
                            totalWifiDataUsage = totalWifiDataUsage + Number(app.wifiDataInBytes);
                            totalMobileDataUsage = totalMobileDataUsage + Number(app.mobileDataInBytes);
                        });
                    }
                    let { days, hours, minutes, seconds } = convertSecondsTo(totalTimeSpent * 1000);
                    let timeVal = formatDate(days, hours, minutes, seconds);
                    appUsageArr = sortByAsc(appUsageArr, 'time');
                    setTotalAppDetails({
                        time: timeVal,
                        data: convertBytesTo(totalDataUsage),
                        mobileData: convertBytesTo(totalMobileDataUsage),
                        wifiData: convertBytesTo(totalWifiDataUsage),
                        apps: totalApps
                    });
                    setAppUsage(appUsageArr);
                    setLoading(false);
                    setSidePanelLoader(false);
                } else {
                    handleAppsUsageFunc({ resp, setTotalAppDetails, setAppUsage, setLoading, setSidePanelLoader });
                }
            })
            .catch((_err) => {
                setLoading(false);
                setSidePanelLoader(false);
            });
    };

    const handleUsageType = (e) => {
        setAppUsageType(e);
        if (fromDate && toDate && fromDate !== '' && toDate !== '') getAppUsage('', e, fromDate, toDate);
        else getAppUsage('', e);
    };

    const handleDateFilter = (e, key) => {
        let date = getDateOnly(e[0]);
        let dateObject = new Date(date);
        var day = ('0' + dateObject.getDate()).slice(-2);
        var month = ('0' + (dateObject.getMonth() + 1)).slice(-2);
        var year = dateObject.getFullYear();
        var formattedDate = year + '-' + month + '-' + day;
        if (key === 'fromDate') {
            setFromDateValue(e[0]);
            setFromDate(e[0] ? formattedDate : '');
            if (toDate && toDate !== '' && e[0]) getAppUsage('', appUsageType, formattedDate, toDate);
        } else if (key === 'toDate') {
            setToDateValue(e[0]);
            setToDate(e[0] ? formattedDate : '');
            if (fromDate && fromDate !== '' && e[0]) getAppUsage('', appUsageType, fromDate, formattedDate);
        }
    };

    const handleExportAppUsage = () => {
        setLoading(true);
        let downloadList = [
            ['Serial', deviceData.serial],
            [],
            ['From Date', fromDate, '', 'To Date', toDate],
            [],
            ['Total Apps', 'Total Spent Time', 'Total Data Consumed'],
            [appUsage?.length ? appUsage?.length : '0', totalAppDetails.screenTime, totalAppDetails.dataUsage],
            []
        ];
        if (appUsage?.length > 0)
            downloadList.push(['App Name', 'Consumed Data', 'Consumed Mobile Data', 'Consumed Wi-Fi Data', 'Spent Time']);
        appUsage.forEach((app) => {
            downloadList.push([app.appName, app.data, app.mobileData, app.wifiData, app.time]);
        });
        var csvFileData = downloadList;
        var csv = '';
        csvFileData.forEach(function (row) {
            csv += row.join(',');
            csv += '\n';
        });
        var a = document.createElement('a');
        a.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
        a.target = '_blank';
        a.download = `App Usage Report ${deviceData.serial}.csv`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        setLoading(false);
    };

    const handleExpandApp = (app, ind) => {
        let appUsageArr = JSON.parse(JSON.stringify(appUsage));
        appUsageArr.forEach((appObj, index) => {
            if (index === ind) appObj.expanded = !appObj.expanded;
        });
        setAppUsage(appUsageArr);
    };

    const appUsageBody = () => {
        return (
            <div>
                {/* <Row className="p-0">
                    <div className="d-flex align-items-center justify-content-end">
                        <Button
                            type="button"
                            id="export"
                            color="primary"
                            className="padding-x-9"
                            disabled={appUsage?.details?.length === 0}
                            onClick={() => handleDownloadAppUsage()}
                        >
                            <span className="d-flex align-items-center">Export Report</span>
                        </Button>
                        {Tooltip('export', 'Export in CSV format.')}
                    </div>
                </Row> */}
                <Row className="p-0 mt-3">
                    <Col sm={3}>
                        <div className="d-flex align-items-center w-100">
                            <Select
                                getOptionValue={(option) => option.value}
                                getOptionLabel={(option) => option.label}
                                id={'appUsageType'}
                                name={'appUsageType'}
                                options={[
                                    { label: 'All', value: 'all' },
                                    { label: 'System Apps', value: 'system' },
                                    { label: 'Custom Apps', value: 'custom' }
                                ]}
                                placeholder="Select Usage Type"
                                onChange={(e) => handleUsageType(e)}
                                value={appUsageType}
                                isSearchable={true}
                                className="min-width-150 w-100"
                                classNamePrefix={'custom-select'}
                                noOptionsMessage={() => 'No data found'}
                            />
                        </div>
                    </Col>
                    <Col sm={3}>
                        <div className="form-icon flex-nowrap">
                            <Flatpickr
                                ref={fp1}
                                value={fromDateValue}
                                placeholder={'From date and time'}
                                className={'form-control form-control-icon min-width-160 me-2'}
                                options={{
                                    // enableTime: true,
                                    mode: 'single',
                                    maxDate: toDateValue ? toDateValue : 'today',
                                    dateFormat: 'Y-m-d'
                                }}
                                onChange={(e) => {
                                    handleDateFilter(e, 'fromDate');
                                }}
                            />
                            <i className="ri-calendar-2-line" />
                        </div>
                    </Col>
                    {!tenantConfig.HOURLY_APP_USAGE && (
                        <Col sm={3}>
                            <div className="form-icon flex-nowrap">
                                <Flatpickr
                                    ref={fp1}
                                    value={toDate}
                                    placeholder={'To date and time'}
                                    className={'form-control form-control-icon min-width-160 me-2'}
                                    options={{
                                        enableTime: true,
                                        mode: 'single',
                                        minDate: fromDate,
                                        dateFormat: 'Y-m-d'
                                    }}
                                    onChange={(e) => {
                                        handleDateFilter(e, 'toDate');
                                    }}
                                />
                                <i className="ri-calendar-2-line" />
                            </div>
                        </Col>
                    )}
                </Row>
                <Row className="p-0 mt-3">
                    <div className="border-radius-top-left-10 border-radius-top-right-10 padding-y-20 padding-x-15 appusage-background">
                        <Row>
                            <Col sm={3}>
                                <div className="text-light fw-normal fs-14">TOTAL APPS</div>
                                <div className="text-light fw-semibold fs-20 margin-top-10">{totalAppDetails.apps}</div>
                            </Col>
                            <Col sm={4}>
                                <div className="text-light fw-normal fs-14">TOTAL SPENT TIME</div>
                                <div className="text-light fw-semibold fs-20 margin-top-10">{totalAppDetails.time}</div>
                            </Col>
                            <Col sm={4}>
                                <div className="text-light fw-normal fs-14">TOTAL DATA CONSUMED</div>
                                <div className="d-flex">
                                    <div className="text-light fw-semibold fs-20 margin-top-10">
                                        <div className="br-25 border border-1 border-color-light d-flex align-items-center dataconsume-box padding-x-13 padding-y-7">
                                            <span className="me-2">{totalAppDetails.data}</span>
                                            <i className="ri-information-line cursor-pointer link-light" id={'info'} />
                                            {Tooltip(
                                                'info',
                                                <ol>
                                                    <li className="text-left">Mobile Data: {totalAppDetails.mobileData}</li>
                                                    <li>Wi-Fi Data: {totalAppDetails.wifiData}</li>
                                                </ol>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <Card className="border-radius-bottom-right-10 border-radius-bottom-left-10 p-0 shadow-card">
                        <div className="border-radius-bottom-left-10 border-radius-bottom-right-10">
                            <table className="table table-nowrap">
                                <thead className="table-light">
                                    <tr>
                                        <th scope="col">App Name</th>
                                        <th scope="col">Spend Time</th>
                                        <th scope="col">Consumed Data</th>
                                        {tenantConfig.HOURLY_APP_USAGE && <th></th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {appUsage.length > 0 ? (
                                        appUsage.map((app, ind) => (
                                            <React.Fragment key={ind}>
                                                <tr className={`${ind % 2 !== 0 ? 'table-active' : ''}`}>
                                                    <th scope="row">{app.title}</th>
                                                    <td>{app.spentTime}</td>
                                                    <td>
                                                        <div className="d-flex align-items-center">
                                                            <span className="me-2">{app.datUsage}</span>
                                                            <i
                                                                className="ri-information-line cursor-pointer link-info"
                                                                id={`info-${ind}`}
                                                            />
                                                            {Tooltip(
                                                                `info-${ind}`,
                                                                <ol className="text-left">
                                                                    <li>Mobile Data: {app.mobileData}</li>
                                                                    <li>Wi-Fi Data: {app.wifiData}</li>
                                                                </ol>
                                                            )}
                                                        </div>
                                                    </td>
                                                    {tenantConfig.HOURLY_APP_USAGE && (
                                                        <td>
                                                            <div
                                                                className="d-flex align-items-center justify-content-center br-per-50 width-20 height-20 bg-primary cursor-pointer"
                                                                onClick={() => handleExpandApp(app, ind)}
                                                            >
                                                                <i
                                                                    className={`${
                                                                        app.expanded ? 'ri-arrow-up-s-fill' : 'ri-arrow-down-s-fill'
                                                                    }  link-light fs-18`}
                                                                />
                                                            </div>
                                                        </td>
                                                    )}
                                                </tr>
                                                {app.expanded && (
                                                    <tr>
                                                        <td colSpan="5">
                                                            <table className="table table-nowrap mb-0 ms-4">
                                                                <thead className="table-light">
                                                                    <tr>
                                                                        <th scope="col">Time Interval</th>
                                                                        <th scope="col">Spend Time</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {app.expandedData?.length > 0 ? (
                                                                        app.expandedData.map((expanded, index) => (
                                                                            <tr
                                                                                key={index}
                                                                                className={`${index % 2 !== 0 ? 'table-active' : ''}`}
                                                                            >
                                                                                <th scope="row">{expanded.interval}</th>
                                                                                <td>{expanded.time}</td>
                                                                            </tr>
                                                                        ))
                                                                    ) : (
                                                                        <tr>
                                                                            <td>
                                                                                <span className="p-2">No Records Found.</span>
                                                                            </td>
                                                                        </tr>
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                )}
                                            </React.Fragment>
                                        ))
                                    ) : (
                                        <tr>
                                            <td>
                                                <span className="p-2">No Records Found.</span>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </Card>
                </Row>
            </div>
        );
    };

    const handleSubBreadcrumb = (group, ind) => {
        if (ind !== subBreadcrumbItems.length - 1) {
            let crumbs = [...subBreadcrumbItems];
            crumbs.splice(ind + 1);
            setSubBreadcrumbItems(crumbs);
            if (!group.key) {
                setSelectedDeviceGroup({});
                let groupsArr = JSON.parse(JSON.stringify(deviceGroupOptionsBackup));
                groupsArr.forEach((groupObj) => delete groupObj.checked);
                setDeviceGroupsOptions(groupsArr);
            } else {
                setSelectedDeviceGroup(group);
                let groupsArr = JSON.parse(JSON.stringify(group.children));
                groupsArr.forEach((groupObj) => delete groupObj.checked);
                setDeviceGroupsOptions(groupsArr);
            }
        }
    };

    const handleMoveView = (_cell, row) => {
        setSelectedDeviceGroup(row.original);
        setSubBreadcrumbItems([...subBreadcrumbItems, row.original]);
        setDeviceGroupsOptions(row.original.children ? row.original.children : []);
    };

    const handlePageChange = (pageNum, size) => {
        getAntiVirus({ page: pageNum, size: 10 });
    };

    const antivirusBody = () => {
        return (
            <React.Fragment>
                <TableContainer
                    loading={loading}
                    hideDisplayTotalCount={true}
                    data={antivirusHistory || []}
                    columns={antivirusHistorySchema}
                    reader={priviliges.DEVICE_READER}
                    customPageSize={10}
                    onPageChange={handlePageChange}
                    totalRecords={antivirusRecordsCount}
                    className="custom-header-css"
                    divClass="table-responsive table-card"
                    tableClass="table-nowrap table-border table-centered align-middle"
                    theadClass="bg-light text-muted"
                />
            </React.Fragment>
        );
    };

    const handleScanAntivirus = () => {
        if (showScanNow && antivirusAction === 'STOP') {
            toast.error(toastMessages.noScanFoundToStop);
            return;
        }
        setLoading(true);
        setActionModal(false);
        let apiService;
        if (antivirusAction === 'START') apiService = api.create(url.ANTI_VIRUS, { deviceid: recordID }, false, domains.ANTIVIRUS_V1);
        else apiService = api.patch(url.ANTI_VIRUS + '/' + antivirusData?._id, { deviceid: recordID }, false, domains.ANTIVIRUS_V1);
        apiService
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    toast.success(showScanNow ? toastMessages.scanStartRequestSent : toastMessages.scanStopRequestSent);
                }
                getAntiVirus({ page: 1, size: 1 });
            })
            .catch((err) => setLoading(false));
    };

    const anrivirusScanModal = () => {
        return (
            <React.Fragment>
                <div className="text-warning fw-medium">last scan 15 days ago</div>
                <div className="margin-top-50">
                    <div className="d-flex align-items-center padding-start-10 ">
                        <img src={antivirusImage} alt="antivirus" height={60} className="margin-end-20" />
                        <div className="d-flex align-items-center flex-column margin-end-20">
                            <span className="fs-20 fw-bold">{antivirus.scanPercentage || '100%'}</span>
                            <span className={`text-${antivirus.scanClass ? antivirus.scanClass : 'success'}`}>
                                {antivirus.scanStatus ? antivirus.scanStatus : 'All good !'}
                            </span>
                        </div>
                        <Button color="primary" onClick={handleScanAntivirus}>
                            {showScanNow ? 'Scan Now' : 'Stop Scan'}
                        </Button>
                    </div>
                </div>
            </React.Fragment>
        );
    };

    const handleThreatsTabContent = (tab, tableName) => {
        return (
            <TableContainer
                loading={loading}
                hideDisplayTotalCount={true}
                data={threats?.details?.[tab] || []}
                columns={threatsSchema}
                reader={priviliges.DEVICE_READER}
                isGlobalFilter={true}
                tableHeader={tableName}
                className="custom-header-css"
                divClass="table-responsive table-card"
                tableClass="table-nowrap table-border table-centered align-middle"
                theadClass="bg-light text-muted"
            />
        );
    };

    const threatsList = [
        { tabID: 'apps', tabName: 'Application Type', tabContent: handleThreatsTabContent('apps', 'App Threats') },
        { tabID: 'files', tabName: 'File Type', tabContent: handleThreatsTabContent('files', 'File Threats') }
    ];

    const antivirusSThreatBody = () => {
        return (
            <React.Fragment>
                <table className="table-responsive mb-5 w-100 ">
                    <tr>
                        <th>Files</th>
                        <th>Duration Time</th>
                        <th>Performed By</th>
                        <th>Status</th>
                    </tr>
                    <tr>
                        <td>{threats.numberOfFiles ? threats.numberOfFiles : 0}</td>
                        <td>{threats.duration ? threats.duration : '0 ss'}</td>
                        <td>{threats.performedBy ? threats.performedBy : '—'}</td>
                        <td>
                            <span className={'lh-sm badge badge-soft-' + threats?.statusClass}>{threats.status}</span>
                        </td>
                    </tr>
                </table>
                <TabsComponent
                    tabsList={threatsList}
                    defaultTabID={'apps'}
                    noPadding={true}
                    toggleTab={(tab) => setSelectedThreatTab(tab)}
                />
            </React.Fragment>
        );
    };

    const handleViewThreats = () => {
        setShowAntivirusThreats(true);
    };

    const handleLocationPageChange = (pageNumber) => {
        setActivePage(pageNumber);
        getLocationHistory({ page: pageNumber, size: 20 });
    };

    const handleGeoPageChange = (pgNum) => {
        setGeoViolationPage(pgNum);
        getGeoFence({ ...dateChange, page: pgNum, size: 20 });
    };

    const handleDateSearch = (val) => {
        let params = {};
        if (val?.length >= 2) params = { ...searchParams, fromDate: convertDate(val?.[0], '/'), toDate: convertDate(val?.[1], '/') };
        else params = { ...searchParams, fromDate: undefined, toDate: undefined };
        setSearchParams({ ...params, page: 1, size: 10 });
        setGeoViolationPage(1);
        setDateChange(params);
        if (params.fromDate && params.toDate) getGeoFence({ ...params, page: 1, size: 20 });
        else if (val?.length === 0) getGeoFence({ ...params, page: 1, size: 20 });
    };

    const geoViolationBody = () => {
        return (
            <React.Fragment>
                <Row>
                    <div className="col-sm-auto">
                        <div className="form-icon flex-nowrap">
                            <Flatpickr
                                ref={fp}
                                value={dateVal}
                                placeholder={'Search by Date'}
                                className={'form-control form-control-icon w-100 date-filter-picker'}
                                options={{ mode: 'range', dateFormat: 'd M, Y' }}
                                onChange={(e) => {
                                    setDateVal(e);
                                    handleDateSearch(e);
                                }}
                            />
                            <i className="ri-calendar-2-line" />
                        </div>
                    </div>
                </Row>
                <div className={'border-radius-top-left-10 border-radius-top-right-10 padding-y-20 padding-x-15 appusage-background mt-2'}>
                    <Row>
                        <Col sm={4} lg={4} xl={4}>
                            <div className="text-light fw-normal fs-14">In Count</div>
                            <div className="text-light fw-semibold fs-20 margin-top-10">{geoViolationData.inCount}</div>
                        </Col>
                        <Col sm={5} lg={5} xl={5}>
                            <div className="text-light fw-normal fs-14">Out Count</div>
                            <div className="text-light fw-semibold fs-20 margin-top-10">{geoViolationData.outCount}</div>
                        </Col>
                    </Row>
                </div>
                <div>
                    <div>
                        <div className="profile-timeline">
                            <div className="accordion accordion-flush" id="accordionFlushExample">
                                {geoViolationData?.records?.length > 0 ? (
                                    geoViolationData?.records?.map((location, ind) => {
                                        return handleGeoViolationHistoryFunc({ togglecol, col, location, ind });
                                    })
                                ) : (
                                    <div className="fs-13 d-flex align-items-center justify-content-center h-100">
                                        {'No Records Found.'}
                                    </div>
                                )}
                            </div>
                            {geoTotalRecords > 15 && (
                                <Pagination
                                    activePage={geoViolationPage}
                                    itemsCountPerPage={20}
                                    totalItemsCount={geoTotalRecords}
                                    pageRangeDisplayed={3}
                                    marginTopClass={'mt-1'}
                                    onChange={handleGeoPageChange}
                                    hideFirstLastPages={false}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    };

    const locationHistoryBody = () => {
        return (
            <div>
                <div>
                    <div className="profile-timeline">
                        <div className="accordion accordion-flush" id="accordionFlushExample">
                            {locationsHistory?.length > 0 ? (
                                locationsHistory?.map((location, ind) => {
                                    return handleLocationsHistoryFunc({ togglecol, col, location, ind });
                                })
                            ) : (
                                <div className="fs-13 d-flex align-items-center justify-content-center h-100">{'No Records Found.'}</div>
                            )}
                        </div>
                        {locationsHistoryTotalRecords > 20 && (
                            <Pagination
                                activePage={activePage}
                                itemsCountPerPage={pageSize}
                                totalItemsCount={locationsHistoryTotalRecords}
                                pageRangeDisplayed={3}
                                marginTopClass={'mt-1'}
                                onChange={handleLocationPageChange}
                                hideFirstLastPages={false}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    };

    const handleClearCache = (row) => {
        setClearCacheApp(row);
        setShowClearCache(true);
    };

    const handleClearCacheConfirm = () => {
        setLoading(true);
        setShowClearCache(false);
        api.create(url.DEVICE_ACTIONS + '/' + recordID, {
            command: 'CLEAR_CACHE',
            params: { packageName: clearCacheApp.packageName, appName: clearCacheApp.title }
        })
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                toast.success(toastMessages.cacheCleared);
                setLoading(true);
                getCommandHistory(deviceGroupOptions, true);
                setLoading(false);
            })
            .catch((_err) => setLoading(false));
    };

    const handleRemote = () => {
        /*
         * setLoading(true);
         * api.get(url.REMOTE + '/' + deviceData.id)
         *     .then((resp) => {
         *         resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
         *         if (resp.status?.toLowerCase() === 'success') {
         *             setRemoteTroubleshoot({ show: true, details: resp.data });
         *         }
         *         setLoading(false);
         *     })
         *     .catch((_err) => setLoading(false));
         */

        setLoading(true);
        api.get(url.REMOTE + '/' + deviceData.id)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status?.toLowerCase() === 'success') {
                    window.open(`${urlconf.REMOTE_URL}#/sessionID=${resp.data.sessionId}&password=${resp.data.password}`, '_blank');
                }

                setLoading(false);
            })
            .catch((_err) => setLoading(false));
    };

    /*
     * const handleFinishKioskExitTimer = async () => {
     *     setShowKioskExit(false);
     *
     *     setTimeout(() => {
     *         clearTimeout();
     *     }, 1000);
     *     setTime(60);
     *     const password = await generateTotp(deviceData.deviceId);
     *     setKioskExitPassword(password);
     *     setShowKioskExit(true);
     * };
     */

    /*
     * const handleKioskExitPassword = async () => {
     *     setTime(0);
     *     const password = await generateTotp(deviceData.deviceId);
     *     setShowKioskExit(true);
     * };
     */

    const monitoringItems = [
        {
            label: 'Violations',
            icon: 'ri-file-forbid-line',
            type: 'violations',
            header: 'Device ID - ',
            status: 'active',
            show: !!tenantConfig?.DEVICE_ACTIONS?.includes('VIOLATIONS'),
            enable: 'violations',
            disableTooltip: 'Violations not configured on this device.'
        },
        {
            label: 'App Usage',
            icon: 'ri-file-chart-line',
            type: 'app_usage',
            header: 'App Usage - ',
            status: 'active',
            show: !!tenantConfig?.DEVICE_ACTIONS?.includes('APP_USAGE'),
            enable: 'appUsageReport',
            disableTooltip: 'App usage not configured on this device'
        },
        {
            label: 'Location History',
            icon: 'ri-map-pin-line',
            type: 'location_history',
            header: 'Location History - ',
            show: !!tenantConfig?.DEVICE_ACTIONS?.includes('LOCATION_HISTORY'),
            enable: 'locationhistory',
            disableTooltip: 'Location history not configured on this device'
        },
        {
            label: 'Geofence Violation',
            icon: 'ri-map-pin-range-line',
            type: 'geo_fence_violation',
            header: 'Geofence Violation - ',
            show: !!tenantConfig?.DEVICE_ACTIONS?.includes('GEOFENCE'),
            enable: 'geofence',
            disableTooltip: 'Geofence not configured on this device.'
        }
    ];

    const actionItems = [
        {
            id: 'antivirus',
            label: 'Antivirus',
            privRequired: priviliges.AVSCAN_EDITOR,
            icon: 'ri-shield-check-line',
            clickFunction: () => handleAntivirus(),
            condition: parseInt(deviceData?.rawMsg?.softwareInfo?.androidVersion) > 7 && deviceData.showActions,
            tenantField: 'ANTIVIRUS'
        },
        {
            id: 'launcher_password',
            label: 'Launcher Password',
            privRequired: priviliges.DEVICE_EDITOR,
            icon: 'ri-key-2-line',
            clickFunction: () => setShowLauncherPasswordModal(true),
            condition: parseInt(deviceData?.rawMsg?.softwareInfo?.androidVersion) > 7 && deviceData.showActions,
            tenantField: 'LAUNCHER_PASSWORD'
        },
        {
            id: 'reboot',
            label: 'Reboot',
            privRequired: priviliges.DEVICE_EDITOR,
            icon: 'ri-creative-commons-sa-line',
            clickFunction: () => {
                setType('reboot');
                setActionModal(true);
                setActionText('Yes, Reboot it!');
            },
            condition: parseInt(deviceData?.rawMsg?.softwareInfo?.androidVersion) > 7 && deviceData.showActions,
            tenantField: 'REBOOT'
        },
        {
            id: 'lock',
            label: 'Lock',
            privRequired: priviliges.DEVICE_EDITOR,
            icon: 'ri-phone-lock-line',
            clickFunction: () => {
                setType('lock');
                setActionModal(true);
                setActionText('Yes, Lock it!');
            },
            condition: deviceData.showActions,
            tenantField: 'LOCK'
        },
        {
            id: 'resetPassword',
            privRequired: priviliges.DEVICE_EDITOR,
            label: 'Reset Password',
            icon: 'ri-lock-password-line',
            clickFunction: handleResetPassword,
            condition: parseInt(deviceData?.rawMsg?.softwareInfo?.androidVersion) > 7 && deviceData.showActions,
            tenantField: 'RESET_PASSWORD'
        },
        {
            id: 'factory_mode',
            label: 'Factory Mode',
            privRequired: priviliges.DEVICE_EDITOR,
            icon: 'ri-android-line',
            clickFunction: () => {
                setType('factory_mode');
                setActionText('Yes, Enable it!');
                setActionModal(true);
            },
            condition:
                parseInt(deviceData?.rawMsg?.softwareInfo?.androidVersion) > 7 &&
                deviceData?.showActions &&
                deviceData?.rawMsg?.hardwareInfo?.brand?.toLowerCase() === 'acer',
            tenantField: 'FACTORY_MODE'
        },
        {
            id: 'scream',
            label: 'Scream',
            privRequired: priviliges.DEVICE_EDITOR,
            icon: 'ri-volume-up-line',
            clickFunction: () => {
                setType('scream');
                setMonitoring({
                    label: 'Scream',
                    icon: 'ri-volume-up-line',
                    type: 'scream',
                    header: '',
                    action: 'START'
                });
                setActionText('Submit');
                setActionModal(true);
            },
            condition: parseInt(deviceData?.rawMsg?.softwareInfo?.androidVersion) > 7 && deviceData.showActions,
            tenantField: 'SCREAM'
        },
        {
            id: 'remote',
            label: 'Remote Control',
            privRequired: priviliges.DEVICE_EDITOR,
            icon: 'ri-remote-control-line',
            clickFunction: handleRemote,
            condition: parseInt(deviceData?.rawMsg?.softwareInfo?.androidVersion) > 7 && deviceData.showActions,
            tenantField: 'REMOTE'
        },
        {
            id: 'factoryReset',
            label: 'Factory Reset',
            privRequired: priviliges.DEVICE_EDITOR,
            icon: 'ri-settings-5-line',
            clickFunction: () => {
                setDeleteModal(true);
                setWipeExternalStorage(false);
            },
            condition: !deviceData.hideFactoryReset,
            tenantField: 'FACTORY_RESET'
        },
        {
            id: 'camera',
            label: 'Camera Disable',
            privRequired: priviliges.DEVICE_EDITOR,
            icon: 'ri-camera-line',
            clickFunction: () => {
                setType('CAMERA');
                setMonitoring({ label: 'Camera', icon: 'ri-camera-line', type: 'CAMERA', header: '', action: 'DISABLE' });
                setActionText('Submit');
                setActionModal(true);
            },
            condition: parseInt(deviceData?.rawMsg?.softwareInfo?.androidVersion) < 7 && deviceData.showActions,
            tenantField: 'CAMERA'
        },
        {
            id: 'sync',
            label: 'Sync',
            privRequired: priviliges.DEVICE_EDITOR,
            icon: 'ri-refresh-line',
            clickFunction: handleSync,
            condition: parseInt(deviceData?.rawMsg?.softwareInfo?.androidVersion) > 7 && deviceData.showActions,
            tenantField: 'REFRESH'
        },
        {
            id: 'retire',
            label: 'Retire',
            privRequired: priviliges.DEVICE_EDITOR,
            icon: 'ri-delete-bin-line',
            clickFunction: handleRetire,
            condition: deviceData.deviceStatusApplied === 'DELETED',
            tenantField: 'RETIRE'
        },
        {
            id: 'kioskexit',
            privRequired: priviliges.DEVICE_EDITOR,
            label: 'Kiosk Exit QR',
            icon: 'ri-qr-code-fill',
            clickFunction: handleKioskExit,
            condition: deviceData.deviceStatusApplied !== 'DELETED' && deviceData.deviceStatusApplied !== 'DELETING',
            tenantField: 'KIOSK_EXIT'
        }
    ];

    const handleRetireConfirm = () => {
        setLoading(true);
        api.update(url.DEVICES + '/' + recordID, { updateType: 'STATE', state: 'RETIRE' })
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    toast.success(toastMessages.deviceRetired);
                    handlePromise();
                }
            })
            .catch((err) => {})
            .finally(() => {
                setLoading(false);
                setActionText('');
                setActionModal(false);
                setType('');
            });
    };

    const handleClearCacheCancel = () => {
        setClearCacheApp('');
        setShowClearCache(false);
    };

    const actionService = () => {
        setActionModal(false);
        if (parseInt(deviceData?.rawMsg?.softwareInfo?.androidVersion) < 7) {
            CustomDPCDeviceActions({
                setLoading: setLoading,
                type: type,
                id: recordID,
                command: `${type}_${monitoring.action}`,
                urlconf
            });
            return;
        }
        commonActionsService({
            deviceID: recordID,
            type: type,
            setLoading: setLoading,
            setShowModal: setShowModal,
            getCommandHistory: getCommandHistory,
            deviceGroupOptions: deviceGroupOptions,
            urlconf: urlconf
        });
    };

    const handleAntivirus = () => {
        setLoading(true);
        setType('antivirus');
        setAntivirusAction('START');
        setActionText('Scan Now');
        getAntiVirus({ page: 1, size: 10 });
        if (!antivirusData.status || antivirusData.status === '3' || antivirusData.status === '4') setShowScanNow(true);
        else setShowScanNow(false);
        setActionModal(true);
    };

    const handleFactoryEnable = () => {
        setActionModal(false);
        setLoading(true);
        let body = {
            command: 'factory_mode',
            params: {
                action: factoryMode === 'enable' ? 'enable_factory_mode' : 'disable_factory_mode'
            }
        };
        api.create(url.DEVICE_ACTIONS + '/' + recordID, body)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    getCommandHistory(deviceGroupOptions);
                    setLoading(false);
                }
            })
            .catch((err) => setLoading(false));
    };

    const handlePolicyShiftModalBody = () => {
        return (
            <div className="mt-3">
                <div className="mb-4 text-center">
                    Are you sure you want to shift policy from{' '}
                    <span className="fw-semibold">{policyBackup?.value + ' - ' + policyBackup?.label}</span> to{' '}
                    <span className="fw-semibold">{policyShift?.details?.onSubmitObj?.policy?.label}</span>
                </div>
                <Alert className="alert-borderless alert-warning text-center mt-3 mb-0" role="alert">
                    <div>It may take some time for it to reflect the updated policy.</div>
                    <div className="d-flex justify-content-center">
                        <b className="me-1">Note: </b>Device should be connected to internet
                    </div>
                </Alert>
            </div>
        );
    };

    const copyToClipboard = async (val) => {
        navigator.clipboard.writeText(val).then(() => {
            toast.success(toastMessages.sessionIDCopied);
        });
    };

    const handleRemoteBody = () => {
        return (
            <div className="mt-3">
                <div className="mb-4 d-flex align-items-center justify-content-center">
                    <img src={remoteController} alt="controller" width={60} height={60} />
                </div>
                <div className="d-flex align-items-center justify-content-center flex-column gap-2">
                    <div>
                        Serial: <span className="fw-semibold">{remoteTroubleshoot?.details?.serial}</span>
                    </div>
                    <div className="d-flex align-items-center gap-1">
                        Session ID: <span className="fw-semibold">{remoteTroubleshoot?.details?.session_id}</span>{' '}
                        <i
                            id={'copySession'}
                            onClick={() => copyToClipboard(remoteTroubleshoot?.details?.session_id)}
                            className="ri-file-copy-line ms-2 fs-13 cursor-pointer link-primary"
                        />
                        {Tooltip('copySession', 'Copy')}
                    </div>
                </div>
                <div className="d-flex align-items-center justify-content-center flex-column gap-2">
                    <Alert className="alert-borderless alert-warning text-center mt-3 mb-0" role="alert">
                        <div className="mb-2">
                            Install HopToDesk application on your device for the troubleshooting of the devices. After the successful
                            installation of the application, kindly paste the network URL in the application.
                        </div>
                        <a href="https://www.hoptodesk.com/" rel="noreferrer" target="_blank">
                            Click here to download the HopToDesk application.
                        </a>
                    </Alert>
                </div>
            </div>
        );
    };

    return (
        <React.Fragment>
            {!sidePanelLoader && !resetLoader && loading && <Loader />}

            <div className={`page-content ${loading ? 'postion-relative mask' : ''}`}>
                <Container fluid>
                    <BreadCrumb pageTitle="View Device" history={history} homeLink="Dashboard" showBack={true} backLink="aedevices" />
                    <Card className="p-3 mb-0">
                        <Row>
                            <Col className="col-12">
                                <div className="ps-2 pe-2 w-100 d-flex align-items-center mb-0">
                                    <div className="w-100 d-flex align-items-center justify-content-between">
                                        <div className="d-flex align-items-center">
                                            <div className="fs-15 fw-medium">Serial Number :</div>
                                            <div className="fs-16 ps-2 text-info">{hardwareData?.serialNumber}</div>
                                        </div>
                                        <div className="d-flex align-items-center gap-4">
                                            {showActions &&
                                                user?.data?.privileges?.filter(
                                                    (privilege) =>
                                                        privilege === priviliges.DEVICE_EDITOR || privilege === priviliges.AVSCAN_EDITOR
                                                )?.length > 0 &&
                                                actionItems.filter((action) => !!tenantConfig?.DEVICE_ACTIONS?.includes(action.tenantField))
                                                    ?.length > 0 && (
                                                    <div className="d-flex align-items-center justify-content-center gap-1">
                                                        <div className="fs-15 d-flex justify-content-center align-items-center fw-medium">
                                                            Actions :
                                                        </div>
                                                        {actionItems.map((action, ind) => {
                                                            return (
                                                                !!tenantConfig?.DEVICE_ACTIONS?.includes(action.tenantField) && (
                                                                    <IsAuthorized
                                                                        privRequired={action.privRequired}
                                                                        yes={() => (
                                                                            <div
                                                                                key={ind}
                                                                                className={`width-35 height-35 d-flex align-items-center justify-content-center ${
                                                                                    action.condition ? 'bg-447BD107 cursor-pointer' : ''
                                                                                }`}
                                                                                onClick={
                                                                                    action.condition ? action.clickFunction : undefined
                                                                                }
                                                                            >
                                                                                <i
                                                                                    id={action.id}
                                                                                    className={`${action.icon} fs-20 ${
                                                                                        action.condition
                                                                                            ? 'action-hover link-info'
                                                                                            : 'text-muted'
                                                                                    }`}
                                                                                />
                                                                                {Tooltip(action.id, action.label)}
                                                                            </div>
                                                                        )}
                                                                    />
                                                                )
                                                            );
                                                        })}
                                                    </div>
                                                )}
                                            {monitoringItemsFunc({ monitoringItems, deviceData, disableActions, handleMonitoring })}
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Card>
                    <Row>
                        <Col className="d-flex flex-column g-3">
                            {handleDeviceSummaryFunc({
                                hardwareData,
                                deviceData,
                                deviceState,
                                deviceGroup,
                                returnDataObj,
                                policy,
                                disableActions,
                                tenantConfig,
                                recordID,
                                setPolicyShift,
                                hideSwitchEdits: false,
                                noEdit: false,
                                urlconf
                            })}
                            {handleDeviceIdentityDetailsFunc({ hardwareData, deviceData })}
                            {handleDeviceSecurityFunc({ deviceData })}
                        </Col>
                        <Col className="d-flex flex-column g-3">
                            {handleHardwareInfoFunc({ hardwareData, deviceData })}
                            {deviceData.deviceStatusApplied === 'ACTIVE' && !!tenantConfig.SHOW_SIM_INFO && handleSimInfoFunc(simData)}
                            {handleSoftwareInfoFunc({ softwareData, deviceData })}
                        </Col>
                        <Col className="d-flex flex-column g-3">
                            {actionHistoryFunc({ tenantConfig, commandHistory })}

                            {(!tenantConfig?.DEVICE_ACTIONS?.includes('ANTIVIRUS') ||
                                !user?.data?.privileges?.includes(priviliges.AVSCAN_READER)) &&
                                appsInPolicyFunc({ applicationsInPolicy, handleClearCache })}
                        </Col>
                        {!!tenantConfig?.DEVICE_ACTIONS?.includes('ANTIVIRUS') &&
                            user?.data?.privileges?.includes(priviliges.AVSCAN_READER) && (
                                <Row>
                                    <Col className="d-flex flex-column g-3">
                                        {handleAntiVirusFunc({ antivirusData, handleViewThreats, setShowAntivirusHistory, getAntiVirus })}
                                    </Col>
                                    <Col className="d-flex flex-column g-3">
                                        {appsInPolicyFunc({ applicationsInPolicy, handleClearCache })}
                                    </Col>
                                </Row>
                            )}
                        <div className="p-1">
                            <TableContainer
                                loading={loading}
                                headerImage={nonCompliance}
                                tableLabelClass={'text-success'}
                                tableHeader={'Non Compliance Details'}
                                isGlobalFilter={true}
                                columns={nonComplianceSchema}
                                data={nonComplianceDetails}
                                customPageSize={5}
                                className="custom-header-css"
                                divClass="table-responsive table-card"
                                tableClass="table table-wrap table-border table-centered align-middle"
                                theadClass="bg-light text-muted"
                            />
                        </div>
                        <div className="p-1">
                            <TableContainer
                                loading={loading}
                                searchFilter2={true}
                                showTooltip={true}
                                searchPlaceHolder={'Package/Application Name'}
                                headerImage={applicationsIcon}
                                tableLabelClass={'text-success'}
                                tableHeader={'Applications'}
                                isGlobalFilter={true}
                                columns={applicationSchema}
                                data={applications}
                                customPageSize={5}
                                className="custom-header-css"
                                divClass="table-responsive table-card"
                                tableClass="table table-wrap table-border table-centered align-middle"
                                theadClass="bg-light text-muted"
                            />
                        </div>
                    </Row>
                </Container>
                <DeleteModal
                    show={deleteModal}
                    onDeleteClick={handleDeleteConfirmation}
                    onCloseClick={() => {
                        setDeleteModal(false);
                        setWipeExternalStorage(false);
                        setWipeReasonMessage(false);
                        setWipeReasonMessageValue('');
                    }}
                    deleteMessage={`Are you sure you want to factory reset this device ${recordID} ?`}
                    confirmText={'Yes, Reset it!'}
                    customBody={true}
                    disabled={wipeReasonMessage && (!wipeReasonMessageValue || wipeReasonMessageValue === '')}
                    deleteBody={handleDeleteBody(false, {
                        wipeDevice,
                        setWipeDevice,
                        recordID,
                        wipeExternalStorage,
                        setWipeExternalStorage,
                        wipeReasonMessage,
                        setWipeReasonMessage,
                        setWipeReasonMessageValue,
                        wipeReasonMessageValue,
                        deviceData
                    })}
                />
                <DeleteModal
                    hideIcon={true}
                    show={actionModal}
                    hideDeleteIcon={true}
                    hideDeleteMessage={true}
                    message={actionMessage({
                        deviceData,
                        type,
                        antivirusAction,
                        setAntivirusAction,
                        setActionText,
                        factoryMode,
                        setMonitoring,
                        monitoring,
                        setFactoryMode
                    })}
                    confirmText={actionText}
                    enablePadding={type === 'antivirus' || type === 'scream'}
                    onDeleteClick={
                        type === 'lock' || type === 'reboot' || type === 'CAMERA'
                            ? actionService
                            : type === 'factory_mode'
                            ? handleFactoryEnable
                            : type === 'antivirus'
                            ? handleScanAntivirus
                            : type === 'RETIRE'
                            ? handleRetireConfirm
                            : () => handleMonitoring(type === 'REFRESH' ? { type: type } : monitoring)
                    }
                    onCloseClick={() => setActionModal(false)}
                />
                <DeleteModal
                    show={showClearCache}
                    hideIcon={true}
                    onDeleteClick={handleClearCacheConfirm}
                    hideDeleteMessage={true}
                    confirmText="Yes, Clear it!"
                    message={message(clearCacheApp)}
                    size="md"
                    onCloseClick={handleClearCacheCancel}
                />
                <CommonModal
                    size={'md'}
                    show={policyShift.confirmation}
                    modalheader={'Policy Shift'}
                    onCloseClick={() => setPolicyShift({ confirmation: false, details: '' })}
                    handleClick={() =>
                        onSubmit(
                            policyShift?.details?.key,
                            policyShift?.details?.onSubmitObj,
                            policyShift?.details?.recordID,
                            setPolicyShift,
                            policyShift?.obj,
                            urlconf
                        )
                    }
                    handleModalBody={() => handlePolicyShiftModalBody()}
                />
                <CommonModal
                    size={'md'}
                    show={showModal}
                    disabled={false}
                    hideSaveButton={true}
                    onCloseClick={() => {
                        validation.resetForm();
                        setShowModal(false);
                    }}
                    handleClick={() => setShowModal(false)}
                    handleModalBody={() => handleModalBody()}
                />
                <CommonModal
                    size={'md'}
                    show={remoteTroubleshoot.show}
                    disabled={false}
                    modalheader="Remote Control"
                    hideSaveButton={true}
                    onCloseClick={() => setRemoteTroubleshoot({ show: false, details: '' })}
                    handleClick={() => setRemoteTroubleshoot({ show: false, details: '' })}
                    handleModalBody={() => handleRemoteBody()}
                />
                <CommonModal
                    modalheader={'Password for Custom Launcher'}
                    size={'md'}
                    saveText="Save"
                    cancelText="Cancel"
                    show={showLauncherPasswordModal}
                    disabled={!togglePasswordChangeVal(customLauncherPassword)}
                    onCloseClick={() => setShowLauncherPasswordModal(false)}
                    handleClick={() => handleLauncherPasswordChange()}
                    handleModalBody={() => handleLauncherPasswordModalBody({ customLauncherPassword, setCustomLauncherPassword })}
                />
                <CommonModal
                    modalheader={'Kiosk Exit'}
                    size={'md'}
                    hideSaveButton={true}
                    cancelText="Ok"
                    show={showKioskExit}
                    onCloseClick={() => {
                        setShowKioskExit(false);
                        setKioskExitQR('');
                    }}
                    handleModalBody={() => handleKioskExitBody(kioskExitQR)}
                />
                <OffcanvasModal
                    direction="end"
                    toggle={toggle}
                    open={offcanvasModal}
                    hideSaveButton={true}
                    handleCloseClick={toggle}
                    loading={sidePanelLoader}
                    OffcanvasModalID="bulkUploadModal"
                    handleOffcanvasBody={
                        selectedMonitor?.type === 'remort'
                            ? remortBody
                            : selectedMonitor?.type === 'scream'
                            ? screamBody
                            : selectedMonitor?.type === 'violations'
                            ? () => violationsBody({ violationSummary, violationDate })
                            : selectedMonitor?.type === 'app_usage'
                            ? appUsageBody
                            : selectedMonitor?.type === 'geo_fence_violation'
                            ? geoViolationBody
                            : locationHistoryBody
                    }
                    modalClassName={
                        selectedMonitor?.type === 'app_usage' ? 'w-50' : selectedMonitor?.type === 'geo_fence_violation' ? 'w-40' : 'w-30'
                    }
                    offcanvasHeader={selectedMonitor?.header + deviceData.serial}
                />
                <OffcanvasModal
                    direction="end"
                    toggle={toggle1}
                    open={showDeviceGroup}
                    handleCloseClick={toggle1}
                    saveDisabled={!selectedDeviceGroup?._id || selectedDeviceGroup?._id === defaultSelectedGroup?._id}
                    loading={loading}
                    OffcanvasModalID="deviceGroup"
                    handleOffcanvasBody={() =>
                        deviceGroupBody({ subBreadcrumbItems, handleSubBreadcrumb, deviceGroupOptions, handleMoveView, loading })
                    }
                    offcanvasHeader="Change Device Group"
                    modalClassName="w-40"
                    handleSaveClick={() => onSubmit('Device Group', onSubmitObj, recordID, '', false, urlconf)}
                />
                <OffcanvasModal
                    direction="end"
                    toggle={toggleAntivirus}
                    open={showAntivirusHistory}
                    handleCloseClick={toggleAntivirus}
                    loading={loading}
                    hideFooterButtons={true}
                    OffcanvasModalID="antivirus"
                    handleOffcanvasBody={antivirusBody}
                    offcanvasHeader="Scanned History"
                    modalClassName="w-40"
                />
                <OffcanvasModal
                    direction="end"
                    toggle={toggleAntivirusScan}
                    hideFooterButtons={true}
                    open={showAntivirusModal}
                    handleCloseClick={toggleAntivirusScan}
                    loading={loading}
                    OffcanvasModalID="antivirusScan"
                    handleOffcanvasBody={anrivirusScanModal}
                    offcanvasHeader="Device Care"
                    modalClassName="w-40"
                />
                <OffcanvasModal
                    direction="end"
                    toggle={toggleAntivirusThreats}
                    hideFooterButtons={true}
                    open={showAntivirusThreats}
                    handleCloseClick={toggleAntivirusThreats}
                    loading={loading}
                    OffcanvasModalID="antivirusScan"
                    handleOffcanvasBody={antivirusSThreatBody}
                    offcanvasHeader="Device Care"
                    modalClassName="w-40"
                />
            </div>
        </React.Fragment>
    );
};

export default AndroidEnterpriseViewDevices;
