import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, Col, Container, Row } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import TableContainer from '../../../Components/Common/TableContainer';
import EllipsisToolTip from '../../../Components/Common/Tooltip/Tooltip';
import { convertUTCtoIST, getFormTypeAndRecordId } from '../../../Components/Common/Util';
import { useEnv } from '../../../envContext';
import { APIClient } from '../../../helpers/api_helper';
import { DevicesInfo, LicenseInfo, tableData } from './TableSchema';
import * as url from '../../../helpers/url_helper';
import * as domains from '../../../helpers/domain_helper';
import Loader from '../../../Components/Common/Loader';

const License = () => {
    const [licenseData, setLicenseData] = useState([]);
    const history = useHistory();
    const urlconf = useEnv();
    const [loading, setLoading] = useState(false);
    let api = new APIClient();
    const formTypeAndId = getFormTypeAndRecordId(window.location.hash);
    const [searchParams, setSearchParams] = useState({ page: 1, size: 10 });
    const [osCount, setOsCount] = useState();
    const [licenseWidgetCount, setLicenseWidgetCount] = useState();
    document.title = 'Licenses';

    useEffect(() => {
        handlePromise({ ...searchParams });
    }, []);

    const handleLicenseValidity = (startDate, endDate) => {
        const date1 = new Date(startDate);
        let date2;
        if (endDate) date2 = new Date(endDate);
        else date2 = new Date();
        const diffInMillisec = date1.getTime() - date2.getTime();
        const differenceInDays = Math.floor(diffInMillisec / (24 * 60 * 60 * 1000));
        return differenceInDays;
    };
    const handlePromise = (params) => {
        setLoading(true);
        const getLicenses = new Promise((resolve, reject) => {
            api.get(url.LICENSE, params, domains.MDM_COMMONS)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    resolve(resp);
                })
                .catch((err) => {
                    reject('os count failed');
                });
        });

        const getOsCount = new Promise((resolve, reject) => {
            api.get(url.LICENSE_OS, '', domains.MDM_COMMONS)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    resolve(resp);
                    setLoading(false);
                })
                .catch((err) => {
                    setLoading(false);
                    reject('os count failed');
                });
        });

        const getDeviceCount = new Promise((resolve, reject) => {
            api.get(url.LICENSE_DEVICE, '', domains.MDM_COMMONS)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    resolve(resp);
                    setLoading(false);
                })
                .catch((err) => {
                    setLoading(false);
                    reject('device total count failed');
                });
        });

        Promise.allSettled([getLicenses, getOsCount, getDeviceCount]).then((result) => {
            setLoading(false);
            if (result[1].status === 'fulfilled') {
                let dataObj = { usedLicense: 0, android_OS: 0, iOS_OS: 0, windows_OS: 0 };
                (result[1]?.value?.data || [])?.forEach((obj) => {
                    dataObj.usedLicense = obj.totalDevices;
                    dataObj.android_OS = obj?.totalAndroid;
                    dataObj.iOS_OS = obj?.totalIOS;
                    dataObj.windows_OS = obj?.totalWindows;
                });
                setOsCount({ ...dataObj });
            }
            if (result[0].status === 'fulfilled') {
                (result[0]?.value?.data || [])?.forEach((ele) => {
                    ele.licenseId = ele.Code;
                    ele.noOfDevices = ele.Total ? ele.Total : '_';
                    ele.remainingLicense = ele.Total ? ele.Total - ele.Assigned : '_';
                    ele.planType = ele.PlanType ? ele.PlanType.toLowerCase() : '_';
                    ele.validity = ele.Tenure ? (ele.Tenure + ele.Tenure > 1 ? ' days' : ' day') : '_';
                    ele.startDate = ele.StartDate ? convertUTCtoIST(ele.StartDate) : '_';
                    ele.endDate = ele.EndDate ? convertUTCtoIST(ele.EndDate) : '_';
                    ele.status = handleLicenseValidity(ele.EndDate) > 0 ? 'Active' : 'Expired';
                    ele.badgeClass = handleLicenseValidity(ele.EndDate) > 0 ? 'primary' : 'danger';
                });
                setLicenseData(result[0]?.value?.data || []);
            }
            if (result[2].status === 'fulfilled') {
                const licensedataObj = {};
                (result[2].value?.data || [])?.forEach((obj) => {
                    licensedataObj.totalLicenses = obj?.totallicenses ? obj?.totallicenses : 0;
                    licensedataObj.usedLicenses = obj?.totalassigned ? obj?.totalassigned : 0;
                    licensedataObj.remainingLicenses = obj?.totallicenses ? obj?.totallicenses - obj?.totalassigned : 0;
                });
                setLicenseWidgetCount({ ...licensedataObj });
            }
        });
    };
    const handleClickView = (row) => {
        history.push(`/license/view/${row.original.Id}`);
    };

    const handleGlobalSearch = (value) => {
        handlePromise({ ...searchParams, Code: value });
    };

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="License Management" pageTitle="License" />
                    <Row>
                        <Col xxl={6} xl={6} lg={6} md={12} sm={12}>
                            <Card className="card-height-100">
                                <Row>
                                    {LicenseInfo.map((ele, index) => (
                                        <Col
                                            key={index}
                                            sm={4}
                                            className={`text-center p-5 ${index !== LicenseInfo.length - 1 ? 'border-end' : ''} `}
                                        >
                                            <div className="text-muted fw-medium fs-14">
                                                <EllipsisToolTip options={{ effect: 'solid', place: 'bottom' }}>
                                                    {ele.title}
                                                </EllipsisToolTip>
                                            </div>
                                            <div className="py-1 fw-semibold fs-16">{licenseWidgetCount?.[ele.accessor]}</div>
                                        </Col>
                                    ))}
                                </Row>
                            </Card>
                        </Col>
                        <Col xxl={6} xl={6} lg={6} md={12} sm={12}>
                            <Card className="card-height-100">
                                <Row>
                                    {DevicesInfo.map((ele, index) => (
                                        <Col
                                            key={index}
                                            sm={4}
                                            className={`text-center p-5 ${index !== DevicesInfo.length - 1 ? 'border-end' : ''}`}
                                        >
                                            <div className="text-muted fw-medium fs-14 d-flex align-items-center justify-content-center">
                                                <i className={`${ele.icon} text-black fs-15`} />
                                                <span className="ms-1">{ele.title}</span>
                                            </div>
                                            <div className=" py-1 fw-semibold fs-16">{osCount?.[ele?.accessor]}</div>
                                        </Col>
                                    ))}
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                    <TableContainer
                        loading={loading}
                        hideDisplayTotalCount={true}
                        data={licenseData}
                        columns={tableData}
                        customPageSize={5}
                        isGlobalFilter={true}
                        // searchPlaceHolder={'License ID'}
                        searchFilter2={true}
                        // handleGlobalSearch={handleGlobalSearch}
                        handleClickView={handleClickView}
                        className="custom-header-css"
                        divClass="table-responsive table-card"
                        tableClass="table-nowrap table-border table-centered align-middle"
                        theadClass="bg-light text-muted"
                    />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default License;
