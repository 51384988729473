import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { priviliges } from '../Components/constants/constants';

const Navdata = () => {
    const history = useHistory();
    const [iscurrentState, setIscurrentState] = useState('');
    const [isAdmin, setIsAdmin] = useState(false);
    const [isApps, setIsApps] = useState(false);
    const [isAndroidEnterprise, setIsAndroidEnterprise] = useState(false);
    const [isAndroidTVs, setIsAndroidTVs] = useState(false);
    const [isConfigurations, setIsConfigurations] = useState(false);
    const [isAppleIOS, setIsAppleIOS] = useState(false);
    const [isWindows, setIsWindows] = useState(false);
    const [isMyDevices, setIsMyDevices] = useState(false);

    function updateIconSidebar(e) {
        if (e && e.target && e.target.getAttribute('subitems')) {
            const ul = document.getElementById('two-column-menu');
            const iconItems = ul.querySelectorAll('.nav-icon.active');
            let activeIconItems = [...iconItems];
            activeIconItems.forEach((item) => {
                item.classList.remove('active');
                var id = item.getAttribute('subitems');
                if (document.getElementById(id)) document.getElementById(id).classList.remove('show');
            });
        }
    }

    useEffect(() => {
        document.body.classList.remove('twocolumn-panel');
        if (iscurrentState === 'getstarted') {
            history.push('/getstarted');
            document.body.classList.add('twocolumn-panel');
        }
        if (iscurrentState === 'dashboard') {
            history.push('/dashboard');
            document.body.classList.add('twocolumn-panel');
        }
        if (iscurrentState === 'license') {
            history.push('/license');
            document.body.classList.add('twocolumn-panel');
        }

        if (iscurrentState !== 'mydevices') setIsMyDevices(false);

        if (iscurrentState !== 'admin') setIsAdmin(false);
        if (iscurrentState !== 'android') setIsAndroidEnterprise(false);
        if (iscurrentState !== 'tv') setIsAndroidTVs(false);
        if (iscurrentState !== 'ios') setIsAppleIOS(false);
        if (iscurrentState !== 'windows') setIsWindows(false);
        if (iscurrentState !== 'apps') setIsApps(false);
        if (iscurrentState !== 'configurations') setIsConfigurations(false);

        /*
         * if (iscurrentState === 'tenantmaster') {
         *     history.push('/tenantmaster');
         *     document.body.classList.add('twocolumn-panel');
         * }
         */
    }, [history, iscurrentState, isAdmin, isAndroidEnterprise, isAndroidTVs, isAppleIOS, isApps, isConfigurations, isMyDevices]);

    const menuItems = [
        {
            id: 'getstarted',
            label: 'Get Started',
            key: 'GET_STARTED',
            doNotCheck: true,
            icon: 'ri-flag-line',
            link: '/getstarted',
            click: function (e) {
                e.preventDefault();
                setIscurrentState('getstarted');
            }
        },
        {
            id: 'mydevices',
            label: 'My Devices',
            link: '/mydevices',
            icon: 'ri-device-line',
            key: 'MYDEVICES',
            stateVariables: isMyDevices,
            click: function (e) {
                e.preventDefault();
                setIsMyDevices(!isMyDevices);
                setIscurrentState('mydevices');
                updateIconSidebar(e);
            },
            doNotCheck: true,
            reader: priviliges.MYDEVICES,
            editor: priviliges.MYDEVICES
        },
        {
            id: 'android',
            label: 'Android',
            icon: 'ri-android-line',
            link: '/#',
            stateVariables: isAndroidEnterprise,
            click: function (e) {
                e.preventDefault();
                setIsAndroidEnterprise(!isAndroidEnterprise);
                setIscurrentState('android');
                updateIconSidebar(e);
            },
            subItems: [
                {
                    id: 'aeconsole',
                    label: 'Dashboard',
                    link: '/aeconsole',
                    icon: 'ri-dashboard-2-line',
                    multipleConditions: true,
                    key: ['DASHBOARD', 'ANDROID_ENTERPRISE_ENABLED'],
                    reader: priviliges.ENTERPRISE_READER,
                    editor: priviliges.ENTERPRISE_EDITOR,
                    parentId: 'android'
                },
                {
                    id: 'setup',
                    label: 'Setup',
                    link: '/aeenterprise',
                    icon: 'ri-home-gear-line',
                    multipleConditions: true,
                    key: ['SETUP', 'ANDROID_ENTERPRISE_ENABLED'],
                    reader: priviliges.ENTERPRISE_READER,
                    editor: priviliges.ENTERPRISE_EDITOR,
                    parentId: 'android'
                },
                {
                    id: 'policies',
                    label: 'Policies',
                    link: '/aepolicies',
                    icon: 'ri-profile-line',
                    multipleConditions: true,
                    key: ['DEVICE_POLICY', 'ANDROID_ENTERPRISE_ENABLED'],
                    reader: priviliges.POLICY_READER,
                    editor: priviliges.POLICY_EDITOR,
                    parentId: 'android'
                },
                {
                    id: 'devices',
                    label: 'Devices',
                    link: '/aedevices',
                    icon: 'ri-device-line',
                    multipleConditions: true,
                    key: ['DEVICES', 'ANDROID_ENTERPRISE_ENABLED'],
                    reader: priviliges.DEVICE_READER,
                    editor: priviliges.DEVICE_EDITOR,
                    parentId: 'android'
                },
                {
                    id: 'applications',
                    label: 'Apps',
                    link: '/aeapps',
                    icon: 'ri-apps-line',
                    multipleConditions: true,
                    reader: priviliges.APPLICATION_READER,
                    editor: priviliges.APPLICATION_EDITOR,
                    key: ['APPS', 'ANDROID_ENTERPRISE_ENABLED'],
                    parentId: 'android'
                },
                {
                    id: 'launcherconfig',
                    label: 'Launcher',
                    link: '/aelaunchersetup',
                    icon: 'ri-android-line',
                    key: 'LAUNCHER_SETUP',
                    reader: priviliges.APPLICATION_READER,
                    editor: priviliges.APPLICATION_EDITOR,
                    parentId: 'android'
                },
                {
                    id: 'bulkrequests',
                    label: 'Bulk Requests',
                    icon: 'ri-file-upload-line',
                    multipleConditions: true,
                    key: ['DEVICE_BULK_REQUEST', 'ANDROID_ENTERPRISE_ENABLED'],
                    link: '/aebulkrequests',
                    reader: priviliges.DEVICE_READER,
                    editor: priviliges.DEVICE_EDITOR,
                    parentId: 'android'
                },
                {
                    id: 'reports',
                    label: 'Reports',
                    link: '/aereports',
                    icon: 'ri-file-chart-line',
                    multipleConditions: true,
                    key: ['REPORTS', 'ANDROID_ENTERPRISE_ENABLED'],
                    parentId: 'android'
                }
            ]
        },
        {
            id: 'tv',
            label: 'Android TV',
            icon: 'ri-tv-2-line',
            link: '/#',
            stateVariables: isAndroidTVs,
            click: function (e) {
                e.preventDefault();
                setIsAndroidTVs(!isAndroidTVs);
                setIscurrentState('tv');
                updateIconSidebar(e);
            },
            subItems: [
                {
                    id: 'TVDashboard',
                    label: 'Dashboard',
                    link: '/atvconsole',
                    icon: 'ri-dashboard-2-line',
                    multipleConditions: true,
                    key: ['DASHBOARD', 'ANDROID_TV_ENABLED'],
                    reader: priviliges.TVDEVICE_READER,
                    editor: priviliges.TVDEVICE_READER,
                    parentId: 'tv'
                },
                {
                    id: 'TVPolicies',
                    label: 'Policies',
                    link: '/atvpolicies',
                    icon: 'ri-profile-line',
                    multipleConditions: true,
                    key: ['DEVICE_POLICY', 'ANDROID_TV_ENABLED'],
                    reader: priviliges.TVPOLICY_READER,
                    editor: priviliges.TVPOLICY_EDITOR,
                    parentId: 'tv'
                },
                {
                    id: 'TVDevices',
                    label: 'Devices',
                    link: '/atvdevices',
                    icon: 'ri-tv-2-line',
                    multipleConditions: true,
                    key: ['DEVICES', 'ANDROID_TV_ENABLED'],
                    reader: priviliges.TVDEVICE_READER,
                    editor: priviliges.TVDEVICE_EDITOR,
                    parentId: 'tv'
                },
                {
                    id: 'TVApps',
                    label: 'Apps',
                    link: '/atvapps',
                    icon: 'ri-apps-line',
                    multipleConditions: true,
                    key: ['APPS', 'ANDROID_TV_ENABLED'],
                    reader: priviliges.TVAPPLICATION_READER,
                    editor: priviliges.TVAPPLICATION_EDITOR,
                    parentId: 'tv'
                },
                {
                    id: 'tvbulkrequests',
                    label: 'Bulk Requests',
                    icon: 'ri-file-upload-line',
                    multipleConditions: true,
                    key: ['DEVICE_BULK_REQUEST', 'ANDROID_TV_ENABLED'],
                    link: '/atvbulkrequests',
                    reader: priviliges.TVDEVICE_READER,
                    editor: priviliges.TVDEVICE_EDITOR,
                    parentId: 'tv'
                },
                {
                    id: 'TVreports',
                    label: 'Reports',
                    link: '/atvreports',
                    icon: 'ri-file-chart-line',
                    multipleConditions: true,
                    key: ['REPORTS', 'ANDROID_TV_ENABLED'],
                    parentId: 'tv'
                }
            ]
        },
        {
            id: 'ios',
            label: 'Apple',
            icon: 'ri-tablet-line',
            link: '/#',
            stateVariables: isAppleIOS,
            click: function (e) {
                e.preventDefault();
                setIsAppleIOS(!isAppleIOS);
                setIscurrentState('ios');
                updateIconSidebar(e);
            },
            subItems: [
                {
                    id: 'iosDashboard',
                    label: 'Dashboard',
                    link: '/iosdashboard',
                    icon: 'ri-dashboard-2-line',
                    multipleConditions: true,
                    key: ['DASHBOARD', 'IOS_ENABLED'],
                    reader: priviliges.IOSPOLICY_READER,
                    editor: priviliges.IOSPOLICY_EDITOR
                },
                {
                    id: 'iosSetup',
                    label: 'Setup',
                    link: '/iossetup',
                    icon: 'ri-flag-2-line',
                    multipleConditions: true,
                    key: ['DEVICE_POLICY', 'IOS_ENABLED'],
                    reader: priviliges.IOSPOLICY_READER,
                    editor: priviliges.IOSPOLICY_EDITOR
                },
                {
                    id: 'iosPolicies',
                    label: 'Policies',
                    link: '/iospolicies',
                    icon: 'ri-profile-line',
                    multipleConditions: true,
                    key: ['DEVICE_POLICY', 'IOS_ENABLED'],
                    reader: priviliges.IOSPOLICY_READER,
                    editor: priviliges.IOSPOLICY_EDITOR
                },
                {
                    id: 'iosDevices',
                    label: 'Devices',
                    link: '/iosdevices',
                    icon: 'ri-smartphone-line',
                    multipleConditions: true,
                    key: ['DEVICES', 'IOS_ENABLED'],
                    reader: priviliges.IOSDEVICE_READER,
                    editor: priviliges.IOSDEVICE_EDITOR,
                    parentId: 'ios'
                },
                {
                    id: 'iosApps',
                    label: 'Apps',
                    link: '/iosapps',
                    icon: 'ri-apps-line',
                    multipleConditions: true,
                    key: ['APPS', 'IOS_ENABLED'],
                    reader: priviliges.IOSDEVICE_READER,
                    editor: priviliges.IOSDEVICE_EDITOR,
                    parentId: 'ios'
                }
            ]
        },
        {
            id: 'windows',
            label: 'Windows',
            icon: 'ri-windows-line',
            link: '/#',
            stateVariables: isWindows,
            click: function (e) {
                e.preventDefault();
                setIsWindows(!isWindows);
                setIscurrentState('windows');
                updateIconSidebar(e);
            },
            subItems: [
                {
                    id: 'windowsDashboard',
                    label: 'Dashboard',
                    link: '/windowsdashboard',
                    icon: 'ri-dashboard-2-line',
                    multipleConditions: true,
                    key: ['DASHBOARD', 'WINDOWS_ENABLED'],
                    reader: priviliges.WINDOWSPOLICY_READER,
                    editor: priviliges.WINDOWSPOLICY_EDITOR
                },
                {
                    id: 'windowsSetup',
                    label: 'Setup',
                    link: '/windowssetup',
                    icon: 'ri-flag-2-line',
                    multipleConditions: true,
                    key: ['DEVICE_POLICY', 'WINDOWS_ENABLED'],
                    reader: priviliges.WINDOWSPOLICY_READER,
                    editor: priviliges.WINDOWSPOLICY_EDITOR,
                    parentId: 'windows'
                },
                {
                    id: 'windowsEnrolment',
                    label: 'Enrollment',
                    link: '/windowsenrollment',
                    icon: 'ri-file-settings-line',
                    multipleConditions: true,
                    key: ['DEVICE_POLICY', 'WINDOWS_ENABLED'],
                    reader: priviliges.WINDOWSPOLICY_READER,
                    editor: priviliges.WINDOWSPOLICY_EDITOR,
                    parentId: 'windows'
                },
                {
                    id: 'windowsPolicies',
                    label: 'Policies',
                    link: '/windowspolicies',
                    icon: 'ri-profile-line',
                    multipleConditions: true,
                    key: ['DEVICE_POLICY', 'WINDOWS_ENABLED'],
                    reader: priviliges.WINDOWSPOLICY_READER,
                    editor: priviliges.WINDOWSPOLICY_EDITOR,
                    parentId: 'windows'
                },
                {
                    id: 'windowsDevices',
                    label: 'Devices',
                    link: '/windowsdevices',
                    icon: 'ri-smartphone-line',
                    multipleConditions: true,
                    key: ['DEVICES', 'WINDOWS_ENABLED'],
                    reader: priviliges.WINDOWSDEVICE_READER,
                    editor: priviliges.WINDOWSDEVICE_EDITOR,
                    parentId: 'windows'
                },
                {
                    id: 'windowsApps',
                    label: 'Apps',
                    link: '/windowsapps',
                    icon: 'ri-apps-line',
                    multipleConditions: true,
                    key: ['DEVICES', 'WINDOWS_ENABLED'],
                    reader: priviliges.WINDOWSDEVICE_READER,
                    editor: priviliges.WINDOWSDEVICE_EDITOR,
                    parentId: 'windows'
                }
            ]
        },
        {
            id: 'apps',
            label: 'Apps',
            icon: 'ri-apps-line',
            link: '/#',
            stateVariables: isApps,
            click: function (e) {
                e.preventDefault();
                setIsApps(!isApps);
                setIscurrentState('apps');
                updateIconSidebar(e);
            },
            subItems: [
                {
                    id: 'files',
                    label: 'Files',
                    link: '/files',
                    key: 'FILES',
                    icon: 'ri-folder-open-line',
                    reader: priviliges.FILE_READER,
                    editor: priviliges.FILE_EDITOR,
                    parentId: 'apps'
                },
                {
                    id: 'browser',
                    label: 'Browser',
                    key: 'BROWSER',
                    link: '/browser',
                    icon: 'ri-global-line',
                    reader: priviliges.CONFIGURATION_READER,
                    editor: priviliges.CONFIGURATION_EDITOR,
                    parentId: 'apps'
                },
                {
                    id: 'messages',
                    label: 'Messages',
                    link: '/messages',
                    key: 'MESSAGES',
                    icon: 'ri-chat-3-line',
                    reader: priviliges.MESSAGE_READER,
                    editor: priviliges.MESSAGE_EDITOR,
                    parentId: 'apps'
                }
            ]
        },
        {
            id: 'configurations',
            label: 'Configurations',
            icon: 'ri-settings-4-line',
            link: '/#',
            stateVariables: isConfigurations,
            click: function (e) {
                e.preventDefault();
                setIsConfigurations(!isConfigurations);
                setIscurrentState('configurations');
                updateIconSidebar(e);
            },
            subItems: [
                {
                    id: 'appprotectionpolicy',
                    label: 'App Protection',
                    link: '/appprotectionpolicy',
                    key: 'APP_PROTECTION_POLICY',
                    icon: 'ri-pages-line',
                    reader: priviliges.CONFIGURATION_READER,
                    editor: priviliges.CONFIGURATION_EDITOR,
                    parentId: 'configurations'
                },
                {
                    id: 'firewall',
                    label: 'Firewall',
                    key: 'FIREWALL',
                    link: '/firewall',
                    icon: 'ri-shield-keyhole-line',
                    reader: priviliges.CONFIGURATION_READER,
                    editor: priviliges.CONFIGURATION_EDITOR,
                    parentId: 'configurations'
                },
                {
                    id: 'geofence',
                    label: 'Geo-Fence',
                    key: 'GEOFENCE',
                    link: '/geofence',
                    icon: 'ri-road-map-line',
                    reader: priviliges.CONFIGURATION_READER,
                    editor: priviliges.CONFIGURATION_EDITOR,
                    parentId: 'configurations'
                },
                {
                    id: 'timefence',
                    label: 'Time-Fence',
                    key: 'TIMEFENCE',
                    link: '/timefence',
                    icon: 'ri-map-pin-time-line',
                    reader: priviliges.CONFIGURATION_READER,
                    editor: priviliges.CONFIGURATION_EDITOR,
                    parentId: 'configurations'
                },
                {
                    id: 'wififence',
                    label: 'Wifi-Fence',
                    key: 'WIFIFENCE',
                    link: '/wififence',
                    icon: 'ri-wifi-line',
                    reader: priviliges.CONFIGURATION_READER,
                    editor: priviliges.CONFIGURATION_EDITOR,
                    parentId: 'configurations'
                },
                /*
                 * {
                 *     id: 'wififence',
                 *     key: 'WIFIFENCE',
                 *     label: 'Wi-Fi Fence',
                 *     link: '/wififence',
                 *     icon: 'ri-wifi-line',
                 *     reader: 'CONFIGURATION_READER',
                 *     editor: 'CONFIGURATION_EDITOR',
                 *     parentId: 'configurations'
                 * },
                 */
                /*
                 * {
                 *     id: 'packages',
                 *     key: 'PACKAGES',
                 *     label: 'Packages',
                 *     link: '/packages',
                 *     icon: 'ri-apps-line',
                 *     reader: 'CONFIGURATION_READER',
                 *     editor: 'CONFIGURATION_EDITOR',
                 *     parentId: 'configurations'
                 * }
                 */
                {
                    id: 'simsetting',
                    key: 'SIM_SETTINGS',
                    label: 'SIM Settings',
                    link: '/simsetting',
                    icon: 'ri-sim-card-2-line',
                    reader: priviliges.CONFIGURATION_READER,
                    editor: priviliges.CONFIGURATION_EDITOR,
                    parentId: 'configurations'
                },
                {
                    id: 'contactlist',
                    key: 'CONTACT_LIST',
                    label: 'Contact List',
                    link: '/contactlist',
                    icon: 'ri-contacts-book-line',
                    reader: priviliges.CONFIGURATION_READER,
                    editor: priviliges.CONFIGURATION_EDITOR,
                    parentId: 'configurations'
                },
                {
                    id: 'callconfig',
                    key: 'CALL_CONFIG',
                    label: 'Call Settings',
                    link: '/callconfig',
                    icon: 'ri-phone-line',
                    reader: priviliges.CONFIGURATION_READER,
                    editor: priviliges.CONFIGURATION_EDITOR,
                    parentId: 'configurations'
                },
                {
                    id: 'certificate',
                    key: 'CERTIFICATE_MANAGEMENT',
                    label: 'Certificates',
                    link: '/certificatemanagement',
                    icon: 'ri-article-line',
                    reader: priviliges.CERTIFICATE_READER,
                    editor: priviliges.CERTIFICATE_EDITOR,
                    parentId: 'configurations'
                },
                {
                    id: 'odmfeatures',
                    key: 'ODM_FEATURES',
                    label: 'ODM Features',
                    link: '/odmfeatures',
                    icon: 'ri-settings-5-line',
                    reader: priviliges.CONFIGURATION_READER,
                    editor: priviliges.CONFIGURATION_EDITOR,
                    parentId: 'configurations'
                }
            ]
        },
        {
            id: 'admin',
            label: 'Admin',
            icon: 'ri-shield-user-line',
            link: '/#',
            stateVariables: isAdmin,
            click: function (e) {
                e.preventDefault();
                setIsAdmin(!isAdmin);
                setIscurrentState('admin');
                updateIconSidebar(e);
            },
            subItems: [
                {
                    id: 'roles',
                    label: 'Roles',
                    key: 'ROLES',
                    doNotCheck: true,
                    link: '/roles',
                    icon: 'ri-user-settings-line',
                    reader: priviliges.ROLE_READER,
                    editor: priviliges.ROLE_EDITOR,
                    parentId: 'admin'
                },
                {
                    id: 'users',
                    label: 'Users',
                    key: 'USERS',
                    doNotCheck: true,
                    link: '/users',
                    icon: 'ri-user-add-line',
                    reader: priviliges.USER_READER,
                    editor: priviliges.USER_EDITOR,
                    parentId: 'admin'
                },
                {
                    id: 'groups',
                    label: 'Groups',
                    key: 'DEVICE_GROUPS',
                    link: '/devicegroups',
                    icon: 'ri-file-list-2-line',
                    reader: priviliges.GROUP_READER,
                    editor: priviliges.GROUP_EDITOR,
                    parentId: 'admin'
                },
                {
                    id: 'assets',
                    label: 'Assets',
                    key: 'ASSETS',
                    icon: 'ri-image-add-line',
                    link: '/assets',
                    reader: priviliges.ASSET_READER,
                    editor: priviliges.ASSET_EDITOR,
                    parentId: 'admin'
                },
                {
                    id: 'zerotouch',
                    label: 'Zero LicensesTouch',
                    key: 'ZERO_TOUCH',
                    link: '/zerotouch',
                    icon: 'ri-account-box-line',
                    reader: priviliges.ZEROTOUCH_READER,
                    editor: priviliges.ZEROTOUCH_EDITOR,
                    parentId: 'admin'
                },
                {
                    id: 'tenant',
                    label: 'Account',
                    key: 'TENANT',
                    link: '/mytenant',
                    doNotCheck: true,
                    icon: 'ri-building-line',
                    reader: priviliges.TENANT_READER,
                    editor: priviliges.TENANT_EDITOR,
                    parentId: 'admin'
                },
                {
                    id: 'reports',
                    label: 'Reports',
                    key: 'ADMIN_REPORTS',
                    icon: 'ri-file-chart-line',
                    link: '/adminreports',
                    parentId: 'admin'
                },
                {
                    id: 'authenticationmodes',
                    label: 'Authentication Modes',
                    key: 'AUTH_MODES',
                    icon: 'ri-user-shared-2-line',
                    link: '/authmodes',
                    reader: priviliges.TENANT_READER,
                    editor: priviliges.TENANT_EDITOR,
                    parentId: 'admin'
                }
            ]
        },
        {
            id: 'license',
            label: 'License',
            key: 'LICENSE',
            icon: 'ri-wallet-2-line',
            link: '/license',
            reader: priviliges.TENANT_READER,
            editor: priviliges.TENANT_EDITOR,
            click: function (e) {
                e.preventDefault();
                setIscurrentState('license');
            }
        },
        {
            id: 'enrollmentsettings',
            label: 'Enrollment Settings',
            key: 'ENROLLMENT_SETTNGS',
            icon: 'ri-profile-line',
            link: '/enrollmentsettings',
            reader: priviliges.TENANT_READER,
            editor: priviliges.TENANT_EDITOR,
            click: function (e) {
                e.preventDefault();
                setIscurrentState('enrollmentsettings');
            }
        }
    ];
    return <React.Fragment>{menuItems}</React.Fragment>;
};

export default Navdata;
