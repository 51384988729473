import AppStore from '../../../assets/images/AppleDefaultAppIcons/AppStore.png';
import AppleBooks from '../../../assets/images/AppleDefaultAppIcons/AppleBooks.png';
import AppleTV from '../../../assets/images/AppleDefaultAppIcons/AppleTV.png';
import Calculator from '../../../assets/images/AppleDefaultAppIcons/Calculator.png';
import Camera from '../../../assets/images/AppleDefaultAppIcons/Camera.png';
import Contacts from '../../../assets/images/AppleDefaultAppIcons/Contacts.png';
import Facetime from '../../../assets/images/AppleDefaultAppIcons/Facetime.png';
import Findmy from '../../../assets/images/AppleDefaultAppIcons/Findmy.png';
import health from '../../../assets/images/AppleDefaultAppIcons/health.png';
import home from '../../../assets/images/AppleDefaultAppIcons/home.png';
import Mail from '../../../assets/images/AppleDefaultAppIcons/Mail.png';
import Messages from '../../../assets/images/AppleDefaultAppIcons/Messages.png';
import Podcasts from '../../../assets/images/AppleDefaultAppIcons/Podcasts.png';
import safari from '../../../assets/images/AppleDefaultAppIcons/safari.png';
import shortcuts from '../../../assets/images/AppleDefaultAppIcons/shortcuts.png';
import stocks from '../../../assets/images/AppleDefaultAppIcons/stocks.png';
import translate from '../../../assets/images/AppleDefaultAppIcons/translate.png';
import Weather from '../../../assets/images/AppleDefaultAppIcons/Weather.png';
import AppleMusic from '../../../assets/images/AppleDefaultAppIcons/AppleMusic.png';
import Calendar from '../../../assets/images/AppleDefaultAppIcons/Calendar.png';
import Clips from '../../../assets/images/AppleDefaultAppIcons/Clips.png';
import Clock from '../../../assets/images/AppleDefaultAppIcons/Clock.png';
import iMazing from '../../../assets/images/AppleDefaultAppIcons/iMazing.png';
import Journal from '../../../assets/images/AppleDefaultAppIcons/Journal.png';
import Photos from '../../../assets/images/AppleDefaultAppIcons/Photos.png';
import reminder from '../../../assets/images/AppleDefaultAppIcons/reminders.png';
import Settings from '../../../assets/images/AppleDefaultAppIcons/Settings.png';
import wallet from '../../../assets/images/AppleDefaultAppIcons/wallet.png';
import tips from '../../../assets/images/AppleDefaultAppIcons/Tips.png';
import freeform from '../../../assets/images/AppleDefaultAppIcons/Freeform.png';
import Magnifier from '../../../assets/images/AppleDefaultAppIcons/Magnifier.png';
import measure from '../../../assets/images/AppleDefaultAppIcons/measure.png';
import compass from '../../../assets/images/AppleDefaultAppIcons/Compass.png';
import files from '../../../assets/images/AppleDefaultAppIcons/Files.png';
import iCloudDrive from '../../../assets/images/AppleDefaultAppIcons/iCloud.png';
import maps from '../../../assets/images/AppleDefaultAppIcons/Maps.png';
import mobileStore from '../../../assets/images/AppleDefaultAppIcons/apple-itunes-store.png';
import notes from '../../../assets/images/AppleDefaultAppIcons/Notes.png';
import news from '../../../assets/images/AppleDefaultAppIcons/news.png';
import photobooth from '../../../assets/images/AppleDefaultAppIcons/photobooth.png';
import phone from '../../../assets/images/AppleDefaultAppIcons/apple-phone.png';
import video from '../../../assets/images/AppleDefaultAppIcons/videos.png';
import voicememo from '../../../assets/images/AppleDefaultAppIcons/VoiceMemos.png';
import Apple from '../../../assets/images/apple.png';

const IOS_DEFAULT_APPS = {
    ios: [
        // { name: 'Arcade', packagename: 'com.apple.Arcade', checked: false, type: 'default' },
        { name: 'App Store', packagename: 'com.apple.AppStore', checked: false, type: 'default', image: AppStore },
        // { name: 'Barcode support Qrcode', packagename: 'com.apple.barcodesupport.qrcode', checked: false, type: 'default' },
        { name: 'Calculator', packagename: 'com.apple.calculator', checked: false, type: 'default', image: Calculator },
        { name: 'Camera', packagename: 'com.apple.camera', checked: false, type: 'default', image: Camera },
        { name: 'Compass', packagename: 'com.apple.compass', checked: false, type: 'default', image: compass },
        { name: 'Files', packagename: 'com.apple.DocumentsApp', checked: false, type: 'default', image: files },
        { name: 'Facetime', packagename: 'com.apple.facetime', checked: false, type: 'default', image: Facetime },
        { name: 'Find My', packagename: 'com.apple.findmy', checked: false, type: 'default', image: Findmy },
        { name: 'Free Form', packagename: 'com.apple.freeform', checked: false, type: 'default', image: freeform },
        // { name: 'Game Center', packagename: 'com.apple.gamecenter', checked: false, type: 'default' },
        { name: 'Health', packagename: 'com.apple.Health', checked: false, type: 'default', image: health },
        { name: 'Home', packagename: 'com.apple.Home', checked: false, type: 'default', image: home },
        { name: 'iBooks', packagename: 'com.apple.iBooks', checked: false, type: 'default', image: AppleBooks },
        { name: 'iCloud Drive App', packagename: 'com.apple.iCloudDriveApp', checked: false, type: 'default', image: iCloudDrive },
        { name: 'Maps', packagename: 'com.apple.Maps', checked: false, type: 'default', image: maps },
        { name: 'Magnifier', packagename: 'com.apple.Magnifier', checked: false, type: 'default', image: Magnifier },
        { name: 'Measure', packagename: 'com.apple.measure', checked: false, type: 'default', image: measure },
        { name: 'Mobile Address Book', packagename: 'com.apple.MobileAddressBook', checked: false, type: 'default', image: Contacts },
        { name: 'Mobile Mail', packagename: 'com.apple.mobilemail', checked: false, type: 'default', image: Mail },
        { name: 'Mobile Safari', packagename: 'com.apple.mobilesafari', checked: false, type: 'default', image: safari },
        { name: 'Mobile SMS', packagename: 'com.apple.MobileSMS', checked: false, type: 'default', image: Messages },
        { name: 'Mobile Store', packagename: 'com.apple.MobileStore', checked: false, type: 'default', image: mobileStore },
        { name: 'Mobile Timer', packagename: 'com.apple.mobiletimer', checked: false, type: 'default', image: Clock },
        { name: 'Mobile Notes', packagename: 'com.apple.mobilenotes', checked: false, type: 'default', image: notes },
        { name: 'Mobile Slideshow', packagename: 'com.apple.mobileslideshow', checked: false, type: 'default', image: Photos },
        { name: 'Music', packagename: 'com.apple.Music', checked: false, type: 'default', image: AppleMusic },
        { name: 'News', packagename: 'com.apple.news', checked: false, type: 'default', image: news },
        { name: 'Wallet', packagename: 'com.apple.Passbook', checked: false, type: 'default', image: wallet },
        { name: 'Phone', packagename: 'com.apple.mobilephone', checked: false, type: 'default', image: phone },
        { name: 'Photo-Booth', packagename: 'com.apple.Photo-Booth', checked: false, type: 'default', image: photobooth },
        { name: 'Podcasts', packagename: 'com.apple.podcasts', checked: false, type: 'default', image: Podcasts },
        { name: 'Reminders', packagename: 'com.apple.reminders', checked: false, type: 'default', image: reminder },
        { name: 'Shortcuts', packagename: 'com.apple.shortcuts', checked: false, type: 'default', image: shortcuts },
        { name: 'Stocks', packagename: 'com.apple.stocks', checked: false, type: 'default', image: stocks },
        { name: 'Tips', packagename: 'com.apple.tips', checked: false, type: 'default', image: tips },
        { name: 'Translate', packagename: 'com.apple.Translate', checked: false, type: 'default', image: translate },
        { name: 'TV', packagename: 'com.apple.tv', checked: false, type: 'default', image: AppleTV },
        { name: 'videos', packagename: 'com.apple.videos', checked: false, type: 'default', image: video },
        { name: 'Voice Memos', packagename: 'com.apple.VoiceMemos', checked: false, type: 'default', image: voicememo },
        { name: 'Weather', packagename: 'com.apple.weather', checked: false, type: 'default', image: Weather }
        /*
         * { name: 'TVApp Store', packagename: 'com.apple.TVAppStore', checked: false, type: 'default' },
         * { name: 'TVHome Sharing', packagename: 'com.apple.TVHomeSharing', checked: false, type: 'default' },
         * { name: 'TVMovies', packagename: 'com.apple.TVMovies', checked: false, type: 'default' },
         * { name: 'TVMusic', packagename: 'com.apple.TVMusic', checked: false, type: 'default' },
         * { name: 'TVPhotos', packagename: 'com.apple.TVPhotos', checked: false, type: 'default' },
         * { name: 'TVSearch', packagename: 'com.apple.TVSearch', checked: false, type: 'default' },
         * { name: 'TVShows', packagename: 'com.apple.TVShows', checked: false, type: 'default' },
         * { name: 'TV WatchList', packagename: 'com.apple.TVWatchList', checked: false, type: 'default' },
         * { name: 'fmf1', packagename: 'com.apple.mobileme.fmf1', checked: false, type: 'default' },
         * { name: 'fmip1', packagename: 'com.apple.mobileme.fmip1', checked: false, type: 'default' }
         */
    ],
    macos: [
        { name: 'Safari', packagename: 'com.apple.Safari', checked: false, type: 'default', image: Apple },
        { name: 'iCal', packagename: 'com.apple.iCal', checked: false, type: 'default', image: Apple },
        { name: 'Dictionary', packagename: 'com.apple.Dictionary', checked: false, type: 'default', image: Apple },
        { name: 'Grapher', packagename: 'com.apple.grapher', checked: false, type: 'default', image: Apple },
        { name: 'Notes', packagename: 'com.apple.Notes', checked: false, type: 'default', image: Apple },
        { name: 'Preview', packagename: 'com.apple.Preview', checked: false, type: 'default', image: Apple },
        { name: 'Reminders', packagename: 'com.apple.reminders', checked: false, type: 'default', image: Apple },
        { name: 'Screenshot', packagename: 'com.apple.screenshot.launcher', checked: false, type: 'default', image: Apple },
        { name: 'Shortcuts', packagename: 'com.apple.shortcuts', checked: false, type: 'default', image: Apple },
        { name: 'Stickies', packagename: 'com.apple.Stickies', checked: false, type: 'default', image: Apple },
        { name: 'TextEdit', packagename: 'com.apple.TextEdit', checked: false, type: 'default', image: Apple },
        { name: 'VoiceMemos', packagename: 'com.apple.VoiceMemos', checked: false, type: 'default', image: Apple },
        { name: 'Findmy', packagename: 'com.apple.findmy', checked: false, type: 'default', image: Apple },
        { name: 'Maps', packagename: 'com.apple.Maps', checked: false, type: 'default', image: Apple },
        { name: 'AddressBook', packagename: 'com.apple.AddressBook', checked: false, type: 'default', image: Apple },
        { name: 'FaceTime', packagename: 'com.apple.FaceTime', checked: false, type: 'default', image: Apple },
        { name: 'Mail', packagename: 'com.apple.mail', checked: false, type: 'default', image: Apple },
        { name: 'MobileSMS', packagename: 'com.apple.MobileSMS', checked: false, type: 'default', image: Apple },
        { name: 'AppStore', packagename: 'com.apple.AppStore', checked: false, type: 'default', image: Apple },
        { name: 'iBooksX', packagename: 'com.apple.iBooksX', checked: false, type: 'default', image: Apple },
        { name: 'Chess', packagename: 'com.apple.Chess', checked: false, type: 'default', image: Apple },
        { name: 'DVDPlayer', packagename: 'com.apple.DVDPlayer', checked: false, type: 'default', image: Apple },
        { name: 'Home', packagename: 'com.apple.Home', checked: false, type: 'default', image: Apple },
        { name: 'Music', packagename: 'com.apple.Music', checked: false, type: 'default', image: Apple },
        { name: 'podcasts', packagename: 'com.apple.podcasts', checked: false, type: 'default', image: Apple },
        { name: 'Stocks', packagename: 'com.apple.stocks', checked: false, type: 'default', image: Apple },
        { name: 'TV', packagename: 'com.apple.TV', checked: false, type: 'default', image: Apple },
        { name: 'AudioMIDISetup', packagename: 'com.apple.audio.AudioMIDISetup', checked: false, type: 'default', image: Apple },
        { name: 'ColorSyncUtility', packagename: 'com.apple.ColorSyncUtility', checked: false, type: 'default', image: Apple },
        { name: 'PhotoBooth', packagename: 'com.apple.PhotoBooth', checked: false, type: 'default', image: Apple },
        { name: 'Photos', packagename: 'com.apple.Photos', checked: false, type: 'default', image: Apple },
        { name: 'Automator', packagename: 'com.apple.Automator', checked: false, type: 'default', image: Apple },
        { name: 'Terminal', packagename: 'com.apple.Terminal', checked: false, type: 'default', image: Apple },
        { name: 'VoiceOverUtility', packagename: 'com.apple.VoiceOverUtility', checked: false, type: 'default', image: Apple },
        { name: 'SystemProfiler', packagename: 'com.apple.SystemProfiler', checked: false, type: 'default', image: Apple },
        { name: 'ScriptEditor2', packagename: 'com.apple.ScriptEditor2', checked: false, type: 'default', image: Apple },
        { name: 'QuickTimePlayerX', packagename: 'com.apple.QuickTimePlayerX', checked: false, type: 'default', image: Apple },
        { name: 'MigrateAssistant', packagename: 'com.apple.MigrateAssistant', checked: false, type: 'default', image: Apple },
        { name: 'Keychainaccess', packagename: 'com.apple.keychainaccess', checked: false, type: 'default', image: Apple },
        { name: 'Image Capture', packagename: 'com.apple.Image_Capture', checked: false, type: 'default', image: Apple },
        { name: 'Freeform', packagename: 'com.apple.freeform', checked: false, type: 'default', image: Apple },
        { name: 'Clock', packagename: 'com.apple.clock', checked: false, type: 'default', image: Apple },
        { name: 'Calculator', packagename: 'com.apple.calculator', checked: false, type: 'default', image: Apple },
        { name: 'Siri', packagename: 'com.apple.Siri', checked: false, type: 'default', image: Apple },
        { name: 'Systempreferences', packagename: 'com.apple.systempreferences', checked: false, type: 'default', image: Apple },
        { name: 'Ui', packagename: 'com.apple.configurator.ui', checked: false, type: 'default', image: Apple },
        { name: 'Exposelauncher', packagename: 'com.apple.exposelauncher', checked: false, type: 'default', image: Apple },
        { name: 'Launcher', packagename: 'com.apple.backup.launcher', checked: false, type: 'default', image: Apple },
        { name: 'Printcenter', packagename: 'com.apple.printcenter', checked: false, type: 'default', image: Apple },
        { name: 'DiskUtility', packagename: 'com.apple.DiskUtility', checked: false, type: 'default', image: Apple },
        { name: 'Console', packagename: 'com.apple.Console', checked: false, type: 'default', image: Apple },
        { name: 'Airportutility', packagename: 'com.apple.airport.airportutility', checked: false, type: 'default', image: Apple },
        { name: 'ActivityMonitor', packagename: 'com.apple.ActivityMonitor', checked: false, type: 'default', image: Apple },
        { name: 'Weather', packagename: 'com.apple.weather', checked: false, type: 'default', image: Apple },
        { name: 'ScreenSharing', packagename: 'com.apple.ScreenSharing', checked: false, type: 'default', image: Apple }
    ]
};

export default IOS_DEFAULT_APPS;
