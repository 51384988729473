import React from 'react';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import { Alert, Button, Card, CardBody, CardFooter, CardHeader, Col, Container, Input, Label, Row, UncontrolledTooltip } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import CommonModal from '../../../Components/Common/CommonModal';
import DeleteModal from '../../../Components/Common/DeleteModal';
import Loader from '../../../Components/Common/Loader';
import OffcanvasModal from '../../../Components/Common/OffcanvasModal';
import TabsComponent from '../../../Components/Common/TabsComponent';
import { Tooltip, profileTypeLabel } from '../../../Components/Common/Util';
import NoEnterpriseAccount from '../../ApplicationManagement/NoEnterpriseAccount';
import { DynamicComponents, InputComponent, selectComponent, toggleButton } from './AddPolicyFunctions';
import ClearData from '../../../assets/images/DataLoss.png';
import alertImage from '../../../assets/images/alert.png';
import noGeoFence from '../../../assets/images/alert-line.svg';
import wifiConfigImage from '../../../assets/images/wifiConf.jpg';
import AndroidIcon from '../../../assets/images/svg/android.svg';
import noSetupAction from '../../../assets/images/setupAction.png';

export const AddPolicyHTML = (props) => {
    let history = useHistory();

    const message = () => {
        return (
            <div className="mt-3">
                <div className="mb-2">Are you sure you want to Switch?</div>
                <div className="mb-2 d-flex align-items-center justify-content-center">
                    <img src={ClearData} alt="deleteGroup" width={60} height={60} />
                </div>
                <div>Kiosk app configuraion will be lost.</div>
            </div>
        );
    };

    const alertMessage = () => {
        return (
            <div>
                <div className="mb-3 d-flex align-items-center justify-content-center">
                    <img src={alertImage} alt="alert message" width={60} height={60} />
                </div>
                <div>{props.selectedToggleDetails?.field?.alertMessage}</div>
            </div>
        );
    };
    const handleRemarksModalBody = () => {
        return (
            <div className="d-flex align-items-center justify-content-center">
                <Input
                    type="textarea"
                    id="reasonForRejection"
                    name="reasonForRejection"
                    className="form-control mt-2"
                    onChange={(e) => props.setRemarks(e.target.value)}
                    value={props.remarks}
                    placeholder="Reason for rejection"
                />
            </div>
        );
    };

    const handleConfirmationModalBody = () => {
        return (
            <div className="d-flex flex-column justify-content-center align-items-center">
                <lord-icon
                    src="https://cdn.lordicon.com/lfqzieho.json"
                    trigger="hover"
                    colors="primary:#eee966"
                    // eslint-disable-next-line no-inline-styles/no-inline-styles
                    style={{ width: '150px', height: '150px' }}
                ></lord-icon>
                <div className="mt-2 mb-5">
                    <span className="fs-14">
                        This property{' '}
                        <span className="fw-bold">
                            {props.details.key[0] === 'extensionConfig' ? 'Extension Config' : 'Delegated Scope'}
                        </span>{' '}
                        is already set to{' '}
                        <span className="fw-bold">{props.details.key[0] === 'extensionConfig' ? 'true' : props.details.label}</span> in
                        another application do you want to switch to this application
                    </span>
                </div>
            </div>
        );
    };

    const handlePackageConfigBody = () => {
        return (
            <React.Fragment>
                <div className={'form-icon mb-4'}>
                    <input
                        autoComplete="off"
                        type="text"
                        id="search-bar-0"
                        value={props.searchPackage || ''}
                        className={'form-control form-control-icon dash-filter-picker'}
                        placeholder={'Search by app name'}
                        onChange={props.handlePackageSearch}
                    />
                    <i className="ri-search-line link-info"></i>
                </div>
                {props.configPackages?.default?.length > 0 ? (
                    props.configPackages?.default?.map((app, ind) => {
                        return (
                            <div className="mb-4" key={ind}>
                                <div className="d-flex align-items-center">
                                    <input
                                        type={'checkbox'}
                                        className={'form-check-input border-primary me-3 width-15 height-15'}
                                        id={`checkbox-${ind}`}
                                        checked={app.checked}
                                        disabled={props.formType === 'view'}
                                        onChange={(e) => props.handlePackageSelect(e, app, ind)}
                                    />
                                    <img src={app.smallIconUrl ? app.smallIconUrl : AndroidIcon} width={30} alt={AndroidIcon} />
                                    <span className="ms-1">{app.title ? app.title : app.packageName}</span>
                                </div>
                            </div>
                        );
                    })
                ) : (
                    <table>
                        <tr>
                            <td>No apps found in default list.</td>
                        </tr>
                    </table>
                )}
            </React.Fragment>
        );
    };

    const handleShowOn = (field) => {
        let flag = true;
        flag = field.showVal.includes(props.validation.values?.[field.showOn]);
        return flag;
    };

    return (
        <React.Fragment>
            {props.loading && <Loader />}
            <div className={`${props.code ? '' : 'page-content'} ${props.loading ? 'postion-relative mask' : ''}`}>
                <Container fluid>
                    {!props.code && (
                        <BreadCrumb
                            pageTitle={
                                props.formType === 'view' || props.formType === 'viewChecker'
                                    ? 'View Policy'
                                    : props.formType === 'edit'
                                    ? 'Edit Policy'
                                    : 'Add Policy'
                            }
                            history={history}
                            homeLink="Dashboard"
                            showBack={true}
                            backLink="aepolicies"
                        />
                    )}
                    {!props.enterpriseObj?.id ? (
                        <NoEnterpriseAccount screen="Policy" />
                    ) : (
                        <div className="h-100">
                            <Card className="card-height-100">
                                <CardHeader className="p-4">
                                    <Row>
                                        <Col lg={6}>
                                            <Row>
                                                <Col xs={6} md={6} sm={6} lg={5} xl={5}>
                                                    <Label className="form-label d-flex align-items-center">
                                                        Policy Type <span className="ps-1">:</span>
                                                    </Label>
                                                </Col>
                                                <Col>
                                                    <div className="input-group fw-medium">{profileTypeLabel(props.polType)}</div>
                                                </Col>
                                            </Row>
                                        </Col>
                                        {/* <Col lg={6}>
                                            {props.formType !== 'view' ? (
                                                <div className="d-flex align-items-center justify-content-end gap-2 me-4">
                                                    <input
                                                        type="checkbox"
                                                        className={'form-check-input border-primary mt-0 width-15 height-15'}
                                                        id="checkbox"
                                                        checked={!!props.protectedPolicy}
                                                        onChange={(e) => props.setProtectedPolicy(e.target.checked)}
                                                    />
                                                    Secure your policy{' '}
                                                    <i className="ri-information-line cursor-pointer link-info" id="infoProtected" />
                                                    {Tooltip(
                                                        'infoProtected',
                                                        `Use default password (${props.tenant.DEVICE_DEFAULT_PASSWORD}) to unlock the policy.`
                                                    )}
                                                </div>
                                            ) : (
                                                <div className="d-flex align-items-center justify-content-end gap-2 me-4">
                                                    {!!props.protectedPolicy ? (
                                                        <>
                                                            <i className="ri-lock-2-fill link-primary me-1 fs-18" /> Secured
                                                        </>
                                                    ) : (
                                                        <>
                                                            <i className="ri-lock-unlock-fill link-primary me-1 fs-18" />
                                                            Not Secured
                                                        </>
                                                    )}
                                                </div>
                                            )}
                                        </Col> */}
                                    </Row>
                                    <Row>
                                        <Col lg={6} className="d-flex flex-column gap-2">
                                            <Row>
                                                <InputComponent
                                                    field={{
                                                        label: 'Policy Name',
                                                        value: 'policyName',
                                                        helpText: 'Enter policy name',
                                                        type: 'text',
                                                        exclude: true,
                                                        mandatory: true,
                                                        codeBased: false
                                                    }}
                                                    formType={props.formType}
                                                    handleKeyDown={props.handleKeyDown}
                                                    index={'index'}
                                                    validation={props.validation}
                                                />
                                            </Row>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody className="p-0">
                                    <Row className={`${!props.validation.values.policyName ? 'mask pe-none' : ''}`}>
                                        <Col xs={12} sm={12} md={12} lg={props.code ? 5 : 3} xl={props.code ? 4 : 2}>
                                            <Card className="h-100 border mb-0 shadow-none">
                                                <div className="chat-message-list">
                                                    <ul className="list-unstyled chat-list chat-user-list users-list" id="userList">
                                                        {props.profileDetails.map((profile, index) => {
                                                            return (
                                                                <li
                                                                    key={index}
                                                                    onClick={() => props.handleProfileDetail(profile, index)}
                                                                    className={
                                                                        'd-flex align-items-center p-2 cursor-pointer' +
                                                                        (profile.active ? ' active' : '')
                                                                    }
                                                                >
                                                                    <i className={`${profile.icon} fs-18 ms-2 me-2`}></i>
                                                                    <span className="text-truncate" id={'label-' + index}>
                                                                        {profile.label}
                                                                        {profile.label?.length > 22 &&
                                                                            Tooltip('label-' + index, profile.label)}
                                                                    </span>
                                                                </li>
                                                            );
                                                        })}
                                                    </ul>
                                                </div>
                                            </Card>
                                        </Col>
                                        {props.selected && props.selected !== '' && (
                                            <Col
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                lg={
                                                    props.selected &&
                                                    (props.selected.extraContent ||
                                                        (!props.addWifiConfig && props.selected.customBody) ||
                                                        props.selected.label === 'Launcher Settings' ||
                                                        props.selected.label === 'Setup Actions')
                                                        ? props.code
                                                            ? 7
                                                            : 9
                                                        : props.code
                                                        ? 7
                                                        : 6
                                                }
                                                xl={
                                                    props.selected &&
                                                    (props.selected.extraContent ||
                                                        (!props.addWifiConfig && props.selected.customBody) ||
                                                        props.selected.label === 'Launcher Settings' ||
                                                        props.selected.label === 'Setup Actions')
                                                        ? props.code
                                                            ? 8
                                                            : 10
                                                        : props.code
                                                        ? 8
                                                        : 7
                                                }
                                            >
                                                <Row sm={12} className="p-4 gap-3 align-items-center">
                                                    {!props.selected.extraContent &&
                                                    props.selected.label !== 'Launcher Settings' &&
                                                    props.selected.label !== 'Setup Actions' &&
                                                    props.selected.fields?.length > 0 ? (
                                                        props.selected.fields.map((field, index) => {
                                                            return (
                                                                (field.hide
                                                                    ? props.validation?.values?.[field.hide] ||
                                                                      props.validation?.values?.[field.value]?.value === field.hide
                                                                    : field.showKey
                                                                    ? props.validation.values?.[field.showKey]?.find(
                                                                          (obj) => obj.value === field.showValue
                                                                      )
                                                                    : true) &&
                                                                (field.showOn ? handleShowOn(field) : true) && (
                                                                    <Row
                                                                        className={`p-0 ${field.notes ? '' : 'align-items-center'}`}
                                                                        key={index}
                                                                    >
                                                                        <DynamicComponents
                                                                            field={field}
                                                                            index={index}
                                                                            code={props.code}
                                                                            formType={props.formType}
                                                                            selected={props.selected}
                                                                            handleChange={props.handleChange}
                                                                            validation={props.validation}
                                                                            handleKeyDown={props.handleKeyDown}
                                                                            applicationObj={props.applicationObj}
                                                                            selectedAppData={props.selectedAppData}
                                                                            handleAlert={props.handleAlert}
                                                                            mode={props.mode}
                                                                            assets={props.assets}
                                                                            configurations={props.configurations}
                                                                            applicationData={props.applicationData}
                                                                            wallpapers={props.wallpapers}
                                                                            bootAnimations={props.bootAnimations}
                                                                            handleLaunchAppChange={props.handleLaunchAppChange}
                                                                            handleLocationModeChange={props.handleLocationModeChange}
                                                                            handleAddFRPMail={props.handleAddFRPMail}
                                                                            isInvalidMail={props.isInvalidMail}
                                                                            handleRemoveOneFRPMail={props.handleRemoveOneFRPMail}
                                                                            handleCardClick={props.handleCardClick}
                                                                            fp1={props.fp1}
                                                                            fp2={props.fp2}
                                                                        />
                                                                        {/* {dynamicComponents(
                                                                            (field,
                                                                            index,
                                                                            props.code,
                                                                            props.formType,
                                                                            props.selected,
                                                                            props.handleChange,
                                                                            props.validation,
                                                                            props.handleKeyDown,
                                                                            props.applicationObj,
                                                                            props.selectedAppData,
                                                                            props.handleAlert,
                                                                            props.mode,
                                                                            props.configurations,
                                                                            props.applicationData,
                                                                            props.wallpapers,
                                                                            props.bootAnimations,
                                                                            props.handleLaunchAppChange,
                                                                            props.handleLocationModeChange,
                                                                            props.handleAddFRPMail,
                                                                            props.isInvalidMail,
                                                                            props.handleRemoveOneFRPMail,
                                                                            props.handleCardClick,
                                                                            props.fp1,
                                                                            props.fp2)
                                                                        )} */}
                                                                    </Row>
                                                                )
                                                            );
                                                        })
                                                    ) : props.selected.label === 'Setup Actions' ? (
                                                        <>
                                                            {!props.showAddAction ? (
                                                                <>
                                                                    <Row>
                                                                        <div className="d-flex align-items-center justify-content-between p-0">
                                                                            <div className="d-flex align-items-center">
                                                                                <Label className="fw-semibold text-decoration-underline text-success fs-14 m-0">
                                                                                    Setup Actions
                                                                                </Label>
                                                                                <i
                                                                                    id="setupActions"
                                                                                    className="ri-information-line d-flex align-items-center blue-icon ps-1 fs-15"
                                                                                />
                                                                                <UncontrolledTooltip
                                                                                    placement="bottom"
                                                                                    target="setupActions"
                                                                                >
                                                                                    Setup Action refers to a required step that must be
                                                                                    completed during the device provisioning process to
                                                                                    prepare the app for use
                                                                                </UncontrolledTooltip>
                                                                            </div>
                                                                            {!props.addWifiConfig &&
                                                                                props.formType !== 'view' &&
                                                                                props.formType !== 'viewChecker' &&
                                                                                props.validation?.values?.setupActions?.length === 0 && (
                                                                                    <div>
                                                                                        <Button
                                                                                            size="md"
                                                                                            type="button"
                                                                                            color="success"
                                                                                            onClick={() => {
                                                                                                props.setActionMode('add');
                                                                                                props.setShowAddAction(true);
                                                                                            }}
                                                                                        >
                                                                                            + Add Setup Action
                                                                                        </Button>
                                                                                    </div>
                                                                                )}
                                                                        </div>
                                                                    </Row>
                                                                    <Row>
                                                                        {props.validation?.values?.setupActions &&
                                                                        props.validation?.values?.setupActions?.length > 0 ? (
                                                                            props.validation?.values?.setupActions.map((action, ind) => (
                                                                                <Col sm={4} key={ind}>
                                                                                    <Card className="card-height-100 shadow-card cursor-pointer rounded-2 mt-3">
                                                                                        <CardBody className="pb-0">
                                                                                            <div className="d-flex position-relative text-bold">
                                                                                                <p className="m-0 ms-1 p-0">
                                                                                                    {' ' + action?.title
                                                                                                        ? action?.title
                                                                                                        : '–'}
                                                                                                </p>
                                                                                            </div>
                                                                                            <div className="d-flex position-relative mt-2 text-muted text-wrap w-100">
                                                                                                <p
                                                                                                    className="m-0 ms-1 p-0 overflow-auto"
                                                                                                    id={`description-${ind}`}
                                                                                                >
                                                                                                    {' ' + action?.description
                                                                                                        ? action?.description?.length > 160
                                                                                                            ? action?.description?.substring(
                                                                                                                  0,
                                                                                                                  160
                                                                                                              ) + '...'
                                                                                                            : action.description
                                                                                                        : '–'}
                                                                                                </p>
                                                                                                {action.description?.length > 160 &&
                                                                                                    Tooltip(
                                                                                                        `description-${ind}`,
                                                                                                        action.description
                                                                                                    )}
                                                                                            </div>
                                                                                            <div className="d-flex position-relative mt-2 text-wrap text-info w-100">
                                                                                                <p className="m-0 ms-1 p-0 overflow-auto">
                                                                                                    {' ' + action?.launchApp
                                                                                                        ? action?.launchApp
                                                                                                        : '–'}
                                                                                                </p>
                                                                                            </div>
                                                                                        </CardBody>
                                                                                        {props.formType !== 'view' &&
                                                                                            props.formType !== 'viewChecker' && (
                                                                                                <CardFooter className="py-2 px-4">
                                                                                                    <div className="d-flex align-items-center justify-content-end position-relative">
                                                                                                        <i
                                                                                                            className="ri-delete-bin-7-fill link-danger me-2 fs-18 cursor-pointer"
                                                                                                            onClick={() =>
                                                                                                                props.handleRemoveSetupAction(
                                                                                                                    ind
                                                                                                                )
                                                                                                            }
                                                                                                        />
                                                                                                        <i
                                                                                                            className="ri-pencil-fill link-primary cursor-pointer fs-18"
                                                                                                            onClick={() =>
                                                                                                                props.handleEditSetupAction(
                                                                                                                    action,
                                                                                                                    ind
                                                                                                                )
                                                                                                            }
                                                                                                        />
                                                                                                    </div>
                                                                                                </CardFooter>
                                                                                            )}
                                                                                    </Card>
                                                                                </Col>
                                                                            ))
                                                                        ) : (
                                                                            <div
                                                                                className="d-flex flex-column align-items-center mt-6"
                                                                                key={'key'}
                                                                            >
                                                                                <img
                                                                                    src={noSetupAction}
                                                                                    alt="No Setup Action"
                                                                                    width={60}
                                                                                    height={60}
                                                                                />
                                                                                <p className="text-muted mt-2">No Setup Actions found.</p>
                                                                            </div>
                                                                        )}
                                                                    </Row>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    {props.selected.fields.map(
                                                                        (field, ind) => (
                                                                            <DynamicComponents
                                                                                key={ind}
                                                                                field={field}
                                                                                index={ind}
                                                                                code={props.code}
                                                                                formType={props.formType}
                                                                                selected={props.selected}
                                                                                handleChange={props.handleChange}
                                                                                validation={props.validation}
                                                                                handleKeyDown={props.handleKeyDown}
                                                                                applicationObj={props.applicationObj}
                                                                                selectedAppData={props.selectedAppData}
                                                                                handleAlert={props.handleAlert}
                                                                                mode={props.mode}
                                                                                assets={props.assets}
                                                                                configurations={props.configurations}
                                                                                applicationData={props.applicationData}
                                                                                wallpapers={props.wallpapers}
                                                                                bootAnimations={props.bootAnimations}
                                                                                handleLaunchAppChange={props.handleLaunchAppChange}
                                                                                handleLocationModeChange={props.handleLocationModeChange}
                                                                                handleAddFRPMail={props.handleAddFRPMail}
                                                                                isInvalidMail={props.isInvalidMail}
                                                                                handleRemoveOneFRPMail={props.handleRemoveOneFRPMail}
                                                                                handleCardClick={props.handleCardClick}
                                                                                fp1={props.fp1}
                                                                                fp2={props.fp2}
                                                                            />
                                                                        )
                                                                        /*
                                                                         * dynamicComponents(
                                                                         *     (field,
                                                                         *     ind,
                                                                         *     props.code,
                                                                         *     props.formType,
                                                                         *     props.selected,
                                                                         *     props.handleChange,
                                                                         *     props.validation,
                                                                         *     props.handleKeyDown,
                                                                         *     props.applicationObj,
                                                                         *     props.selectedAppData,
                                                                         *     props.handleAlert,
                                                                         *     props.mode,
                                                                         *     props.configurations,
                                                                         *     props.applicationData,
                                                                         *     props.wallpapers,
                                                                         *     props.bootAnimations,
                                                                         *     props.handleLaunchAppChange,
                                                                         *     props.handleLocationModeChange,
                                                                         *     props.handleAddFRPMail,
                                                                         *     props.isInvalidMail,
                                                                         *     props.handleRemoveOneFRPMail,
                                                                         *     props.handleCardClick,
                                                                         *     props.fp1,
                                                                         *     props.fp2)
                                                                         * )
                                                                         */
                                                                    )}
                                                                    {props.setupExisting && (
                                                                        <Alert
                                                                            className="alert-borderless alert-warning text-center mt-3 mb-0"
                                                                            role="alert"
                                                                        >
                                                                            This application is already used in the applications screen
                                                                            please select another application.
                                                                        </Alert>
                                                                    )}
                                                                    <div className="d-flex align-items-center justify-content-end gap-2 mt-2">
                                                                        <Button
                                                                            type="button"
                                                                            className="btn btn-light"
                                                                            onClick={props.handleCancelSetupAction}
                                                                        >
                                                                            Cancel
                                                                        </Button>
                                                                        <Button
                                                                            size="md"
                                                                            type="button"
                                                                            color="primary"
                                                                            disabled={
                                                                                !props.validation?.values?.title ||
                                                                                props.validation?.values?.title === '' ||
                                                                                !props.validation?.values?.description ||
                                                                                props.validation?.values?.description === '' ||
                                                                                !props.validation?.values?.launchApp ||
                                                                                props.validation?.values?.launchApp === '' ||
                                                                                props.setupExisting
                                                                            }
                                                                            onClick={
                                                                                props.actionMode === 'edit'
                                                                                    ? props.handleUpdateSetupAction
                                                                                    : props.handleAddSetupAction
                                                                            }
                                                                        >
                                                                            {props.actionMode === 'edit' ? 'Update' : 'Add'}
                                                                        </Button>
                                                                    </div>
                                                                </>
                                                            )}
                                                        </>
                                                    ) : props.selected.label === 'Launcher Settings' ? (
                                                        <>
                                                            {!props.showKioskAppConfig ? (
                                                                <Row className="mt-1 mb-1">
                                                                    {(props.polType === 'TL' || props.polType === 'SA') &&
                                                                        props.kioskApps.map((kiosk, ind) => (
                                                                            <React.Fragment key={ind}>
                                                                                <Col
                                                                                    xs={12}
                                                                                    sm={6}
                                                                                    md={4}
                                                                                    xl={props.code ? 12 : 3}
                                                                                    lg={4}
                                                                                    className="padding-x-15"
                                                                                >
                                                                                    <Card
                                                                                        className={`card-height-100 ${
                                                                                            kiosk.hideboxShadow
                                                                                                ? 'border border-1'
                                                                                                : 'shadow-card'
                                                                                        }`}
                                                                                    >
                                                                                        <CardHeader className="p-0">
                                                                                            <div className="d-flex align-items-center justify-content-end padding-top-10 padding-bottom-10 padding-right-10">
                                                                                                <input
                                                                                                    type="radio"
                                                                                                    checked={kiosk.checked}
                                                                                                    className="form-check-input border-primary"
                                                                                                    onClick={() =>
                                                                                                        props.handleKioskAppSelection(
                                                                                                            kiosk,
                                                                                                            ind
                                                                                                        )
                                                                                                    }
                                                                                                    disabled={
                                                                                                        props.formType === 'view' ||
                                                                                                        props.formType === 'viewChecker'
                                                                                                    }
                                                                                                    onChange={props.handleChangeKioskApp}
                                                                                                />
                                                                                            </div>
                                                                                        </CardHeader>
                                                                                        <CardBody
                                                                                            className={`${
                                                                                                kiosk.showConfig &&
                                                                                                kiosk.checked &&
                                                                                                props.formType !== 'view' &&
                                                                                                props.formType !== 'viewChecker'
                                                                                                    ? 'cursor-pointer'
                                                                                                    : ''
                                                                                            }`}
                                                                                            onClick={() => {
                                                                                                if (kiosk.showConfig && kiosk.checked)
                                                                                                    props.handleKioskCardClick(kiosk, ind);
                                                                                            }}
                                                                                        >
                                                                                            <div className="d-flex align-items-center justify-content-center flex-column">
                                                                                                <div className="h-100">
                                                                                                    <div className="d-flex h-100 align-items-center justify-content-center">
                                                                                                        <div>
                                                                                                            <div className="d-flex justify-content-center">
                                                                                                                <img
                                                                                                                    src={
                                                                                                                        kiosk.image[
                                                                                                                            props.polType
                                                                                                                        ]
                                                                                                                    }
                                                                                                                    alt={
                                                                                                                        kiosk.alt[
                                                                                                                            props.polType
                                                                                                                        ]
                                                                                                                    }
                                                                                                                    width="50"
                                                                                                                    height="50"
                                                                                                                />
                                                                                                            </div>
                                                                                                            <div className="d-flex justify-content-center align-items-center flex-column margin-top-10">
                                                                                                                <div className="fw-medium fs-14 mt-3 text-primary">
                                                                                                                    {
                                                                                                                        kiosk.label[
                                                                                                                            props.polType
                                                                                                                        ]
                                                                                                                    }
                                                                                                                </div>
                                                                                                                <div
                                                                                                                    className={`text-${kiosk.infoClass} d-flex justify-content-center align-items-center margin-top-10`}
                                                                                                                >
                                                                                                                    <i className="ri-information-line fs-10 me-1" />
                                                                                                                    {kiosk.kioskInfo}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </CardBody>
                                                                                    </Card>
                                                                                </Col>
                                                                            </React.Fragment>
                                                                        ))}
                                                                    {props.showKioskSettings && (
                                                                        <Row className="mt-3">
                                                                            {props.selected.fields.map((field, index) => {
                                                                                return (
                                                                                    <Row
                                                                                        className={`p-0 mt-2 ${
                                                                                            field.notes ? '' : 'align-items-center'
                                                                                        }`}
                                                                                        key={index}
                                                                                    >
                                                                                        {
                                                                                            (field.show
                                                                                                ? props.validation?.values?.[field.show]
                                                                                                : true) && (
                                                                                                <DynamicComponents
                                                                                                    field={field}
                                                                                                    index={index}
                                                                                                    code={props.code}
                                                                                                    formType={props.formType}
                                                                                                    selected={props.selected}
                                                                                                    handleChange={props.handleChange}
                                                                                                    validation={props.validation}
                                                                                                    handleKeyDown={props.handleKeyDown}
                                                                                                    applicationObj={props.applicationObj}
                                                                                                    selectedAppData={props.selectedAppData}
                                                                                                    handleAlert={props.handleAlert}
                                                                                                    mode={props.mode}
                                                                                                    assets={props.assets}
                                                                                                    configurations={props.configurations}
                                                                                                    applicationData={props.applicationData}
                                                                                                    wallpapers={props.wallpapers}
                                                                                                    bootAnimations={props.bootAnimations}
                                                                                                    handleLaunchAppChange={
                                                                                                        props.handleLaunchAppChange
                                                                                                    }
                                                                                                    handleLocationModeChange={
                                                                                                        props.handleLocationModeChange
                                                                                                    }
                                                                                                    handleAddFRPMail={
                                                                                                        props.handleAddFRPMail
                                                                                                    }
                                                                                                    isInvalidMail={props.isInvalidMail}
                                                                                                    handleRemoveOneFRPMail={
                                                                                                        props.handleRemoveOneFRPMail
                                                                                                    }
                                                                                                    handleCardClick={props.handleCardClick}
                                                                                                    fp1={props.fp1}
                                                                                                    fp2={props.fp2}
                                                                                                />
                                                                                            )
                                                                                            /*
                                                                                             * dynamicComponents(
                                                                                             *     (field,
                                                                                             *     index,
                                                                                             *     props.code,
                                                                                             *     props.formType,
                                                                                             *     props.selected,
                                                                                             *     props.handleChange,
                                                                                             *     props.validation,
                                                                                             *     props.handleKeyDown,
                                                                                             *     props.applicationObj,
                                                                                             *     props.selectedAppData,
                                                                                             *     props.handleAlert,
                                                                                             *     props.mode,
                                                                                             *     props.configurations,
                                                                                             *     props.applicationData,
                                                                                             *     props.wallpapers,
                                                                                             *     props.bootAnimations,
                                                                                             *     props.handleLaunchAppChange,
                                                                                             *     props.handleLocationModeChange,
                                                                                             *     props.handleAddFRPMail,
                                                                                             *     props.isInvalidMail,
                                                                                             *     props.handleRemoveOneFRPMail,
                                                                                             *     props.handleCardClick,
                                                                                             *     props.fp1,
                                                                                             *     props.fp2)
                                                                                             * )
                                                                                             */
                                                                                        }
                                                                                    </Row>
                                                                                );
                                                                            })}
                                                                        </Row>
                                                                    )}
                                                                </Row>
                                                            ) : (
                                                                <Row className="ms-1 mt-3 gap-3 align-items-center">
                                                                    <div className="d-flex justify-content-between p-0">
                                                                        <div
                                                                            className="text-success fw-medium fs-14 d-flex align-items-center cursor-pointer"
                                                                            onClick={props.handleGoBack}
                                                                        >
                                                                            <i className="ri-arrow-left-line me-2" />
                                                                            Go Back
                                                                        </div>
                                                                        {/* <Button
                                                                        size="sm"
                                                                        className="btn btn-success mb-3"
                                                                        onClick={() => setOffcanvasModal(true)}
                                                                    >
                                                                        <span className="d-flex align-items-center">
                                                                            {applicationObj[appPackageName]?.mcmId ? (
                                                                                <i className="ri-edit-box-line fs-16" />
                                                                            ) : (
                                                                                <i className="ri-add-line fs-16"></i>
                                                                            )}
                                                                            <span className="ps-1">App Managed Configuration</span>
                                                                        </span>
                                                                    </Button> */}
                                                                    </div>
                                                                    {props.polType !== 'TL' && (
                                                                        <Row className="mt-2 mb-2">
                                                                            <Col
                                                                                xs={6}
                                                                                md={6}
                                                                                sm={6}
                                                                                lg={props.code ? 7 : 5}
                                                                                xl={props.code ? 7 : 5}
                                                                                className="d-flex align-items-center justify-content-between"
                                                                            >
                                                                                <Label className="mb-0 fw-medium d-flex align-items-center">
                                                                                    Select Launcher App
                                                                                    <i
                                                                                        className="ri-information-line d-flex align-items-center blue-icon ps-1 fs-15"
                                                                                        id="selectComponent-launcher"
                                                                                    />
                                                                                    <UncontrolledTooltip
                                                                                        placement="bottom"
                                                                                        target="selectComponent-launcher"
                                                                                    >
                                                                                        This acts as the custom launcher.
                                                                                    </UncontrolledTooltip>
                                                                                </Label>
                                                                            </Col>
                                                                            <Col xs={6} md={6} sm={6} lg={5} xl={5}>
                                                                                {props.formType === 'view' ||
                                                                                props.formType === 'viewChecker' ||
                                                                                props.code ? (
                                                                                    props.selectedLauncherApp?.title ? (
                                                                                        props.selectedLauncherApp?.title
                                                                                    ) : (
                                                                                        '–'
                                                                                    )
                                                                                ) : (
                                                                                    <Select
                                                                                        getOptionValue={(option) => option.packageName}
                                                                                        getOptionLabel={(option) => option.title}
                                                                                        id={'Select Launcher App'}
                                                                                        name={'launcher_app'}
                                                                                        options={props.applicationData}
                                                                                        placeholder={'Select Launcher App'}
                                                                                        onChange={(option) =>
                                                                                            props.handleConfigKiosk(option)
                                                                                        }
                                                                                        value={props.selectedLauncherApp}
                                                                                        isSearchable={true}
                                                                                        noOptionsMessage={() => 'No apps found'}
                                                                                    />
                                                                                )}
                                                                            </Col>
                                                                        </Row>
                                                                    )}
                                                                    {props.showAppError && (
                                                                        <Alert
                                                                            className="alert-borderless alert-warning text-center mb-2 mx-2"
                                                                            role="alert"
                                                                        >
                                                                            This app is already selected in another section.
                                                                        </Alert>
                                                                    )}
                                                                    {props.showKioskConfig && (
                                                                        <React.Fragment>
                                                                            <Row className="mb-2">
                                                                                <div className="d-flex align-items-center justify-content-center">
                                                                                    <img
                                                                                        src={props.selectedAppData?.smallIconUrl}
                                                                                        alt="icon"
                                                                                        height={100}
                                                                                    />
                                                                                </div>
                                                                            </Row>
                                                                            <Row className="p-0 align-items-center">
                                                                                <Col
                                                                                    xs={6}
                                                                                    md={6}
                                                                                    sm={6}
                                                                                    lg={props.code ? 7 : 5}
                                                                                    xl={props.code ? 7 : 5}
                                                                                    className="d-flex align-items-center justify-content-between"
                                                                                >
                                                                                    <Label className="mb-0 fw-medium d-flex align-items-center justify-content-between">
                                                                                        Application Name :
                                                                                    </Label>
                                                                                </Col>
                                                                                <Col xs={6} md={6} sm={6} lg={5} xl={5}>
                                                                                    <div className="input-group fw-medium">
                                                                                        {props.selectedAppData?.title}
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                            <Row className="p-0 align-items-center mt-1">
                                                                                <Col
                                                                                    xs={6}
                                                                                    md={6}
                                                                                    sm={6}
                                                                                    lg={props.code ? 7 : 5}
                                                                                    xl={props.code ? 7 : 5}
                                                                                    className="d-flex align-items-center justify-content-between"
                                                                                >
                                                                                    <Label className="mb-0 fw-medium d-flex align-items-center justify-content-between">
                                                                                        Package Name :
                                                                                    </Label>
                                                                                </Col>
                                                                                <Col xs={6} md={6} sm={6} lg={5} xl={5}>
                                                                                    <div className="input-group fw-medium">
                                                                                        {props.selectedAppData?.packageName}
                                                                                        <span className="me-2">
                                                                                            <i
                                                                                                id={'copy'}
                                                                                                onClick={(e) =>
                                                                                                    props.copyToClipboard(
                                                                                                        e,
                                                                                                        props.selectedAppData?.packageName,
                                                                                                        'copy'
                                                                                                    )
                                                                                                }
                                                                                                className="ri-file-copy-line ms-2 fs-13 cursor-pointer link-primary"
                                                                                            ></i>
                                                                                            {Tooltip('copy', 'Copy')}
                                                                                        </span>
                                                                                    </div>
                                                                                </Col>
                                                                            </Row>
                                                                            {props.selectedAppData?.active &&
                                                                                props.selectedAppData?.selected &&
                                                                                props.selectedAppData?.fieldsList?.length > 0 &&
                                                                                props.selectedAppData.fieldsList.map((field, index) => {
                                                                                    return (
                                                                                        <Row className="p-0 align-items-center" key={index}>
                                                                                            <DynamicComponents
                                                                                                field={field}
                                                                                                index={index}
                                                                                                code={props.code}
                                                                                                formType={props.formType}
                                                                                                selected={props.selected}
                                                                                                handleChange={props.handleChange}
                                                                                                validation={props.validation}
                                                                                                handleKeyDown={props.handleKeyDown}
                                                                                                applicationObj={props.applicationObj}
                                                                                                selectedAppData={props.selectedAppData}
                                                                                                handleAlert={props.handleAlert}
                                                                                                mode={props.mode}
                                                                                                assets={props.assets}
                                                                                                configurations={props.configurations}
                                                                                                applicationData={props.applicationData}
                                                                                                wallpapers={props.wallpapers}
                                                                                                bootAnimations={props.bootAnimations}
                                                                                                handleLaunchAppChange={
                                                                                                    props.handleLaunchAppChange
                                                                                                }
                                                                                                handleLocationModeChange={
                                                                                                    props.handleLocationModeChange
                                                                                                }
                                                                                                handleAddFRPMail={props.handleAddFRPMail}
                                                                                                isInvalidMail={props.isInvalidMail}
                                                                                                handleRemoveOneFRPMail={
                                                                                                    props.handleRemoveOneFRPMail
                                                                                                }
                                                                                                handleCardClick={props.handleCardClick}
                                                                                                fp1={props.fp1}
                                                                                                fp2={props.fp2}
                                                                                            />
                                                                                            {/* {dynamicComponents(
                                                                                                field,
                                                                                                index,
                                                                                                props.code,
                                                                                                props.formType,
                                                                                                props.selected,
                                                                                                props.handleChange,
                                                                                                props.validation,
                                                                                                props.handleKeyDown,
                                                                                                props.applicationObj,
                                                                                                props.selectedAppData,
                                                                                                props.handleAlert,
                                                                                                props.mode,
                                                                                                props.configurations,
                                                                                                props.applicationData,
                                                                                                props.wallpapers,
                                                                                                props.bootAnimations,
                                                                                                props.handleLaunchAppChange,
                                                                                                props.handleLocationModeChange,
                                                                                                props.handleAddFRPMail,
                                                                                                props.isInvalidMail,
                                                                                                props.handleRemoveOneFRPMail,
                                                                                                props.handleCardClick,
                                                                                                props.fp1,
                                                                                                props.fp2
                                                                                            )} */}
                                                                                        </Row>
                                                                                    );
                                                                                })}
                                                                        </React.Fragment>
                                                                    )}
                                                                </Row>
                                                            )}
                                                        </>
                                                    ) : !props.selected.extraContent && props.selected.customBody ? (
                                                        <>
                                                            <Row>
                                                                <div className="d-flex align-items-center justify-content-between p-0">
                                                                    <div className="d-flex align-items-center">
                                                                        <Label className="fw-semibold text-decoration-underline text-success fs-14 m-0">
                                                                            Configure Wi-Fi
                                                                        </Label>
                                                                        <i
                                                                            id="confWifi"
                                                                            className="ri-information-line d-flex align-items-center blue-icon ps-1 fs-15"
                                                                        />
                                                                        <UncontrolledTooltip placement="bottom" target="confWifi">
                                                                            Define Wi-Fi configurations on the device
                                                                        </UncontrolledTooltip>
                                                                    </div>
                                                                    {!props.addWifiConfig &&
                                                                        props.formType !== 'view' &&
                                                                        props.formType !== 'viewChecker' && (
                                                                            <div>
                                                                                <Button
                                                                                    size="md"
                                                                                    type="button"
                                                                                    color="success"
                                                                                    onClick={() => {
                                                                                        props.setWifiMode('add');
                                                                                        props.setAddWifiConfig(true);
                                                                                    }}
                                                                                >
                                                                                    + Add Wi-Fi Configuration
                                                                                </Button>
                                                                            </div>
                                                                        )}
                                                                </div>
                                                                {props.wifiConfiguration?.length === 0 &&
                                                                    !props.addWifiConfig &&
                                                                    (!props.wifiConfiguration || props.wifiConfiguration?.length === 0) && (
                                                                        <div className="d-flex flex-column align-items-center mt-6">
                                                                            <img
                                                                                src={noGeoFence}
                                                                                alt="No Geofence"
                                                                                width={60}
                                                                                height={60}
                                                                            />
                                                                            <p className="text-muted mt-2">No Configurations found.</p>
                                                                        </div>
                                                                    )}
                                                                {!props.addWifiConfig && props.wifiConfiguration?.length > 0 && (
                                                                    <Row>
                                                                        {props.wifiConfiguration?.length > 0 &&
                                                                            props.wifiConfiguration.map((conf, ind) => {
                                                                                return (
                                                                                    <Col sm={3} key={ind}>
                                                                                        <Card className="card-height-100 shadow-card cursor-pointer rounded-2 mt-3">
                                                                                            <CardHeader className="d-flex aign-items-center p-0">
                                                                                                <img
                                                                                                    alt=""
                                                                                                    src={wifiConfigImage}
                                                                                                    height="100%"
                                                                                                    width="100%"
                                                                                                    className="rounded-2"
                                                                                                />
                                                                                            </CardHeader>
                                                                                            <CardBody className="pb-0">
                                                                                                <div className="d-flex position-relative">
                                                                                                    <Label className="fw-medium  text-muted">
                                                                                                        Wi-Fi Name :{' '}
                                                                                                    </Label>
                                                                                                    <p className="m-0 ms-1 p-0">
                                                                                                        {' ' + conf?.wifiName
                                                                                                            ? conf?.wifiName
                                                                                                            : '–'}
                                                                                                    </p>
                                                                                                </div>
                                                                                                <div className="d-flex position-relative mt-2">
                                                                                                    <Label className="fw-medium  text-muted">
                                                                                                        SSID :{' '}
                                                                                                    </Label>
                                                                                                    <p className="m-0 ms-1 p-0">
                                                                                                        {' ' + conf?.ssid
                                                                                                            ? conf?.ssid
                                                                                                            : '–'}
                                                                                                    </p>
                                                                                                </div>
                                                                                                <div className="d-flex position-relative mt-2">
                                                                                                    <Label className="fw-medium  text-muted">
                                                                                                        Security :{' '}
                                                                                                    </Label>
                                                                                                    <p className="m-0 ms-1 p-0">
                                                                                                        {' ' +
                                                                                                            (conf?.security?.value
                                                                                                                ? conf?.security?.value
                                                                                                                : '–')}
                                                                                                    </p>
                                                                                                </div>
                                                                                            </CardBody>
                                                                                            {props.formType !== 'view' &&
                                                                                                props.formType !== 'viewChecker' && (
                                                                                                    <CardFooter className="py-2 px-4">
                                                                                                        <div className="d-flex align-items-center justify-content-end position-relative">
                                                                                                            <i
                                                                                                                className="ri-delete-bin-7-fill link-danger me-2 fs-18 cursor-pointer"
                                                                                                                onClick={() =>
                                                                                                                    props.handleRemoveWifiConfig(
                                                                                                                        conf
                                                                                                                    )
                                                                                                                }
                                                                                                            />
                                                                                                            <i
                                                                                                                className="ri-pencil-fill link-primary cursor-pointer fs-18"
                                                                                                                onClick={() =>
                                                                                                                    props.handleEditWifiConfig(
                                                                                                                        conf
                                                                                                                    )
                                                                                                                }
                                                                                                            />
                                                                                                        </div>
                                                                                                    </CardFooter>
                                                                                                )}
                                                                                        </Card>
                                                                                    </Col>
                                                                                );
                                                                            })}
                                                                    </Row>
                                                                )}
                                                                {props.addWifiConfig && (
                                                                    <Row className="g-3 p-0 m-0 align-items-center">
                                                                        <InputComponent
                                                                            field={{
                                                                                label: 'Wi-Fi Name',
                                                                                value: 'wifiName',
                                                                                type: 'text',
                                                                                helpText:
                                                                                    'The name of Wi-Fi to which the device must be configured',
                                                                                exclude: true
                                                                            }}
                                                                            handleKeyDown={props.handleKeyDown}
                                                                            index={'wifiName'}
                                                                            validation={props.validation}
                                                                        />
                                                                        <InputComponent
                                                                            field={{
                                                                                label: 'Service Set Identifier (SSID)',
                                                                                value: 'ssid',
                                                                                type: 'text',
                                                                                helpText:
                                                                                    'The SSID of Wi-Fi to which the device must be configured',
                                                                                exclude: true
                                                                            }}
                                                                            handleKeyDown={props.handleKeyDown}
                                                                            index={'ssid'}
                                                                            validation={props.validation}
                                                                        />
                                                                        {selectComponent(
                                                                            {
                                                                                label: 'Security',
                                                                                value: 'security',
                                                                                helpText:
                                                                                    'Security protocols used in Wi-Fi network to secure data transmissions',
                                                                                options: props.securityOptions
                                                                            },
                                                                            'security',
                                                                            props.validation
                                                                        )}
                                                                        {props.validation?.values?.security?.value &&
                                                                            props.validation?.values?.security?.value !== 'None' && (
                                                                                <InputComponent
                                                                                    field={{
                                                                                        label: 'Passphrase',
                                                                                        value: 'passphrase',
                                                                                        helpText: 'Passphrase',
                                                                                        type: 'text',
                                                                                        exclude: true
                                                                                    }}
                                                                                    handleKeyDown={props.handleKeyDown}
                                                                                    index="passphrase"
                                                                                    validation={props.validation}
                                                                                />
                                                                            )}
                                                                        {toggleButton(
                                                                            {
                                                                                label: 'Hidden SSID',
                                                                                value: 'HiddenSSID',
                                                                                helpText:
                                                                                    'Controls if SSID can be displayed on the device or not'
                                                                            },
                                                                            'hiddenSSID',
                                                                            props.validation
                                                                        )}
                                                                        <Col xs={6} md={6} sm={6} lg={5} xl={5}></Col>
                                                                        <Col xs={6} md={6} sm={6} lg={5} xl={5} className="mt-4">
                                                                            <div className="d-flex align-items-center justify-content-end gap-2">
                                                                                <Button
                                                                                    type="button"
                                                                                    className="btn btn-light"
                                                                                    onClick={props.handleCancelWifiConfig}
                                                                                >
                                                                                    Cancel
                                                                                </Button>
                                                                                <Button
                                                                                    size="md"
                                                                                    type="button"
                                                                                    color="primary"
                                                                                    disabled={
                                                                                        !props.validation?.values?.wifiName ||
                                                                                        props.validation?.values?.wifiName === '' ||
                                                                                        !props.validation?.values?.ssid ||
                                                                                        props.validation?.values?.ssid === '' ||
                                                                                        !props.validation?.values?.security?.value ||
                                                                                        props.validation?.values?.security?.value === '' ||
                                                                                        (props.validation?.values?.security?.value ===
                                                                                            'WPA-PSK' ||
                                                                                        props.validation?.values?.security?.value ===
                                                                                            'WEP-PSK'
                                                                                            ? !props.validation?.values?.passphrase ||
                                                                                              props.validation?.values?.passphrase === '' ||
                                                                                              props.validation?.errors?.passphrase
                                                                                            : false)
                                                                                    }
                                                                                    onClick={
                                                                                        props.wifiMode === 'edit'
                                                                                            ? props.handleUpdateWifiConfig
                                                                                            : props.handleAddWifiConfig
                                                                                    }
                                                                                >
                                                                                    {props.wifiMode === 'edit' ? 'Update' : 'Add'}
                                                                                </Button>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                )}
                                                            </Row>
                                                        </>
                                                    ) : (
                                                        <React.Fragment>
                                                            <TabsComponent
                                                                tabsList={props.tabsList}
                                                                defaultTabID={'apps'}
                                                                mode={props.formType}
                                                                toggleTab={(tab) => props.handleSelectedTab(tab)}
                                                            />
                                                        </React.Fragment>
                                                    )}
                                                </Row>
                                            </Col>
                                        )}
                                    </Row>
                                </CardBody>
                                <CardFooter>
                                    {props.selected && props.selected !== '' && !props.code && (
                                        <Row className="p-0 m-0">
                                            <Col>
                                                {props.tenant?.ENABLE_WORKFLOW ? (
                                                    <div className="gap-2 d-flex justify-content-end">
                                                        <button
                                                            type="reset"
                                                            className="btn btn-light"
                                                            onClick={() => history.push('/aepolicies')}
                                                        >
                                                            Cancel
                                                        </button>
                                                        {(props.selected && props.selected.label === 'Applications'
                                                            ? !props.showConfig
                                                            : props.selected && props.selected.label === 'Launcher Settings'
                                                            ? !props.showKioskAppConfig
                                                            : true) &&
                                                            props.formTypeAndId['formType'] !== 'view' &&
                                                            props.formType !== 'viewChecker' &&
                                                            (props.formTypeAndId['formType'] === 'takeAction' ? (
                                                                <>
                                                                    <button
                                                                        className="btn btn-danger"
                                                                        id="reject-btn"
                                                                        onClick={() => props.handleReject()}
                                                                    >
                                                                        Reject
                                                                    </button>
                                                                    <button
                                                                        className="btn btn-success"
                                                                        id="approve-btn"
                                                                        onClick={() => props.handleSubmit('APPROVED')}
                                                                    >
                                                                        Approve
                                                                    </button>
                                                                </>
                                                            ) : (
                                                                <button
                                                                    className="btn btn-success"
                                                                    id="add-btn"
                                                                    disabled={props.checkSaveDisable()}
                                                                    onClick={() => props.handleSubmit('REVIEW')}
                                                                >
                                                                    Send for Review
                                                                </button>
                                                            ))}
                                                        {((props.selected && props.selected.label === 'Applications' && props.showConfig) ||
                                                            (props.selected &&
                                                                props.selected.label === 'Launcher Settings' &&
                                                                props.showKioskAppConfig)) && (
                                                            <button
                                                                type="button"
                                                                className="btn btn-secondary"
                                                                onClick={props.handleGoBack}
                                                            >
                                                                Back
                                                            </button>
                                                        )}
                                                    </div>
                                                ) : (
                                                    <div className="gap-2 d-flex justify-content-end">
                                                        <button
                                                            type="reset"
                                                            className="btn btn-light"
                                                            onClick={() => history.push('/aepolicies')}
                                                        >
                                                            Cancel
                                                        </button>
                                                        {(props.selected && props.selected.label === 'Applications'
                                                            ? !props.showConfig
                                                            : props.selected && props.selected.label === 'Launcher Settings'
                                                            ? !props.showKioskAppConfig
                                                            : true) &&
                                                            props.formType !== 'view' && (
                                                                <button
                                                                    className="btn btn-success"
                                                                    id="add-btn"
                                                                    disabled={props.checkSaveDisable()}
                                                                    onClick={() => props.handleSubmit()}
                                                                >
                                                                    {props.formType === 'edit' ? 'Update' : 'Save'}
                                                                </button>
                                                            )}
                                                        {((props.selected && props.selected.label === 'Applications' && props.showConfig) ||
                                                            (props.selected &&
                                                                props.selected.label === 'Launcher Settings' &&
                                                                props.showKioskAppConfig)) && (
                                                            <button
                                                                type="button"
                                                                className="btn btn-secondary"
                                                                onClick={props.handleGoBack}
                                                            >
                                                                Back
                                                            </button>
                                                        )}
                                                    </div>
                                                )}
                                            </Col>
                                        </Row>
                                    )}
                                </CardFooter>
                            </Card>
                        </div>
                    )}
                    {props.showKioskConfirmation && (
                        <DeleteModal
                            hideIcon={true}
                            hideDeleteMessage={true}
                            message={message()}
                            show={props.showKioskConfirmation}
                            confirmText="Ok"
                            cancelText="Cancel"
                            hideDeleteIcon={true}
                            onDeleteClick={() => props.handleKioskAppConfimation(props.kioskDetails?.kiosk, props.kioskDetails?.index)}
                            onCloseClick={() => {
                                props.setKioskDetails('');
                                props.setShowKioskConfirmation(false);
                            }}
                            // deleteMessage={`Are you sure you want to remove this device group props.code -${selectedRow?.name} ?`}
                        />
                    )}
                    <OffcanvasModal
                        direction="end"
                        toggle={props.togglePackage}
                        open={props.showPackageConfig}
                        handleCloseClick={props.togglePackage}
                        OffcanvasModalID="packageConfig"
                        handleOffcanvasBody={handlePackageConfigBody}
                        modalClassName={'w-50'}
                        handleSaveClick={props.handleAddPackConfigs}
                        saveText="Save"
                        modalHeaderClass={props.packageType === 'allowed' ? 'bg-success' : 'bg-danger'}
                        offcanvasHeader={props.packageModalHeader}
                    />
                    <DeleteModal
                        hideIcon={true}
                        hideDeleteMessage={true}
                        message={alertMessage()}
                        show={props.alertModal}
                        confirmText="Yes"
                        cancelText="No"
                        hideDeleteIcon={true}
                        onDeleteClick={props.handleChangeValue}
                        onCloseClick={() => {
                            props.setSelectedToggleDetails('');
                            props.setAlertModal(false);
                        }}
                        // deleteMessage={`Are you sure you want to remove this device group props.code -${selectedRow?.name} ?`}
                    />
                    {props.showConfirmation && (
                        <CommonModal
                            size={'md'}
                            cancelText={'No'}
                            show={props.showConfirmation}
                            saveText="Yes"
                            onCloseClick={() => props.setShowConfirmation(false)}
                            handleClick={() => props.handleConfirmation()}
                            handleModalBody={() => handleConfirmationModalBody()}
                        />
                    )}
                    <CommonModal
                        size={'md'}
                        cancelText={'No'}
                        show={props.showRejectModal}
                        saveText="Yes"
                        modalheader={'Reason for Rejection'}
                        disabled={props.remarks === ''}
                        onCloseClick={() => {
                            props.setShowRejectModal(false);
                            props.setRemarks('');
                        }}
                        handleClick={() => props.handleSubmit('REJECTED')}
                        handleModalBody={() => handleRemarksModalBody()}
                    />
                </Container>
            </div>
        </React.Fragment>
    );
};
