/* eslint-disable multiline-comment-style */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Alert, Card, CardBody, Col, Container, Label, Row, Spinner } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import CommonModal from '../../../Components/Common/CommonModal';
import DeleteModal from '../../../Components/Common/DeleteModal';
import Loader from '../../../Components/Common/Loader';
import TableContainer from '../../../Components/Common/TableContainer';
import { TenantConfig, Tooltip, convertUTCtoIST } from '../../../Components/Common/Util';
import { iosPoliciesArr } from '../../../Components/constants/commons';
import { priviliges } from '../../../Components/constants/constants';
import ClearData from '../../../assets/images/DataLoss.png';
import polClone from '../../../assets/images/clonePolicy.png';
import syncDevice from '../../../assets/images/sync.png';
import toastMessages from '../../../common/messages/toastMessages';
import { useEnv } from '../../../envContext';
import { APIClient } from '../../../helpers/api_helper';
import * as domains from '../../../helpers/domain_helper';
import * as url from '../../../helpers/url_helper';
import { policyTableSchema } from '../TableSchema';

const IOSPolicies = () => {
    document.title = 'iOS Policy';
    const api = new APIClient();
    const urlconf = useEnv();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [policies, setPolicies] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [deleteModal, setDeleteModal] = useState(false);
    const [policiesTableSchema, setPoliciesTableSchema] = useState([...policyTableSchema]);
    const [searchParams, setSearchParams] = useState({ page: 1, size: 10 });
    const [selectedRow, setSelectedRow] = useState('');
    const [policyType, setPolicyType] = useState('');
    const tenantConfig = TenantConfig();
    const [policyCards, setPolicyCards] = useState([]);
    const [showCloneModal, setShowCloneModal] = useState(false);
    const [showPolicyModal, setShowPolicyModal] = useState(false);
    const [qrCodeString, setQRCodeString] = useState('');
    const [showQRModal, setShowQRModal] = useState(false);
    const [searchVal, setSearchVal] = useState('');
    const [policiesBackup, setPoliciesBackup] = useState([]);
    const emptyModal = { message: '', image: '', confirmMessage: '', type: '' };
    const [modalDetails, setModalDetails] = useState(emptyModal);
    const [showLinkModal, setShowLinkModal] = useState(false);
    const [enrollmentLink, setEnrollmentLink] = useState('');

    useEffect(() => {
        getPolicies(searchParams);
    }, []);

    const getPolicies = (params) => {
        setLoading(true);
        api.get(url.POLICIES, params, domains.IOS_V1)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.data) {
                    resp.data.forEach((data) => {
                        data.disableQR = data.platform === 'macos';
                        data.platform = data.platform?.replaceAll('os', 'OS') || '—';
                        data.type = data.policytype === 'MA' ? 'Multi App' : data.policytype === 'SA' ? 'Single App' : '—';
                        data.doNotDelete = data.code === 'DEFAULT';
                        data.deleteTooltip = 'You cannot delete the DEFAULT policy.';
                        data.modifiedDate = data.modifieddate ? convertUTCtoIST(data.modifieddate) : '—';
                    });
                    setPolicies(resp.data);
                    setPoliciesBackup(resp.data);
                    setTotalRecords(resp.totalRecords);
                }
                setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    const handleDelete = (row) => {
        setModalDetails({
            message: 'Are you sure you want to delete this policy',
            image: ClearData,
            confirmMessage: 'Yes, Delete it!',
            type: 'delete'
        });
        setSelectedRow(row.original);
        setDeleteModal(true);
    };

    const handleGlobalSearch = (val) => {
        let filteredPolicies = [...policiesBackup];
        if (val?.length > 0)
            filteredPolicies = policiesBackup.filter(
                (policy) =>
                    policy?.code?.toLowerCase()?.includes(val?.toLowerCase()) || policy?.name?.toLowerCase()?.includes(val?.toLowerCase())
            );
        setSearchVal(val);
        setTotalRecords(filteredPolicies.length);
        setPolicies(filteredPolicies);
    };

    const handleDeleteConfirmation = () => {
        setDeleteModal(false);
        setSelectedRow('');
        api.delete(url.POLICIES + '/' + selectedRow._id, '', domains.IOS_V1)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status?.toLowerCase() === 'success') {
                    toast.success(toastMessages.policyDeleted);
                    setSearchVal('');
                    getPolicies(searchParams);
                }
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    const message = () => {
        return (
            <div className="mt-3">
                <div className="mb-2">{modalDetails.message}</div>
                <div className="mb-2 d-flex align-items-center justify-content-center">
                    <img src={modalDetails.image} alt="deleteGroup" width={60} height={60} />
                </div>
                <div>
                    Policy Name: <span className="fw-semibold">{selectedRow.name}</span>
                </div>
            </div>
        );
    };

    const handlePageChange = (page) => {
        let params = { ...searchParams, page: page, size: 10 };
        setSearchParams(params);
        getPolicies(params);
    };

    const handleAddNew = () => {
        setPolicyType('');
        let policyArray = iosPoliciesArr;
        let configArr = [];
        policyArray.forEach((ele) => {
            if (tenantConfig?.IOS_POLICY_TYPES?.join()?.includes(ele.id)) configArr.push(ele);
        });
        setPolicyCards(configArr);
        setShowPolicyModal(true);
    };

    const handlePolicyTypeClick = (item) => {
        setPolicyType(item);
        if (item.cards?.length > 0) {
            let configArr = [];
            item.cards.forEach((ele) => {
                if (tenantConfig?.IOS_DD_POLICY_TYPES?.join()?.includes(ele.id)) configArr.push(ele);
            });
            setPolicyCards(configArr);
        }
    };

    const handleBackToMainMenu = () => {
        let PolicyArray = iosPoliciesArr;
        let configArr = [];
        PolicyArray.forEach((ele) => {
            tenantConfig?.IOS_POLICY_TYPES?.forEach((item) => {
                if (ele.id?.toUpperCase() === item || ele.id === item) {
                    configArr.push(ele);
                }
            });
        });
        setPolicyCards(configArr);
        setPolicyType('');
    };

    const handlePolicyModalBody = () => {
        return (
            <div>
                {(policyType.cards?.length > 0 || ['ios', 'SA', 'MA'].includes(policyType.id)) && (
                    <div className="d-flex align-items-center cursor-pointer link-primary mb-2" onClick={handleBackToMainMenu}>
                        <i className="ri-arrow-left-line me-2" />
                        Back
                    </div>
                )}
                <Row className="d-flex align-items-center justify-content-evenly">
                    {policyCards?.length > 0 &&
                        policyCards.map((ele, index) => {
                            return (
                                <Card
                                    key={index}
                                    className={`d-flex align-items-center justify-content-center mb-0 p-0 border shadow width-250 height-250 cursor-pointer ${
                                        ele.id === policyType.id ? 'border-2 border-primary' : ''
                                    }`}
                                    onClick={() => handlePolicyTypeClick(ele)}
                                >
                                    <CardBody className="d-flex flex-column align-items-center justify-content-center position-relative">
                                        {ele.id === policyType.id && (
                                            <i className="position-absolute end-10 top-5 link-primary fs-18 ri-check-double-fill"></i>
                                        )}
                                        <div>
                                            <img src={ele.img} alt="" height={60} width={60} />
                                        </div>
                                        <p className="fw-semibold text-info text-truncate mt-3 mb-3">{ele.label}</p>
                                        <span className="text-wrap text-center fw-normal fs-12">{ele.desc}</span>
                                    </CardBody>
                                </Card>
                            );
                        })}
                </Row>
            </div>
        );
    };

    const handleQR = (row) => {
        setLoading(true);
        setSelectedRow(row);
        api.get(url.POLICIES + url.QR_CODE + '/' + row.original?.code, '', domains.IOS_V1)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    toast.success(toastMessages.qrGenerated);
                    setQRCodeString(resp.data);
                    setShowQRModal(true);
                }
                setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    const handleCloneConfirm = () => {
        setLoading(true);
        setShowCloneModal(false);
        api.create(url.POLICIES + '/' + selectedRow.original._id + '/clone', '', false, domains.IOS_V1)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    toast.success(toastMessages.policyCloned);
                    getPolicies(searchParams);
                } else setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    const handleClone = (row) => {
        setSelectedRow(row);
        setShowCloneModal(true);
    };

    const handleCloneModalBody = () => {
        return (
            <div className="mt-3">
                <div className="mb-4">Are you sure you want to clone this Policy?</div>
                <div className="mb-4 d-flex align-items-center justify-content-center">
                    <img src={polClone} alt="clonepolicy" width={60} height={60} />
                </div>
                <div>
                    Policy Name: <span className="fw-semibold">{selectedRow.original?.name}</span>
                </div>
            </div>
        );
    };

    const handleQRModalBody = () => {
        return (
            <Row className="d-flex align-items-center justify-content-center">
                <div className="text-center">
                    <h5 className="text-primary">QR Code</h5>
                    <Alert className="alert-borderless alert-warning text-center mt-3 mb-0" role="alert">
                        {qrCodeString ? 'Please scan for the device enrollment' : 'QR code is being generated...'}
                    </Alert>
                </div>

                <div className={`d-flex align-items-center justify-content-center flex-wrap ${qrCodeString ? '' : 'mt-3'}`}>
                    {qrCodeString ? (
                        <img alt="" className="w-75" src={`data:image/png;base64,${qrCodeString}`} />
                    ) : (
                        <Spinner color="primary" className="spinner-style" />
                    )}
                </div>
            </Row>
        );
    };

    const copyToClipboard = async (e, val, toastMsg) => {
        navigator.clipboard.writeText(val).then(() => {
            toast.success(toastMsg);
        });
    };

    const handleLinkModalBody = () => {
        return (
            <Row className="d-flex align-items-center justify-content-center">
                <div className="text-center">
                    <h5 className="text-primary">Enrollment Link</h5>
                </div>
                <Alert className="alert-borderless alert-warning text-center mb-0" role="alert">
                    For the best enrollment experience, use Safari on an iPad, iPhone, or Mac. During enrollment, a *.mobileconfig file is
                    downloaded to your device. Safari can handle this file type automatically, while other browsers may not.
                </Alert>

                <Alert className="alert-borderless alert-primary text-center mt-3 mb-0" role="alert">
                    <Row>
                        <Col sm={11} className="fw-medium text-truncate" id={'enrollmentLink'}>
                            {enrollmentLink}
                            {Tooltip('enrollmentLink', enrollmentLink)}
                        </Col>
                        <Col sm={1}>
                            <i
                                id={'copy-link'}
                                onClick={(e) => copyToClipboard(e, enrollmentLink, toastMessages.linkCopied)}
                                className="ri-file-copy-line ms-2 fs-13 cursor-pointer link-primary me-2"
                            ></i>
                            {Tooltip('copy-link', 'Copy')}
                        </Col>
                    </Row>
                </Alert>
            </Row>
        );
    };

    const handleSync = (row) => {
        setModalDetails({
            message: 'Are you sure you want to force sync this policy?',
            image: syncDevice,
            confirmMessage: 'Yes, Sync it!',
            type: 'sync'
        });
        setSelectedRow(row.original);
        setDeleteModal(true);
    };

    const qrHeader = () => {
        return (
            <div className="d-flex align-items-center justify-content-center">
                <Label className="p-0 m-0 mt-3 fw-semibold">Policy :</Label>
                <p className="p-0 m-0 mt-3 ms-2 fw-normal">{selectedRow?.original?.name}</p>
            </div>
        );
    };

    const handleAction = () => {
        if (modalDetails.type === 'delete') handleDeleteConfirmation();
        else if (modalDetails.type === 'sync') {
            setLoading(true);
            setDeleteModal(false);
            api.get(url.POLICY_SYNC + '/' + selectedRow?.code, '', domains.IOS_V1)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp.status === 'success') {
                        toast.success(toastMessages.policySyncReq);
                        getPolicies();
                    }
                    setLoading(false);
                })
                .catch((err) => setLoading(false));
        }
        setModalDetails(emptyModal);
    };

    const handleLink = (row) => {
        setLoading(true);
        setSelectedRow(row);
        api.get(url.POLICIES + '/url/' + row.original?.code, '', domains.IOS_V1)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                toast.success(toastMessages.linkGenerated);
                setEnrollmentLink(resp?.data);
                setShowLinkModal(true);
                setLoading(false);
            })
            .catch((_err) => setLoading(false));
    };

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className={`page-content h-100 ${loading ? 'postion-relative mask' : ''}`}>
                <Container fluid>
                    <BreadCrumb pageTitle="iOS Policies" history={history} homeLink="Dashboard" />
                    <TableContainer
                        loading={loading}
                        tableHeader={'All Policies'}
                        isGlobalFilter={true}
                        addButton={true}
                        largeSearchFilter={true}
                        SearchFilterWidth={'width-280'}
                        searchFilter2={true}
                        searchVal={searchVal}
                        searchPlaceHolder={'Policy Code or Name'}
                        columns={policiesTableSchema}
                        handleGlobalSearch={handleGlobalSearch}
                        handleDelete={handleDelete}
                        onPageChange={handlePageChange}
                        handleQR={handleQR}
                        handleSync={handleSync}
                        handleClone={handleClone}
                        handleLink={handleLink}
                        handleEdit={(row) =>
                            history.push(
                                `/iospolicies/edit/${row.original._id}/${row.original.policytype}-${row.original.platform?.toLowerCase()}`
                            )
                        }
                        handleAddNew={handleAddNew}
                        handleClickView={(row) =>
                            history.push(
                                `/iospolicies/view/${row.original._id}/${row.original.policytype}-${row.original.platform?.toLowerCase()}`
                            )
                        }
                        totalRecords={totalRecords}
                        data={policies}
                        editor={priviliges.IOSPOLICY_EDITOR}
                        reader={priviliges.IOSPOLICY_READER}
                        className="custom-header-css"
                        divClass="table-responsive table-card"
                        tableClass="table table-nowrap table-border table-centered align-middle"
                        theadClass="bg-light text-muted"
                    />
                    <DeleteModal
                        hideIcon={true}
                        show={deleteModal}
                        confirmText={modalDetails.confirmMessage}
                        hideDeleteMessage={true}
                        message={message()}
                        onDeleteClick={handleAction}
                        onCloseClick={() => setDeleteModal(false)}
                    />
                    <CommonModal
                        customWidth={'max-width-per-50'}
                        disabled={!policyType?.path || policyType?.path === ''}
                        cancelText={'Cancel'}
                        saveText={'Proceed'}
                        show={showPolicyModal}
                        modalheader={'Create New Policy'}
                        onCloseClick={() => {
                            setShowPolicyModal(false);
                            setPolicyType('');
                        }}
                        handleClick={() => {
                            history.push('/iospolicies/add/' + policyType.path);
                            setShowPolicyModal(false);
                        }}
                        handleModalBody={() => handlePolicyModalBody()}
                    />
                    <DeleteModal
                        hideIcon={true}
                        show={showCloneModal}
                        hideDeleteMessage={true}
                        message={handleCloneModalBody()}
                        onDeleteClick={handleCloneConfirm}
                        confirmText="Yes, Clone it"
                        hideDeleteIcon={true}
                        onCloseClick={() => {
                            setShowCloneModal(false);
                            setSelectedRow('');
                        }}
                    />
                    <CommonModal
                        size={'md'}
                        show={showQRModal}
                        disabled={false}
                        hideSaveButton={true}
                        modalheader={qrHeader()}
                        onCloseClick={() => {
                            setShowQRModal(false);
                            setSelectedRow('');
                            setQRCodeString('');
                        }}
                        handleClick={() => {
                            setShowQRModal(false);
                            setSelectedRow('');
                            setQRCodeString('');
                        }}
                        handleModalBody={() => handleQRModalBody()}
                    />
                    <CommonModal
                        size={'md'}
                        show={showLinkModal}
                        disabled={false}
                        hideSaveButton={true}
                        modalheader={qrHeader()}
                        onCloseClick={() => {
                            setShowLinkModal(false);
                            setSelectedRow('');
                            setEnrollmentLink('');
                        }}
                        handleClick={() => {
                            setShowLinkModal(false);
                            setSelectedRow('');
                            setEnrollmentLink('');
                        }}
                        handleModalBody={() => handleLinkModalBody()}
                    />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default IOSPolicies;
