/* eslint-disable quotes */
const toastMessages = {
    // devices
    factoryResetWithMemorWipe: 'Device factory reset and sd card wipe successful.',
    notesUpdated: 'Notes updated successfully.',
    notesAdded: 'Notes added successfully.',
    providePassword: 'Please Provide Password',
    provideNotes: 'Please provide notes.',
    providePolicy: 'Please provide policy.',
    provideGroup: 'Please provide group.',
    devicesMaxCount: "Devices count shouldn't be greater than 300k.",
    filterDevicesMaxCount: "Devices count shouldn't be greater than 10k.",
    refreshTime: 'You can refresh once every 5 seconds.',
    refreshTime30: 'You can refresh once every 30 seconds.',
    deviceMaxPageReached: 'You have reached the max page limit you can use the filter criteria to get the required records.',
    maxDeviceCount: 'Maximum devices selection reached.',
    screamRequested: 'Request raised for device scream.',
    serialCopied: 'Serial number copied successfully.',
    linkCopied: 'Enrollment Link copied successfully.',
    deviceIDCopied: 'Device ID copied successfully.',
    imeiCopied: 'IMEI copied successfully.',
    requestToSetVolume: 'Request raised to set volume.',
    requestToDeprovision: 'Request raised to deprovision device.',
    wipeUser: 'Request raised to wipe users.',
    screenshot: 'Screenshot taken successfully.',
    scanStartRequestSent: 'Request raised to start scan device.',
    scanStopRequestSent: 'Request raised to stop scan device.',
    noScanFoundToStop: 'No scan found to stop.',
    simIdLength: 'SIM ID must be a number and should be contain 22 digits.',
    deviceSync: 'Request raised to sync device policy.',
    sessionIDCopied: 'Session ID copied successfully.',
    lostModeStart: 'Request raised to start lost mode on device.',
    lostModeStop: 'Request raised to stop lost mode on device.',

    // TV actions
    cameraEnable: 'Request raised to enable camera.',
    cameraDisable: 'Request raised to disable camera.',
    lockDevice: 'Request raised to lock device.',
    rebootDevice: 'Request raised to reboot device.',
    refreshDevice: 'Request raised to refresh device.',

    // device details
    devicePolicyUpdate: 'Policy update will reflect once the device gets refreshed.',
    deviceStatusUpdate: 'Device status update will reflect once the device gets refreshed.',
    deviceGroupUpdate: 'Device Group update will reflect once the device gets refreshed.',
    cacheCleared: 'Request raised to clear cache.',
    screamSwitchedOff: 'Request raised to switch off screamer.',
    screamSwitchedOn: 'Request raised to switch on screamer.',
    launcherPassword: 'Request raised to change launcher password.',
    devicePassword: 'Request raised to change device password.',
    factoryReset: 'Request raised to factory reset device.',

    // device groups
    deviceGroupUpdated: 'Device group updated successfully.',
    deviceGroupCreated: 'Device group created successfully.',
    deviceGroupDeleted: 'Device group deleted successfully.',
    deviceGroupMoved: 'Device group moved successfully.',
    devicesMoved: 'Devices moved successfully.',
    nameLength: 'Name should have minimum 3 characters.',

    // device settings
    settingsUpdated: 'Device settings updated successfully.',
    settingsCreated: 'Device settings created successfully.',
    addressNotFound: 'Unable to get address.',
    locationNotFound: 'Unable to get location.',
    existingBookmark: 'Bookmark already exists.',

    // device policy
    atleastOneWiFiConfig: 'Atleast one wifi configuration should be added.',
    selectedInApps: 'Already selected in the applications.',
    selectCheckbox: 'Please check the box to add or remove the application',
    selectedInKiosk: 'Already selected in the kiosk.',
    existingSSID: 'SSID already exists.',
    packageNameCopied: 'Package name copied successfully.',
    policyDeleted: 'Policy deleted successfully.',
    policyStatusUpdated: 'Policy status updated successfully.',
    policyCloned: 'Policy cloned successfully.',
    appUsedInPolicy: 'Cannot remove Application as it is already used in the policy.',
    packageExistsInAllowed: 'Package name already exists in allowed list.',
    packageExistsInBlocked: 'Package name already exists in blocked list.',
    packageExistsInEnable: 'Package name already exists in Enable list.',
    packageExistsInDisable: 'Package name already exists in Disable list.',
    frpMailAlreadyExists: 'FRP mail already exists.',
    inValidEmail: 'Enter a valid Email',
    mailAlreadyExist: 'Email Aready Exists',
    domainAlreadyExists: 'Domain alread exists.',
    kioskAppNotFound: 'Please select an app as custom launcher.',
    invalidPassword: 'Invalid Password.',

    // enrollment
    qrGenerated: 'QR code generated successfully.',
    linkGenerated: 'Link generated successfully.',
    qrGeneratedWithSignIn: 'QR code for the sign-in flow was generated successfully.',
    qrGeneratedWithoutSignIn: 'QR code for the no sign-in flow was generated successfully.',
    tokenCopied: 'Token copied successfully.',
    maxDaysWorkoffLimit: 'Maximum days with work off should be atleast 3 days.',

    // device bulk request
    deviceNotUploaded: 'Device upload failed.',
    deviceUploaded: 'Devices uploaded successfully.',

    // playstore
    appApproved: 'Application approved successfully.',
    appDeleted: 'Application deleted successfully.',
    filesSizeGT300: 'Files size exceeded 300MB.',

    // login
    otpSent: 'OTP sent to your registered email address.',

    // enterprise
    enterpriseCreated: 'Enterprise account deleted successfully.',

    // announcements
    announcementDeleted: 'Message deleted successfully.',
    announcementUpdated: 'Message updated successfully.',
    announcementCreated: 'Message created successfully.',
    announcementRecalled: 'Message recalled successfully.',
    minLength4: 'Message title should be greater than 3 characters.',
    timeValidation: 'Scheduled time should be lesser than current time.',
    deviceAlreadyExists: 'Device already exists in the list.',

    // User Settings
    userSettingCreated: 'User setting created successfuly.',
    userSettingUpdated: 'User setting updated successfully.',
    userSettingDeleted: 'User setting deleted successfully.',

    // assets
    assetDeleted: 'Asset deleted successfully.',
    assetUpdated: 'Asset updated successfully.',
    assetCreated: 'Asset created successfully.',

    // Roles
    roleUpdate: 'Role updated successfully.',
    roleCreate: 'Role creates successfuly.',
    roleDelete: 'Role deleted successfully.',

    // content management
    fileDeleted: 'File deleted successfully.',
    fileShared: 'File successfully shared among the groups.',
    fileSizeLimit: 'File size limit exceeded.',
    fileNameLimit: 'File name limit exceeded.',
    fileUploaded: 'File uploaded successfully.',
    filesSizeGT30: 'Files size exceeded 30MB.',
    wallpaperGT5: 'Wallpaper size exceeded 5MB.',

    // tenant master
    offlineTimeRestriction: 'Offline time should be greater than online time.',
    tenantUpdated: 'Tenant updated successfully.',
    tenantCreated: 'Tenant created successfully.',

    // users
    userUpdated: 'User updated successfully.',
    userCreated: 'User created successfully.',
    userDeactivated: 'User deactivated successfully.',
    userActivated: 'User activated successfully.',
    emailCopied: 'Email copied successfully.',

    // common
    fillAllMandatoryFields: 'Please fill all the mandatory fields.',
    passwordRequired: 'Password is mandatory.',
    passwordRequirements:
        'Password should contain atleast one capital letter, one small letter, one digit, one special character and atleast 8 character length.',
    invalidURL: 'Invalid URL.',
    urlRestriction: 'URL should not contain spaces.',

    // Applications
    reinstallationRequested: 'Request raised to update the application',
    NoDetailsFound: 'No details found.',
    appAdded: 'Application added successfully.',
    invalidPackageName: 'Invalid Package Name.',

    // Login
    passwordDidNotMatch: 'Passwords did not match.',

    // Configs
    configDeleted: 'Config deleted successfully.',
    configCreated: 'Config created successfully.',
    configUpdated: 'Config updated successfully.',

    firewallClone: 'Firewall cloned successfully.',
    firewallCreated: 'Firewall created successfully.',
    firewallUpdated: 'Firewall updated successfully.',
    geofenceClone: 'Geo-Fence cloned successfully.',
    geofenceCreated: 'Geo-Fence created successfully.',
    geofenceUpdated: 'Geo-Fence updated successfully.',
    simsettingUpdated: 'Sim setting updated successfully.',
    simsettingsCreated: 'Sim Setting created successfully.',
    simSettingsCloned: 'Sim Setting cloned successfully.',
    packageConfigCreated: 'Package config created successfully.',
    wififenceClone: 'Wi-Fi Fence cloned successfully.',
    wififenceCreated: 'Wi-Fi Fence created successfully.',
    wififenceUpdated: 'Wi-Fi Fence updated successfully.',
    packageConfigUpdated: 'Package config updated successfully.',
    blockedListAvailable: 'It is already available in blocked list.',
    allowedListAvailable: 'It is already available in allowed list.',
    browserConfigCreate: 'Browser config created successfully.',
    browserConfigUpdate: 'Browser config updated successfully.',
    browserConfigDelete: 'Browser config deleted successfully.',
    packageConfigDelete: 'Package config deleted successfuly.',
    invalidDomain: 'Invalid domain.',
    policyFenceCreated: 'Time-Fence created successfully.',
    policyFenceUpdated: 'Time-Fence updated successfully.',
    policyFenceDeleted: 'Time-Fence deleted successfully.',
    timeSameValidation: 'Start time and end time should not be same.',
    timeDiff: 'Time difference should be atleast 30 minutes.',
    callConfigConfigUpdate: 'Call config updated succesfully.',
    callConfigConfigCreate: 'Call config created successfully.',
    callConfigConfigClone: 'Call config cloned successfully.',
    callConfigDeleted: 'Call config deleted successfully.',

    acerODMFeatureConfigUpdate: 'Acer ODM Feature config updated succesfully.',
    acerODMFeatureCreate: 'Acer ODM Feature config created successfully.',
    acerODMFeatureDeleted: 'Acer ODM Feature config deleted successfully.',

    invalidPhone: 'Invalid contact number.',
    phoneExists: 'Contact number already exists.',

    geofenceDeleted: 'Geo-fence deleted successfully.',
    simSettingDeleted: 'Sim setting deleted successfully.',
    wifiFenceDeleted: 'Wi-Fi fence deleted successfully.',
    firewallDeleted: 'Firewall deleted successfully.',
    urlExists: 'URL already exists.',
    launcherConfigDeleted: 'Launcher deleted successfully.',
    launcherConfigCreated: 'Launcher created successfully.',
    launcherConfigEdit: 'Launcher updated successfully.',
    domainValidations: 'Domain should contain dot(.) and only allows alphanumeric values and (*) and it should not end with (.)',

    invalidCSVFile: 'Incorrect CSV format.',
    invalidFileFormat: 'Incorrect file format.',
    roleNameLengthExceeded: 'Role name must be less than 20 characters in length.',

    tvDevicesUpload: 'Uploaded Tv devices successfully.',
    invalidAPK: 'File name should end with .apk.',
    invalidAppLogo: 'File name should end with .jpg or .png or .jpeg or .svg .',
    androidTvPolicyCreated: 'Android TV policy created successfully.',
    androidTvPolicyUpdated: 'Android TV policy updated successfully.',
    tvVolumeUpdate: 'Volume updated successfully.',
    powerStatusUpdate: 'Request raised to change power status',
    inputMethodUpdate: 'Request raised to update input source',

    iosPolicyCreated: 'iOS policy created successfully.',
    iosPolicyUpdated: 'iOS policy updated successfully.',
    lostModeEnabled: 'Request raised to enable lost mode on this device.',
    lostModeDisabled: 'Request raised to disable lost mode on this device.',
    lostModeSoundInitiated: 'Request raised to play lost mode sound on this device.',
    actionSuccessMsg: 'Action done successfully.',
    appInstallMsg: 'Request raised to install app on this device.',
    appRemoveMsg: 'Request raised to remove app on this device.',
    wallpaperMsg: 'Request raised to set wallpaper on this device.',
    activationLock: 'Activation Lock successful',

    androidTvAppPublished: 'Android TV app published successfully.',
    androidTVAppUpdated: 'Android TV app updated successfully.',
    cumulativeSizeGT30: 'Cumulative file size must be less than 30MB.',
    teacherDataUploaded: 'Teacher data uploaded successfully.',

    certificateUploaded: 'Certificate uploaded successfully.',
    certificateDeleted: 'Certificate deleted successfully.',
    // IDM
    accountVerified: 'Account ID verified successfully.',
    authModeCreated: 'Auth Mode created successfully.',
    authModeUpdated: 'Auth Mode updated successfully.',
    statusUpdated: 'Status updated successfully.',
    // IOS policies
    verifyCheckbox: 'Please check the box to add or remove the application',
    policySyncReq: 'Request raised to sync policy.',
    // IOS setup
    APNSuccess: 'APN Configured Successfully.',
    APNDeleted: 'APN Deleted Successfully.',
    ABMSuccess: 'ABM Configured Successfully. ',
    ABMSync: 'ABM Sync Successfully. ',
    ABMDeleted: 'ABM Deleted Successfully.',
    VPPDeleted: 'VPP Deleted Successfully.',
    deviceRetired: 'Device Retired Successfully. It will be Removed After One Minute.',
    doNotHaveAccess: 'You do not Have Access to Perform this Action Please Check if you Respective Priviliges.',
    noAuthModesConfigured: 'No Authentication Modes Configured in this Account.',
    enrollmentStepsEdited: 'Enrollment Steps Updated Successfully.',
    enrollmentStepsCreated: 'Enrollment Steps Configured Successfully.',
    vppConfigured: 'Apps & Books Configured Successfully.',
    countrySaved: 'Country Saved Successfully.',
    APNCreated: 'Apple Push Notification Service Configured Successfully.',

    // IOS
    deviceNameMsg: 'Device Name updated successfully.',

    // Enrollment Settings
    deviceAllocationCreated: 'Device Allocation Created Successfully',
    deviceAllocationUpdated: 'Device Allocation Updated Successfully',

    // Windows Policies
    windowsPolicyCreated: 'Windows policy created successfully.',
    windowsPolicyUpdated: 'Windows policy updated successfully.',
    byodSetup: 'BYOD Setup Initiated',
    syncDevice: 'Request raised to sync device.',
    eraseDevice: 'Request raised to erase device.',
    unenrollDevice: 'Request raised to unenroll device.',
    invalidTime: 'Invalid time.',
    shutdownDevice: 'Request raised to shutdown device.',
    addUser: 'Request raised to add user.',

    appProtecitonCreated: 'App protection policy created successfully.',
    appProtecitonUpdated: 'App protection policy updated successfully.',
    appProtecitonDeleted: 'App protection policy deleted successfully.',
    conditionalAccessCreated: 'Conditional access created successfully.',
    conditionalAccessUpdated: 'Conditional access updated successfully.',
    conditionalAccessDeleted: 'Conditional access deleted successfully.',
    noSufficientPriviliges: 'No sufficient priviliges to perform this action.',

    ODMFeaturesCreated: 'ODM Feature created successfully.',
    ODMFeaturesUpdated: 'ODM Feature updated successfully.',
    ODMFeaturesDeleted: 'ODM Feature deleted successfully.'
};

export default toastMessages;
