import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Col, Container, Row } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import DeleteModal from '../../../Components/Common/DeleteModal';
import Loader from '../../../Components/Common/Loader';
import { COUNTRIES } from '../../../Components/constants/Countries';
import { ENROLLMENT_STEPS_DATA } from '../../../Components/constants/constants';
import AppleBussinessImage from '../../../assets/images/apple_bussiness.png';
import ApplePushImage from '../../../assets/images/apple_push.png';
import VPPImage from '../../../assets/images/apps_books.png';
import toastMessages from '../../../common/messages/toastMessages';
import { useEnv } from '../../../envContext';
import { APIClient } from '../../../helpers/api_helper';
import * as domains from '../../../helpers/domain_helper';
import * as url from '../../../helpers/url_helper';
import AppleBusinessManager from './AppleBusinessManager';
import ApplePushNotifications from './ApplePushNotifications';
import EnrollmentSteps from './EnrollmentSteps';
import VolumePurchaseProgram from './VolumePurchaseProgram';

const Setup = () => {
    document.title = 'Apple Setup';
    let api = new APIClient();
    const urlconf = useEnv();
    let images = { apns: ApplePushImage, abm: AppleBussinessImage, vpp: VPPImage };

    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const [showDelete, setShowDelete] = useState(false);
    const [modalDetatils, setModalDetails] = useState({ message: '', header: '', id: '', type: '' });
    const [apnData, setApnData] = useState('');
    const [abmData, setAbmData] = useState([]);
    const [vppData, setVppData] = useState('');
    const [enrollmentSteps, setEnrollmentSteps] = useState('');

    useEffect(() => {
        setLoading(true);
        handlePromise();
    }, []);

    const definePath = (schema, obj) => {
        schema.path.forEach((path) => (obj = obj?.[path]));
        return obj;
    };

    const defineDropDown = async (obj, key, dataObj) => {
        obj[dataObj.value] = await dataObj.options.find((option) => option[dataObj.optionValue] === key?.[dataObj.value]);
        return obj;
    };

    const handlePromise = () => {
        let abmPromise = new Promise((resolve, reject) => {
            api.get(url.MDMSERVER, '', domains.IOS_V1)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp.status?.toLowerCase() === 'success') resolve(resp.data);
                    reject('ABM Failed');
                })
                .catch((err) => reject('ABM Failed'));
        });
        let apnPromise = new Promise((resolve, reject) => {
            api.get(url.APN_GET, '', domains.IOS_V1)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp.status?.toLowerCase() === 'success') resolve(resp.data);
                    reject('APN Failed');
                })
                .catch((err) => reject('APN Failed'));
        });
        let vppPromise = new Promise((resolve, reject) => {
            api.get(url.VPP_DATA, '', domains.IOS_V1)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp.status?.toLowerCase() === 'success') resolve(resp.data);
                    reject('VPP Failed');
                })
                .catch((err) => reject('VPP Failed'));
        });

        let enrollmentStepPromise = new Promise((resolve, reject) => {
            api.get(url.ENROLLMENT_STEPS, '', domains.IOS_V1)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp.status?.toLowerCase() === 'success') resolve(resp.data);
                    reject('Enrollment Steps Failed');
                })
                .catch((err) => reject('Enrollment Steps Failed'));
        });

        Promise.allSettled([abmPromise, apnPromise, vppPromise, enrollmentStepPromise])
            .then((result) => {
                if (result[0].status === 'fulfilled') {
                    setAbmData(result[0].value || []);
                }
                if (result[1].status === 'fulfilled') {
                    setApnData(result[1].value || '');
                }
                if (result[2].status === 'fulfilled') {
                    if (result[2].value?.region)
                        result[2].value.name = result[2].value?.region?.name
                            ? result[2].value?.region?.name
                            : result[2].value?.region
                            ? COUNTRIES?.find((country) => country.code === result[2].value?.region?.toUpperCase())?.name
                            : '';
                    if (result[2]?.value?.appautoupdate)
                        result[2].value.appAutoUpdate = result[2]?.value?.appautoupdate?.toUpperCase() === 'Y' ? 'Yes' : 'No';
                    setVppData(result[2]?.value || '');
                }
                if (result[3].status === 'fulfilled') {
                    handleES(result[3].value ? result[3].value : {});
                }
                setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    const handleES = (resp) => {
        let obj = {};
        if (resp) {
            ENROLLMENT_STEPS_DATA?.forEach(async (dataObj) => {
                if (dataObj.inputType !== 'heading') {
                    let key = definePath(dataObj, resp);
                    if (dataObj.inputType === 'input') obj[dataObj.value] = key?.[dataObj.value];
                    else if (dataObj.inputType === 'dropdown') obj = await defineDropDown(obj, key, dataObj);
                    else if (dataObj.inputType === 'checkbox') obj[dataObj.value] = !!key?.[dataObj.value];
                    else obj[dataObj.value] = key?.[dataObj.value] || [];
                }
            });
            resp = resp ? { ...resp, ...obj } : '';
        }
        setEnrollmentSteps(resp);
    };

    const handleKeyDown = (e) => {
        if (e.keyCode === 13) e.preventDefault();
    };

    const getABMData = () => {
        setLoading(true);
        api.get(url.MDMSERVER, '', domains.IOS_V1)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;

                if (resp.status?.toLowerCase() === 'success') {
                    setAbmData(resp.data || []);
                }
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    const getAPNData = () => {
        setLoading(true);
        api.get(url.APN_GET, '', domains.IOS_V1)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status?.toLowerCase() === 'success') {
                    setApnData(resp.data ? resp.data : '');
                }
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    const getVPPData = () => {
        setLoading(true);
        api.get(url.VPP_DATA, '', domains.IOS_V1)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status?.toLowerCase() === 'success') {
                    if (resp.data?.region?.name) resp.data.name = resp.data?.region?.name;
                    if (resp.data?.appautoupdate) resp.data.appAutoUpdate = resp.data?.appautoupdate?.toUpperCase() === 'Y' ? 'Yes' : 'No';
                    setVppData(resp.data);
                }
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    const getES = () => {
        setLoading(true);
        api.get(url.ENROLLMENT_STEPS, '', domains.IOS_V1)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') handleES(resp.data);
                setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    const handleDeleteConfirmation = () => {
        setShowDelete(false);
        setLoading(true);
        switch (modalDetatils.type) {
            case 'apns':
                api.delete(url.APN_GET + '/' + modalDetatils.id, '', domains.IOS_V1)
                    .then((resp) => {
                        resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                        if (resp.status?.toLowerCase() === 'success') {
                            toast.success(toastMessages.APNDeleted);
                            setModalDetails({ message: '', header: '', id: '', type: '' });
                        }
                        getAPNData();
                        setLoading(false);
                    })
                    .catch((err) => setLoading(false));
                break;
            case 'abm':
                api.delete(url.MDMSERVER + '/' + modalDetatils.id, '', domains.IOS_V1)
                    .then((resp) => {
                        resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                        if (resp.status?.toLowerCase() === 'success') {
                            toast.success(toastMessages.ABMDeleted);
                            setModalDetails({ message: '', header: '', id: '', type: '' });
                        }
                        getABMData();
                        setLoading(false);
                    })
                    .catch((err) => setLoading(false));
                break;
            case 'vpp':
                api.delete(url.VPP_DATA + '/' + modalDetatils.id, '', domains.IOS_V1)
                    .then((resp) => {
                        resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                        if (resp.status?.toLowerCase() === 'success') {
                            toast.success(toastMessages.VPPDeleted);
                            setModalDetails({ message: '', header: '', id: '', type: '' });
                        }
                        getVPPData();
                        setLoading(false);
                    })
                    .catch((err) => setLoading(false));
                break;
            default:
                break;
        }
    };

    const message = () => {
        return (
            <div className="mt-3">
                <div className="mb-4">{modalDetatils.message}</div>
                <div className="mb-4 d-flex align-items-center justify-content-center">
                    <img height={65} src={images[modalDetatils.type]} alt="img" />
                </div>
            </div>
        );
    };

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Apple Setup" pageTitle="Apple Setup" history={history} />
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                            <ApplePushNotifications
                                setLoading={setLoading}
                                handleDelete={(val) => {
                                    setShowDelete(val.enabled);
                                    setModalDetails({
                                        message: 'Are you sure you want to delete APNS?',
                                        header: 'Delete APNS',
                                        id: val.id,
                                        type: 'apns'
                                    });
                                }}
                                getAPNData={getAPNData}
                                apns={apnData}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                            <AppleBusinessManager
                                setLoading={setLoading}
                                getABMData={getABMData}
                                handleDelete={(val) => {
                                    setShowDelete(true);
                                    setModalDetails({
                                        message: 'Are you sure you want to delete Apple Business/School Manager?',
                                        header: 'Delete ABM',
                                        id: val?.id,
                                        type: 'abm'
                                    });
                                }}
                                abm={abmData}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                            <VolumePurchaseProgram
                                setLoading={setLoading}
                                getVPPData={getVPPData}
                                handleDelete={(val) => {
                                    setShowDelete(true);
                                    setModalDetails({
                                        message: 'Are you sure you want to delete Apps & Books(VPP)?',
                                        header: 'Delete VPP',
                                        id: val?.id,
                                        type: 'vpp'
                                    });
                                }}
                                vpp={vppData}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                            <EnrollmentSteps data={enrollmentSteps} handleKeyDown={handleKeyDown} getES={getES} setLoading={setLoading} />
                        </Col>
                    </Row>
                </Container>
                <DeleteModal
                    show={showDelete}
                    hideIcon={true}
                    hideDeleteMessage={true}
                    message={message()}
                    confirmText={'Delete'}
                    onDeleteClick={handleDeleteConfirmation}
                    onCloseClick={() => {
                        setModalDetails({ id: '', message: '', header: '', type: '' });
                        setShowDelete(false);
                    }}
                    deleteMessage={modalDetatils.message}
                />
            </div>
        </React.Fragment>
    );
};

export default Setup;
