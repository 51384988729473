const tvPoliciesFieldsSchema = [
    {
        label: 'General Settings',
        icon: 'ri-settings-5-line',
        active: true,
        fields: [
            {
                label: 'Wallpaper',
                value: 'wallpaper',
                helpText: 'Select a wallpaper that has already been configured in Assets',
                inputType: 'select',
                optionsVal: 'wallpapers',
                optionLabel: 'label',
                optionValue: 'value',
                removable: true
            },
            {
                label: 'GeoFence',
                value: 'geofence',
                helpText: 'Select a Geofence that has been configured under Geofence in configurations tab',
                inputType: 'select',
                optionLabel: 'name',
                optionValue: 'config',
                optionsVal: 'geofenceConfigs',
                conditionalHide: true,
                removable: true,
                hideOn: 'tenant',
                field: 'ENABLE_TV_GEOFENCE'
            },
            {
                label: 'Enable Volume',
                value: 'enableVolume',
                inputType: 'toggle',
                helpText: 'Enable Volume to control audio levels for sound output on your device'
            },
            {
                label: 'Volume Level',
                value: 'volumeLevel',
                inputType: 'slider',
                helpText:
                    'Enabling this feature allows the admin to manage device volume. This would be the default volume on all devices.',
                step: 1,
                max: 100,
                min: 0,
                conditionalHide: true,
                hideOn: 'value',
                field: 'enableVolume'
            },
            {
                label: 'Enable Input Source',
                value: 'enableInputMethod',
                inputType: 'toggle',
                helpText: 'Enable Input Source (HDMI) to switch the display or device input to HDMI for audio and video transmission'
            },
            {
                label: 'Select Input Source',
                value: 'inputDropdown',
                inputType: 'select',
                mandatory: true,
                helpText: 'Choose the input source for the device.',
                options: [
                    { label: 'HDMI1', value: 'HDMI1' },
                    { label: 'HDMI2', value: 'HDMI2' },
                    { label: 'HDMI3', value: 'HDMI3' },
                    { label: 'HDMI4', value: 'HDMI4' }
                ],
                conditionalHide: true,
                placeholder: 'dropdown',
                hideOn: 'value',
                field: 'enableInputMethod',
                removable: true
            }
            /*
             * {
             *     label: 'Launcher Exit Password',
             *     value: 'launcherExitPassword',
             *     inputType: 'input',
             *     mandatoryTooltip: true,
             *     helpText: 'Enter password to exit Launcher  (in between 3 to 6 digits).'
             * },
             */
            /*
             * {
             *     label: 'Location',
             *     value: 'enableLocation',
             *     helpText: 'Enabled location history',
             *     inputType: 'toggle'
             * },
             * {
             *     label: 'Remote Monitoring',
             *     value: 'enableRemote',
             *     helpText: 'Enabled remote monitoring',
             *     inputType: 'toggle'
             * }
             */
            /*
             * {
             *     label: 'Screamer',
             *     value: 'enableScreamer',
             *     helpText: 'Enabled screamer',
             *     inputType: 'toggle'
             * }
             */
        ]
    },
    {
        label: 'Power Controls',
        key: 'powerControls',
        icon: 'ri-shut-down-line',
        active: false,
        fields: [
            {
                label: 'Enable Power Control',
                value: 'enablepowerControl',
                inputType: 'toggle',
                helpText: 'Enable Power Control to manage device power settings like turning on/off'
            },
            {
                label: 'Enable Power Status',
                value: 'enablePowerStatus',
                inputType: 'select',
                mandatory: true,
                helpText: 'This feature enables the admin to power on or off all the devices linked to the policy. ',
                options: [
                    { label: 'ON', value: true },
                    { label: 'OFF', value: false }
                ],
                conditionalHide: true,
                hideOn: 'showon',
                showOn: ['enablepowerControl']
            },
            {
                label: 'Enable Schedule Power Controls',
                value: 'enableSchedulepowerControl',
                inputType: 'toggle',
                // eslint-disable-next-line quotes
                helpText: "This feature enables admin to schedule power controls to automatically manage the IFP's power ON and OFF times.",
                conditionalHide: true,
                hideOn: 'showon',
                showOn: ['enablepowerControl']
            },
            {
                label: 'Frequency',
                value: 'frequency',
                inputType: 'checkbox',
                helpText: 'Choose the frequency for scheduling power controls',
                conditionalHide: true,
                mandatory: true,
                hideOn: 'showon',
                showOn: ['enableSchedulepowerControl', 'enablepowerControl'],
                checkboxes: ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN']
            },

            {
                label: 'Power on Time',
                value: 'powerontime',
                type: 'time',
                icon: 'ri-time-line',
                inputType: 'timePicker',
                mandatory: true,
                conditionalHide: true,
                helpText: 'Specify the time for device to be turned ON automatically.',
                hideOn: 'showon',
                showOn: ['enableSchedulepowerControl', 'enablepowerControl'],
                options: {
                    enableTime: true,
                    noCalendar: true,
                    dateFormat: 'h:i K'
                }
            },
            {
                label: 'Shutdown Time',
                value: 'shutdowntime',
                type: 'time',
                icon: 'ri-time-line',
                inputType: 'timePicker',
                helpText: 'Specify the time for device to be turned OFF automatically.',
                mandatory: true,
                conditionalHide: true,
                hideOn: 'showon',
                showOn: ['enableSchedulepowerControl', 'enablepowerControl'],
                alertMsg: 'Shutdown time should be greater than power on time',
                options: {
                    enableTime: true,
                    noCalendar: true,
                    dateFormat: 'h:i K',
                    minuteIncrement: 5
                }
            }
        ]
    },
    {
        label: 'Applications',
        key: 'apps',
        icon: 'ri-apps-line',
        active: false
    }
];

export default tvPoliciesFieldsSchema;
