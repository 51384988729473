import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
    Alert,
    Button,
    Card,
    CardBody,
    Col,
    Container,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Form,
    FormFeedback,
    Input,
    Label,
    Row,
    UncontrolledDropdown,
    UncontrolledTooltip
} from 'reactstrap';
import * as Yup from 'yup';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import CommonModal from '../../Components/Common/CommonModal.js';
import DeleteModal from '../../Components/Common/DeleteModal';
import { commonActionsService } from '../../Components/Common/DeviceUtils.js';
import Loader from '../../Components/Common/Loader.js';
import NoRecordsFound from '../../Components/Common/NoRecordsFound.js';
import { TenantConfig, convertTextCase, getDate } from '../../Components/Common/Util.js';
import {
    togglePassword,
    togglePasswordChange,
    togglePasswordChangeVal,
    tooglePasswordStrengthIn,
    tooglePasswordStrengthOut
} from '../../Components/Hooks/UserHooks.js';
import { detailsObj, myDeviceCards } from '../../Components/constants/commons.js';
import android from '../../assets/images/androidlogo.png';
import ios from '../../assets/images/ios.png';
import toastMessages from '../../common/messages/toastMessages.js';
import { useEnv } from '../../envContext.js';
import { APIClient } from '../../helpers/api_helper.js';
import * as domains from '../../helpers/domain_helper.js';
import * as url from '../../helpers/url_helper.js';
import { actionMessage } from '../AndroidEnterprise/Devices/AndroidEnterpriseViewDevicesSummary.js';
import OffcanvasModal from '../../Components/Common/OffcanvasModal.js';
import { AndroidPolicyQR, enrolmentProcess, qrSecurityOptions } from '../../Components/constants/constants.js';
import Select from 'react-select';

const MyDevices = () => {
    document.title = 'My Devices';

    const history = useHistory();
    const tenantConfig = TenantConfig();
    const api = new APIClient();
    const urlconf = useEnv();
    const [devicesData, setDevicesData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [type, setType] = useState('');
    const [actionModal, setActionModal] = useState(false);
    const [actionText, setActionText] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [modalType, setModalType] = useState('');
    const [resetLoader, setResetLoader] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});
    const [monitoring, setMonitoring] = useState('');
    const [antivirusAction, setAntivirusAction] = useState('START');
    const [factoryMode, setFactoryMode] = useState('enable');
    const [deviceType, setDeviceType] = useState('');
    const [qrCodeString, setQRCodeString] = useState('');
    const [showQRDetailsModal, setShowQRDetailsModal] = useState(false);
    const [qrDetailsObj, setQrDetailObj] = useState({ ...detailsObj });

    const actions = {
        reboot: {
            info: 'Are you sure you want to reboot the device?',
            actionBody: { command: 'RestartDevice', params: { RequestType: 'RestartDevice' } },
            toastMessage: toastMessages.rebootDevice
        },
        shutdown: {
            info: 'Are you sure you want to shutdown the device?',
            actionBody: { command: 'ShutDownDevice', params: { RequestType: 'ShutDownDevice' } },
            toastMessage: toastMessages.shutdownDevice
        },
        lock: {
            info: 'Are you sure you want to lock the device?',
            actionBody: {
                command: 'DeviceLock',
                params: { RequestType: 'DeviceLock', Message: 'Locked by your TT Admin' }
            },
            toastMessage: toastMessages.lockDevice
        }
    };

    useEffect(() => {
        getMyDevices();
    }, []);

    const getMyDevices = () => {
        setLoading(true);
        const androidPromise = new Promise((resolve, reject) => {
            api.get(url.MY_DEVICE, { page: 1, size: 10 })
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp) resolve(resp);
                    else reject('Android Failed.');
                })
                .catch((_err) => reject('Android Failed.'));
        });

        const iosPromise = new Promise((resolve, reject) => {
            api.get(url.MY_DEVICES, { page: 1, size: 10 }, domains.IOS_V1)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp) resolve(resp);
                    else reject('Android Failed.');
                })
                .catch((_err) => reject('iOS Failed.'));
        });

        Promise.allSettled([androidPromise, iosPromise])
            .then((result) => {
                if (result[0].status === 'fulfilled') {
                    result[0]?.value?.data?.forEach((data) => {
                        data.type = 'android';
                        data.manufacturer = data?.details?.manufacturer || '—';
                        data.policy = data.policyApplied;
                        data.status = data.deviceStatusApplied;
                        data.group = data.deviceGroup;
                        data.enrolledDate = data.enrollmentDate ? getDate(data.enrollmentDate) : '—';
                    });
                }
                if (result[1].status === 'fulfilled') {
                    result[1].value.data = handleIOSDevices(result[1].value?.data || []);
                }

                let devices = [...(result[0].value?.data || []), ...(result[1].value?.data || [])];
                setDevicesData(devices);
            })
            .catch((_err) => {})
            .finally(() => setLoading(false));
    };

    const handleIOSDevices = (devices) => {
        devices?.forEach((data) => {
            data.id = data._id;
            data.serial = data.refs?.find((ref) => ref.type === 'SERIAL')?.value;
            data.type = 'ios';
            data.status = data.enroll?.status;
            data.enrolledDate = data.enroll?.enrolldate ? getDate(data.enroll?.enrolldate) : '—';
            data.group = data.assignment?.group;
            data.policy = data.assignment?.policy;
            data.manufacturer = 'Apple';
        });
        return devices;
    };
    const viewDeviceDetails = (ele) => {
        history.push(`/mydevices/view/${ele.type}/${ele['id']}`);
    };

    const handleResetPassword = (ele) => {
        setSelectedRow(ele);
        setShowModal(true);
        setModalType('reset');
    };

    const handleiosActions = (params) => {
        params.setLoading(true);
        params.setShowModal(false);
        const body = { ...actions?.[params?.type]?.actionBody, device: params.deviceID };
        api.create(url.IOS_COMMANDS, body, false, domains.IOS_V1)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    toast.success(actions?.[params?.type]?.toastMessage);
                    getMyDevices();
                }
                setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    const actionService = () => {
        setActionModal(false);
        if (selectedRow.type === 'android') {
            commonActionsService({
                deviceID: selectedRow?.id,
                type: type,
                setLoading: setLoading,
                setShowModal: setShowModal,
                urlconf: urlconf
            });
        } else if (selectedRow.type === 'ios') {
            handleiosActions({ deviceID: selectedRow?.id, type: type, setLoading: setLoading, setShowModal: setShowModal });
        }
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: { newPassword: '', default_password: 'true' },
        validationSchema: Yup.object({
            newPassword: Yup.string().required('Please Enter Your New Password')
        }),
        onSubmit: (values) => {
            handleSubmit(values);
        }
    });

    const handleSubmit = (obj) => {
        commonActionsService({
            deviceID: selectedRow?.id,
            type: 'resetpassword',
            values: obj,
            setLoading: setResetLoader,
            setShowModal: setShowModal,
            urlconf: urlconf
        });
        validation.resetForm();
    };

    const handleQRService = () => {
        setLoading(true);
        let qrPromise;
        qrPromise = new Promise((resolve, reject) =>
            api
                .get(url.POLICIES + url.QR_CODE + '/DEFAULT', '', domains.IOS_V1)
                .then((resp) => {
                    toast.success(toastMessages.qrGenerated);
                    resolve(resp.data);
                })
                .catch((_err) => reject('qr failed'))
        );
        Promise.allSettled([qrPromise])
            .then((result) => {
                if (result[0].status === 'fulfilled') {
                    setQRCodeString(result[0].value);
                }
            })
            .catch((err) => {})
            .finally(() => setLoading(false));
        setModalType('qr');
    };

    const handleModalClick = () => {
        if (deviceType.type === 'apple') {
            handleQRService();
        } else if (deviceType.type === 'android') {
            setShowModal(false);
            setShowQRDetailsModal(true);
        }
    };

    const handleQrConfirmation = () => {
        setShowQRDetailsModal(false);
        setLoading(true);
        let qrObj = {
            code: 'DEFAULT',
            multipleEnrollments: true,
            signIn: true,

            config: {
                systemApps: !!qrDetailsObj?.systemApps,
                educationScreens: !!qrDetailsObj?.educationScreens,
                allowOffline: !!qrDetailsObj?.allowOffline,
                screenOn: !!qrDetailsObj?.screenOn,
                pin: qrDetailsObj?.pin ? qrDetailsObj?.pin : undefined,
                securityType: qrDetailsObj?.wifiId ? qrDetailsObj?.securityType?.value : undefined,
                wifiId: qrDetailsObj?.wifiId ? qrDetailsObj?.wifiId : undefined,
                password: qrDetailsObj?.password ? qrDetailsObj?.password : undefined,
                noOfDays: 1
            }
        };
        api.create(url.MY_POLICIES + '/qrcode', qrObj)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status?.toLowerCase() === 'success') {
                    toast.success(tenantConfig.ENABLE_SIGN_IN ? toastMessages.qrGeneratedWithoutSignIn : toastMessages.qrGenerated);
                    setShowQRDetailsModal(true);
                    setQRCodeString(resp.data);
                    setLoading(false);
                }
                setQrDetailObj({ ...detailsObj });
            })
            .catch((err) => setLoading(false));
    };

    const handleModalBody = () => {
        return modalType === 'reset' ? (
            <Row className={'p-4 justify-content-center ' + (resetLoader ? 'mask' : '')}>
                {resetLoader && <Loader />}
                <div className="text-center mt-2">
                    <h5 className="text-primary">Reset Password?</h5>
                    <p className="text-muted mb-1">
                        Reset your password with <b>{tenantConfig.COMPANY_DEVELOPED_BY}</b>
                    </p>
                    <i className="ri-lock-password-line font-50 text-success" color="primary"></i>
                </div>
                <Alert className="alert-borderless alert-warning text-center mb-2 mx-2" role="alert">
                    Please set as new password for the device - {selectedRow?.serial}
                </Alert>
                <Col className="d-flex align-items-center justify-content-center mt-2">
                    <div className="form-check form-check-inline">
                        <Input
                            type="radio"
                            value={'true'}
                            id={'default_password'}
                            name={'default_password'}
                            className="form-check-input"
                            onBlur={validation.handleBlur}
                            onChange={validation.handleChange}
                            checked={validation.values['default_password'] === 'true'}
                        />
                        <Label className="form-check-label">{'Default Password'}</Label>
                    </div>
                    <div className="form-check form-check-inline">
                        <Input
                            type="radio"
                            value={'false'}
                            id={'default_password'}
                            name={'default_password'}
                            className="form-check-input"
                            onBlur={validation.handleBlur}
                            onChange={validation.handleChange}
                            checked={validation.values['default_password'] === 'false'}
                        />
                        <Label className="form-check-label">{'Custom Password'}</Label>
                    </div>
                </Col>
                <div className="p-2">
                    <Form>
                        {validation.values['default_password'] === 'true' && (
                            <>
                                <div className="d-flex align-items-center justify-content-center mt-4">
                                    <Label className="form-label">Default Password :</Label>
                                    <Alert className="alert-borderless alert-info text-center mb-2 mx-2" role="alert">
                                        {tenantConfig?.DEVICE_DEFAULT_PASSWORD}
                                    </Alert>
                                </div>
                                <div className="text-center mt-4">
                                    <button
                                        type="submit"
                                        className="btn btn-success w-100"
                                        onClick={(e) => handleSubmit({ newPassword: tenantConfig?.DEVICE_DEFAULT_PASSWORD })}
                                    >
                                        Reset Password
                                    </button>
                                </div>
                            </>
                        )}
                        {validation.values['default_password'] === 'false' && (
                            <>
                                <div className="mb-4">
                                    <Label className="form-label">New Password</Label>
                                    <div className="position-relative auth-pass-inputgroup">
                                        <Input
                                            onFocus={() => tooglePasswordStrengthIn('password-contain')}
                                            onBlur={(e) => {
                                                validation.handleBlur(e);
                                                togglePasswordChangeVal(validation.values.newPassword) &&
                                                    tooglePasswordStrengthOut('password-contain');
                                            }}
                                            name="newPassword"
                                            type="password"
                                            autoComplete="off"
                                            className="form-control pe-5 password-input"
                                            onPaste={() => false}
                                            placeholder="Enter new password"
                                            id="password-input"
                                            aria-describedby="passwordInput"
                                            required
                                            onChange={(e) => {
                                                validation.handleChange(e);
                                                togglePasswordChange(e.target.value);
                                            }}
                                            value={validation.values.newPassword || ''}
                                            invalid={validation.touched.newPassword && validation.errors.newPassword ? true : false}
                                        />
                                        {validation.touched.newPassword && validation.errors.newPassword ? (
                                            <FormFeedback type="invalid">
                                                <div>{validation.errors.newPassword}</div>
                                            </FormFeedback>
                                        ) : null}
                                        {validation.values.newPassword && validation.values.newPassword !== '' && (
                                            <Button
                                                color="link"
                                                className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                                                type="button"
                                                onClick={() => togglePassword('password-input', 'password-icon1')}
                                            >
                                                <i id="password-icon1" className="ri-eye-off-fill align-middle"></i>
                                            </Button>
                                        )}
                                    </div>
                                    <div id="passwordInput" className="form-text">
                                        Must be at least 8 characters.
                                    </div>
                                </div>
                                <div id="password-contain" className="p-3 bg-light mb-2 rounded">
                                    <h5 className="fs-13">Password must contain:</h5>
                                    <p id="pass-length" className="invalid fs-12 mb-2">
                                        Minimum <b>8 characters</b>
                                    </p>
                                    <p id="pass-max-length" className="invalid fs-12 mb-2">
                                        Maximum <b>12 characters</b>
                                    </p>
                                    <p id="pass-lower" className="invalid fs-12 mb-2">
                                        At least 1 <b>lowercase</b> letter (a-z)
                                    </p>
                                    <p id="pass-upper" className="invalid fs-12 mb-2">
                                        At least 1 <b>uppercase</b> letter (A-Z)
                                    </p>
                                    <p id="pass-number" className="invalid fs-12 mb-2">
                                        At least 1 <b>number</b> (0-9)
                                    </p>
                                    <p id="pass-special" className="invalid fs-12 mb-0">
                                        At least 1 <b>Special</b> Symbol
                                    </p>
                                </div>
                                <div className="text-center mt-4">
                                    <button
                                        type="submit"
                                        className="btn btn-success w-100"
                                        onClick={(e) => validation.handleSubmit()}
                                        disabled={!togglePasswordChangeVal(validation.values.newPassword)}
                                    >
                                        Reset Password
                                    </button>
                                </div>
                            </>
                        )}
                    </Form>
                </div>
            </Row>
        ) : modalType === 'qr' ? (
            <Row className="d-flex align-items-center justify-content-center">
                <Col sm="4">
                    <div className="border border-info rounded-2 border-2">
                        <img alt="" className="w-100" src={`data:image/png;base64,${qrCodeString}`} height={300} />
                    </div>
                </Col>
                <Col sm="8">
                    <div className="fw-medium text-primary mb-2">Steps to Enroll the Device :</div>
                    <div>{deviceType.steps}</div>
                </Col>
            </Row>
        ) : (
            <Row>
                <div className="d-flex align-items-center gap-2 w-100">
                    {myDeviceCards?.map(
                        (device, index) =>
                            !!tenantConfig?.[device.key] && (
                                <Card
                                    key={index}
                                    className={`d-flex align-items-center justify-content-center mb-0 p-0 border shadow cursor-pointer ${
                                        device.type === deviceType.type ? 'border-2 border-primary' : ''
                                    }`}
                                    onClick={() => setDeviceType(device)}
                                >
                                    <CardBody className="d-flex flex-column align-items-center justify-content-center position-relative w-100 h-100">
                                        {device.type === deviceType.type && (
                                            <i className="position-absolute end-10 top-5 link-primary fs-18 ri-check-double-fill"></i>
                                        )}
                                        <div>
                                            <img src={device.img} alt="" height={50} />
                                        </div>
                                        <p className="text-uppercase fw-semibold text-info text-truncate mt-3 mb-3 fs-14">{device.label}</p>
                                        <span className="text-wrap text-center fw-normal fs-12">{device.desc}</span>
                                    </CardBody>
                                </Card>
                            )
                    )}
                </div>
            </Row>
        );
    };

    const radioButton = (field, index) => {
        return (
            <Row key={index}>
                {field?.label && (
                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Label className="mb-3 fw-semibold fs-14">{field?.label}</Label>
                        <div>
                            {field.helpText && (
                                <div className={`text-muted fs-12 word-wrap-break${field.noMargin ? '' : 'mb-2'}`}>{field.helpText}</div>
                            )}
                        </div>
                    </Col>
                )}

                <Col xs={12} sm={12} md={12} lg={12} xl={12} className={`${field.noMargin ? '' : 'mb-2'}`}>
                    <Row>
                        {field?.radios
                            ?.filter((obj) => obj.val !== 'withoutLogin')
                            ?.map((ele, ind) => {
                                return (
                                    <Col className="form-check form-check-inline col-12 mb-3" key={ind}>
                                        <Input
                                            className="form-check-input"
                                            type="radio"
                                            id={field.value}
                                            name={field.value}
                                            value={ele.val}
                                            onChange={(e) => {
                                                setQrDetailObj({
                                                    ...qrDetailsObj,
                                                    [field.value]: e.target.value,
                                                    pin: ''
                                                });
                                            }}
                                            checked={qrDetailsObj?.[field.value] === ele.val}
                                        />
                                        <Label className="mb-0 d-flex">
                                            {ele.label}
                                            <i
                                                className="ri-information-line d-flex align-items-center blue-icon ps-1 fs-15"
                                                id={`radioButton-${ind}`}
                                            />
                                            <UncontrolledTooltip placement="bottom" target={`radioButton-${ind}`}>
                                                {ele?.toolTip}
                                            </UncontrolledTooltip>
                                        </Label>
                                    </Col>
                                );
                            })}
                    </Row>
                </Col>
            </Row>
        );
    };
    const inputComponent = (field, index) => {
        return (
            <React.Fragment key={index}>
                {field?.label && (
                    <Col
                        xs={field.smallLabel ? 2 : 4}
                        sm={field.smallLabel ? 2 : 4}
                        md={field.smallLabel ? 2 : 4}
                        xl={field.smallLabel ? 2 : 4}
                        lg={field.smallLabel ? 2 : 4}
                        className="d-flex align-items-center justify-content-between"
                    >
                        <Label className="form-check-label fw-medium d-flex align-items-center mb-0">
                            {field.label}
                            {field.mandatory ? (
                                <span className="red-color ps-1">*</span>
                            ) : (
                                <>
                                    <i
                                        className="ri-information-line d-flex align-items-center blue-icon ps-1 fs-15"
                                        id={`inputComponent-${index}`}
                                    />
                                    <UncontrolledTooltip placement="bottom" target={`inputComponent-${index}`}>
                                        {field.helpText ? field.helpText : 'Helper Text'}
                                    </UncontrolledTooltip>
                                </>
                            )}
                        </Label>
                    </Col>
                )}

                <Col
                    xs={field.largeBox ? 6 : 4}
                    sm={field.largeBox ? 6 : 4}
                    md={field.largeBox ? 6 : 4}
                    xl={field.largeBox ? 6 : 4}
                    lg={field.largeBox ? 6 : 4}
                >
                    <div className={'input-group '}>
                        <div className="d-flex align-items-center w-100">
                            <Input
                                name={field.value}
                                id={field.value}
                                className={`form-control ${field.class ? field.class : 'w-100'}`}
                                placeholder={`Enter ${field.label ? field?.label : field?.placeHolder}`}
                                type={field.type}
                                maxLength={field.maxLength}
                                validate={{ required: { value: true } }}
                                onChange={(e) => setQrDetailObj({ ...qrDetailsObj, pin: e.target.value })}
                                value={qrDetailsObj?.[field.value] || ''}
                            />
                        </div>
                    </div>
                </Col>
            </React.Fragment>
        );
    };

    const dynamicComponent = (field, index) => {
        switch (field?.inputType) {
            case 'radio':
                return radioButton(field, index);
            case 'input':
                return inputComponent(field, index);
        }
    };

    const handleShowOn = (field) => {
        let flag = true;
        flag = field.showKey ? flag && qrDetailsObj[field.showKey] === field.showVal : flag;
        return flag;
    };

    const handleQrDetails = (e, key) => {
        setQrDetailObj({ ...qrDetailsObj, [key]: e.target.checked });
    };

    const toggleButton = (field, index) => {
        return (
            <>
                <Col xs={6} md={6} sm={6} lg={5} xl={5} className="d-flex align-items-center">
                    <Label className="mb-0 fw-medium d-flex align-items-center w-100">
                        {field.label}
                        {field.helpText ? (
                            <>
                                <i
                                    className="ri-information-line d-flex align-items-center blue-icon ps-1 fs-15"
                                    id={`toggleButton-${index}`}
                                />
                                <UncontrolledTooltip placement="bottom" target={`toggleButton-${index}`}>
                                    {field.helpText ? field.helpText : 'Helper Text'}
                                </UncontrolledTooltip>
                            </>
                        ) : (
                            <span className="ps-1">:</span>
                        )}
                    </Label>
                </Col>
                <Col xs={6} md={6} sm={6} lg={7} xl={7} className="d-flex padding-left-30">
                    <div className="form-check form-switch form-switch-lg">
                        <Input
                            className="form-check-input"
                            type="checkbox"
                            role="switch"
                            name={field.value}
                            id={field.value}
                            checked={qrDetailsObj[field.value] || false}
                            onChange={(e) => handleQrDetails(e, field.value)}
                        />
                    </div>
                </Col>
            </>
        );
    };

    const handleQRModalBody = () => {
        return qrCodeString ? (
            <Row className="d-flex align-items-center justify-content-center">
                {/* {tenantConfig?.ENABLE_SIGN_IN && <div className="mb-2">{generatingQrCode()}</div>} */}
                <div className="text-center">
                    <h5 className="text-primary">QR Code</h5>
                    <Alert className="alert-borderless alert-warning text-center mt-3 mb-0" role="alert">
                        Please scan for the device enrollment
                    </Alert>
                </div>
                <div className={`d-flex align-items-center justify-content-center flex-wrap ${qrCodeString ? '' : 'mt-3'}`}>
                    <img alt="" className="w-75" src={`data:image/png;base64,${qrCodeString}`} />
                </div>
            </Row>
        ) : (
            <>
                <div className="w-100 pb-2 border-bottom">
                    <div className="fs-14 fw-semibold mb-2">Enrollment Steps :</div>
                    <Row>
                        {enrolmentProcess?.map((qrDetail, ind) => {
                            return (
                                <Row className="mb-2" key={ind}>
                                    {toggleButton(qrDetail, ind)}
                                </Row>
                            );
                        })}

                        <Col xs={6} md={6} sm={6} lg={5} xl={5} className="d-flex align-items-center">
                            <Label className="mb-0 fw-medium d-flex align-items-center w-100">
                                Wi-Fi SSID
                                <i
                                    className="ri-information-line d-flex align-items-center blue-icon ps-1 fs-15"
                                    id={'toggleButton-SSID'}
                                />
                                <UncontrolledTooltip placement="bottom" target={'toggleButton-SSID'}>
                                    {`
							1. configured Wi-Fi network should be used during device provisioning and connect automatically.
							2. Skipping Wi-Fi isn't allowed.
							`}
                                </UncontrolledTooltip>
                            </Label>
                        </Col>
                        <Col xs={6} md={6} sm={6} lg={7} xl={7} className="mb-2">
                            <div className="form-check ">
                                <Input
                                    name={'wifiId'}
                                    id={'wifiId'}
                                    className={'form-control'}
                                    placeholder={'Enter Wi-Fi SSID'}
                                    type={'text'}
                                    onChange={(e) => setQrDetailObj({ ...qrDetailsObj, wifiId: e.target.value })}
                                    value={qrDetailsObj.wifiId}
                                />
                            </div>
                        </Col>

                        <Col xs={6} md={6} sm={6} lg={5} xl={5} className="d-flex align-items-center">
                            <Label className="mb-0 fw-medium d-flex align-items-center w-100">
                                Security Type
                                <i
                                    className="ri-information-line d-flex align-items-center blue-icon ps-1 fs-15"
                                    id={'toggleButton-Security'}
                                />
                                <UncontrolledTooltip placement="bottom" target={'toggleButton-Security'}>
                                    Wi-Fi Password
                                </UncontrolledTooltip>
                            </Label>
                        </Col>
                        <Col
                            xs={6}
                            md={6}
                            sm={6}
                            lg={7}
                            xl={7}
                            className={`${
                                qrDetailsObj?.securityType?.value && qrDetailsObj.securityType?.value?.toLowerCase() !== 'none'
                                    ? 'mb-2'
                                    : ''
                            }`}
                        >
                            <div className="form-check pl-0 ">
                                <Select
                                    getOptionValue={(option) => option.label}
                                    getOptionLabel={(option) => option.label}
                                    id="securityType"
                                    name="securityType"
                                    classNamePrefix={'custom-select w-100'}
                                    options={qrSecurityOptions}
                                    placeholder="Select Security"
                                    onChange={(selectedOption) => setQrDetailObj({ ...qrDetailsObj, securityType: selectedOption })}
                                    value={qrDetailsObj.securityType || ''}
                                    isSearchable={true}
                                    noOptionsMessage={() => 'No data found'}
                                />
                            </div>
                        </Col>

                        {qrDetailsObj?.securityType?.value && qrDetailsObj.securityType?.value?.toLowerCase() !== 'none' && (
                            <>
                                <Col xs={6} md={6} sm={6} lg={5} xl={5} className="d-flex align-items-center">
                                    <Label className="mb-0 fw-medium d-flex align-items-center w-100">
                                        Password
                                        <i
                                            className="ri-information-line d-flex align-items-center blue-icon ps-1 fs-15"
                                            id={'toggleButton-Password'}
                                        />
                                        <UncontrolledTooltip placement="bottom" target={'toggleButton-Password'}>
                                            Password
                                        </UncontrolledTooltip>
                                    </Label>
                                </Col>
                                <Col xs={6} md={6} sm={6} lg={7} xl={7}>
                                    <div className="form-check">
                                        <Input
                                            type={'text'}
                                            id={'password'}
                                            name={'password'}
                                            className={'form-control'}
                                            placeholder={'Enter password'}
                                            onChange={(e) => setQrDetailObj({ ...qrDetailsObj, password: e.target.value })}
                                            value={qrDetailsObj.password}
                                        />
                                    </div>
                                </Col>
                            </>
                        )}
                    </Row>
                </div>
                <div className="mt-3">
                    {AndroidPolicyQR?.map((field, index) => (field?.showOn ? handleShowOn(field) : true) && dynamicComponent(field, index))}
                </div>
            </>
        );
    };

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className={`page-content h-100 ${loading ? 'postion-relative mask' : ''}`}>
                <Container fluid>
                    <BreadCrumb title="My Devices" pageTitle="My Devices" history={history} />

                    <Col sm="auto" className="d-flex justify-content-end mb-3">
                        <div>
                            <Button
                                type="button"
                                color="primary"
                                onClick={() => {
                                    setShowModal(true);
                                    setModalType('add');
                                    setDeviceType('');
                                }}
                            >
                                + Add New Device
                            </Button>
                        </div>
                    </Col>

                    {!devicesData.length > 0 ? (
                        <NoRecordsFound />
                    ) : (
                        devicesData.map((ele, ind) => (
                            <Card key={ind} className="shadow-card">
                                <Row className="p-4 align-items-center">
                                    <Col xs={12} sm={1} className="text-center mb-2 mb-sm-0">
                                        <span>
                                            <img src={ele.type === 'android' ? android : ios} alt="img" height={40} />
                                        </span>
                                    </Col>
                                    <Col xs={12} sm={10} className="mb-2 mb-sm-0">
                                        <Row>
                                            <Col xs={6} sm={6} md={6} lg={2}>
                                                <div className="text-muted fs-13">Serial</div>
                                                <div className="fw-medium">{ele.serial}</div>
                                            </Col>
                                            <Col xs={6} sm={6} md={6} lg={2}>
                                                <div className="text-muted fs-13">Policy</div>
                                                <div className="fw-medium">{ele.policy}</div>
                                            </Col>
                                            <Col xs={6} sm={6} md={6} lg={2}>
                                                <div className="text-muted fs-13">Group</div>
                                                <div className="fw-medium">{ele.group}</div>
                                            </Col>
                                            <Col xs={6} sm={6} md={6} lg={2}>
                                                <div className="text-muted fs-13">Manufacturer</div>
                                                <div className="fw-medium">{convertTextCase(ele?.manufacturer)}</div>
                                            </Col>
                                            <Col xs={6} sm={6} md={6} lg={2}>
                                                <div className="text-muted fs-13">Device Status</div>
                                                <div
                                                    className={`fw-medium lh-sm badge badge-soft-${
                                                        ele.status?.toLowerCase() === 'active'
                                                            ? 'success'
                                                            : ele.status?.toLowerCase() === 'disabled'
                                                            ? 'warning'
                                                            : ele.status?.toLowerCase() === 'deleted' ||
                                                              ele.status?.toLowerCase() === 'deleting'
                                                            ? 'danger'
                                                            : 'primary'
                                                    }`}
                                                >
                                                    {convertTextCase(ele.status, true)}
                                                </div>
                                            </Col>
                                            <Col xs={6} sm={6} md={6} lg={2}>
                                                <div className="text-muted fs-13">Enrollment Date</div>
                                                <div className="fw-medium">{getDate(ele.enrolledDate)}</div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col xs={12} sm={1} md={1} className="d-flex align-items-center justify-content-center">
                                        <ul className="list-inline hstack gap-2 mb-0">
                                            <li className="list-inline-item">
                                                <UncontrolledDropdown onToggle={() => setSelectedRow({ ...ele })}>
                                                    <DropdownToggle className="btn btn-soft-primary btn-sm dropdown" tag="button">
                                                        <i className="ri-more-fill align-middle"></i>
                                                    </DropdownToggle>
                                                    <DropdownMenu className="dropdown-menu-end">
                                                        <DropdownItem
                                                            className="dropdown-item"
                                                            onClick={() => {
                                                                setSelectedRow(ele);
                                                                setType('reboot');
                                                                setActionModal(true);
                                                                setActionText('Yes, Reboot it!');
                                                            }}
                                                        >
                                                            <span className="d-flex align-items-center">
                                                                <i className="ri-creative-commons-sa-line fs-17 me-2"></i>
                                                                Reboot
                                                            </span>
                                                        </DropdownItem>
                                                        <DropdownItem
                                                            className="dropdown-item"
                                                            onClick={() => {
                                                                setSelectedRow(ele);
                                                                setType('lock');
                                                                setActionModal(true);
                                                                setActionText('Yes, Lock it!');
                                                            }}
                                                        >
                                                            <span className="d-flex align-items-center">
                                                                <i className="ri-phone-lock-line fs-17 me-2"></i>
                                                                Lock
                                                            </span>
                                                        </DropdownItem>
                                                        {ele.type === 'android' && (
                                                            <DropdownItem
                                                                className="dropdown-item"
                                                                onClick={() => handleResetPassword(ele)}
                                                            >
                                                                <span className="d-flex align-items-center">
                                                                    <i className="ri-lock-password-line fs-17 me-2"></i>
                                                                    Reset Password
                                                                </span>
                                                            </DropdownItem>
                                                        )}
                                                        <DropdownItem
                                                            className="dropdown-item edit-item-btn border-top border-2"
                                                            onClick={() => viewDeviceDetails(ele)}
                                                        >
                                                            <span className="d-flex align-items-center text-primary">
                                                                <i className="ri-eye-line fs-17 me-2"></i>
                                                                View Details
                                                            </span>
                                                        </DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledDropdown>
                                            </li>
                                        </ul>
                                    </Col>
                                </Row>
                            </Card>
                        ))
                    )}
                </Container>
                <DeleteModal
                    hideIcon={true}
                    show={actionModal}
                    hideDeleteIcon={true}
                    hideDeleteMessage={true}
                    message={actionMessage({
                        deviceData: { ...selectedRow },
                        type,
                        antivirusAction,
                        setAntivirusAction,
                        setActionText,
                        factoryMode,
                        setMonitoring,
                        monitoring,
                        setFactoryMode
                    })}
                    confirmText={actionText}
                    onDeleteClick={actionService}
                    onCloseClick={() => setActionModal(false)}
                />
                <CommonModal
                    size={modalType === 'reset' ? 'md' : modalType === 'qr' ? 'lg' : 'md'}
                    show={showModal}
                    modalheader={modalType === 'add' ? 'Select Device Type' : modalType === 'qr' ? deviceType.label : ''}
                    disabled={modalType === 'add' ? deviceType === '' : false}
                    hideSaveButton={modalType !== 'add'}
                    onCloseClick={() => {
                        validation.resetForm();
                        setShowModal(false);
                    }}
                    handleClick={() => handleModalClick()}
                    handleModalBody={() => handleModalBody()}
                />
                <OffcanvasModal
                    direction="end"
                    toggle={() => setShowQRDetailsModal(false)}
                    open={showQRDetailsModal}
                    handleCloseClick={() => {
                        setShowQRDetailsModal(false);
                        setQrDetailObj({
                            educationScreens: true,
                            systemApps: false,
                            allowOffline: false,
                            screenOn: false,
                            wifiId: undefined,
                            securityType: undefined,
                            password: undefined,
                            pin: undefined,
                            securityOptions: 'withAuthentic'
                        });
                    }}
                    OffcanvasModalID="Qr_details"
                    closeButtonBorder={true}
                    saveText={'Generate QR'}
                    handleOffcanvasBody={() => handleQRModalBody()}
                    modalClassName={'w-40'}
                    saveDisabled={qrDetailsObj?.securityOptions === 'withStatic' ? qrDetailsObj?.pin?.length !== 4 : false}
                    offcanvasHeader={'Policy : Default'}
                    handleSaveClick={handleQrConfirmation}
                />
            </div>
        </React.Fragment>
    );
};

export default MyDevices;
