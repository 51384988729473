import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Card, CardBody, CardHeader, Col, Container, Input, Row } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import CustomStepperComponent from '../../../Components/Common/CustomStepperComponent';
import Loader from '../../../Components/Common/Loader';
import TabsComponent from '../../../Components/Common/TabsComponent';
import appleLogo from '../../../assets/images/applelogo.svg';
import { getAppProtectionPathParams, sortBy } from '../../../Components/Common/Util';
import android1 from '../../../assets/images/android1.png';
import toastMessages from '../../../common/messages/toastMessages';
import { useEnv } from '../../../envContext';
import { APIClient } from '../../../helpers/api_helper';
import * as domains from '../../../helpers/domain_helper';
import * as url from '../../../helpers/url_helper';
import { DynamicComponents } from './AppComponents';
import {
    ACCESS_REQUIREMENT_SCHEMA,
    ALLOW,
    CONDITIONAL_LAUNCH,
    EMPTY_OBJ,
    NOT_REQUIRED,
    REQUIRED,
    RESTRICT,
    dataProtectionSchema,
    exp,
    groupsSchema,
    radioField
} from './Schema';
import NoRecordsFound from '../../../Components/Common/NoRecordsFound';
import TableContainer from '../../../Components/Common/TableContainer';
import EllipsisToolTip from '../../../Components/Common/Tooltip/Tooltip';

const AddAPP = () => {
    document.title = 'Add App Protection Policy';
    const urlconf = useEnv();
    const api = new APIClient();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [formValues, setFormValues] = useState({ ...EMPTY_OBJ });
    const [appsArray, setAppsArray] = useState([]);
    const formTypeAndId = getAppProtectionPathParams(window.location.hash);
    const formType = formTypeAndId['formType'];
    const [groups, setGroups] = useState([]);

    const [applications, setApplications] = useState([]);
    const [applicationsBackup, setApplicationsBackup] = useState([]);

    const platformObj = { android: { label: 'Android', icon: android1 }, ios: { label: 'Apple', icon: appleLogo } };

    const [selectedTab, setSelectedTab] = useState({ threats: '', access: '' });
    const appVersionSchema = useState([
        {
            label: 'Require App Version',
            inputType: 'radios',
            value: 'requireAppVersion',
            helpText: 'Display name of the profile - will be shown on the device',
            radios: [
                { label: 'Require', val: REQUIRED },
                { label: 'Not Required', val: NOT_REQUIRED }
            ]
        },
        {
            label: 'Apps',
            inputType: 'selectAndCards',
            value: 'selectedApps',
            customCol: true,
            helpText: 'Display name of the profile - will be shown on the device',
            options: applications,
            showOn: 'requireAppVersion',
            showValue: REQUIRED
        }
    ]);

    useEffect(() => {
        setLoading(true);
        handlePromise();
    }, []);

    const handlePromise = () => {
        const appsPromise = new Promise((resolve, reject) => {
            api.get(
                formTypeAndId.platform === 'ios' ? url.APPLE_APP_PROTECTION + '/apps' : url.APP_PROTECTION_APPS,
                '',
                domains.MDM_COMMONS
            )
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp.data) resolve(resp.data);
                    else reject('Apps failed');
                })
                .catch((err) => reject(err));
        });

        const groupsPromise = new Promise((resolve, reject) => {
            api.get(url.APP_PROTECTION_GROUPS, '', domains.MDM_COMMONS)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp?.data) resolve(resp.data);
                    else reject('Groups failed.');
                })
                .catch((err) => reject(err));
        });

        Promise.allSettled([appsPromise, groupsPromise])
            .then(async (result) => {
                if (result[0].status === 'fulfilled') {
                    result[0].value = result[0].value?.filter((app) =>
                        formTypeAndId.platform === 'android' ? app.packageId : formTypeAndId.platform === 'ios' ? app.bundleId : ''
                    );
                    setApplications(result[0].value);
                    setApplicationsBackup(result[0].value);
                }
                if (result[1].status === 'fulfilled') {
                    result[1].value.forEach((group) => {
                        group.members = group.members || '—';
                    });
                    setGroups(result[1].value);
                }
                setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: { ...formValues },
        onSubmit: (values) => {}
    });

    const handleChange = (option) => {
        const arr = [...appsArray];
        let arrBackup = JSON.parse(JSON.stringify(applicationsBackup));
        let ind = arrBackup.findIndex((app) => (option.packageId ? app.packageId === option.packageId : app.bundleId === option.bundleId));
        arrBackup.splice(ind, 1);
        setApplicationsBackup(arrBackup);
        arr.push(option);
        setAppsArray([...arr]);
    };

    const deleteApp = (index) => {
        let arr = [...appsArray];
        let arrBackup = JSON.parse(JSON.stringify(applicationsBackup));
        arrBackup.push(arr[index]);
        setApplicationsBackup(arrBackup);
        arr.splice(index, 1);
        setAppsArray([...arr]);
    };

    const setCheckedGroup = (groupsObj, group) => {
        groupsObj.children?.forEach((groupVal) => {
            if (groupVal.code === group.code) groupVal.checked = !!groupVal.checked;
            if (groupVal.children) groupVal = setCheckedGroup(groupVal, group);
        });
        return groupsObj;
    };

    const handleAddApp = (app, field) => {
        let vals = validation.values[field.value];
        vals?.push(app);
    };

    const handleVersionSelectChange = (version, app, valInd, valKey, key) => {
        app[key] = version;
        // eslint-disable-next-line no-unsafe-optional-chaining
        let vals = [...validation.values?.[valKey]];
        vals[valInd] = app;
        validation.setValues({ ...validation.values, [valKey]: vals });
    };

    const handleKeyDown = (e) => {
        if (e.keyCode === 13) e.preventDefault();
    };

    const handleMultiplecheck = (field) => {
        let flag = true;
        field.multipleCheck?.forEach((check) => {
            flag =
                flag && field.showKey
                    ? validation.values[check][field.showKey]
                        ? validation.values[check]?.[field.showKey] === field.showValue
                        : validation.values[check] === field.radVal
                    : false;
        });
        return flag;
    };

    const handleDataProtectionContent = (tabID, schema) => {
        return (
            <Row className="my-2">
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                    {schema[tabID]?.length > 0 ? (
                        schema[tabID]?.map(
                            (field, index) =>
                                (field.multipleCheck
                                    ? handleMultiplecheck(field)
                                    : field.showOn
                                    ? field.showKey
                                        ? validation.values[field.showOn]?.[field.showKey] === field.showValue
                                        : validation.values[field.showOn] === field.showValue
                                    : true) && (
                                    <DynamicComponents
                                        handleKeyDown={handleKeyDown}
                                        handleAddApp={handleAddApp}
                                        handleVersionSelectChange={handleVersionSelectChange}
                                        key={index}
                                        field={field}
                                        validation={validation}
                                    />
                                )
                        )
                    ) : (
                        <React.Fragment>
                            {appVersionSchema?.map(
                                (versionObj, ind) =>
                                    (versionObj.showOn ? validation.values[versionObj.showOn] === versionObj.showValue : true) && (
                                        <DynamicComponents
                                            key={ind}
                                            field={versionObj}
                                            index={ind}
                                            handleKeyDown={handleKeyDown}
                                            validation={validation}
                                            handleAddApp={handleAddApp}
                                            handleVersionSelectChange={handleVersionSelectChange}
                                        />
                                    )
                            )}
                        </React.Fragment>
                    )}
                </Col>
            </Row>
        );
    };

    const threatsList = [
        {
            tabID: 'dataTransfer',
            tabName: 'Data Transfer',
            tabContent: handleDataProtectionContent('dataTransfer', dataProtectionSchema[formTypeAndId.platform])
        },
        // { tabID: 'appVersion', tabName: 'App Version', tabContent: handleDataProtectionContent('appVersion', dataProtectionSchema[formTypeAndId.platform]) },
        {
            tabID: 'compliance',
            tabName: 'Compliance',
            tabContent: handleDataProtectionContent('compliance', dataProtectionSchema[formTypeAndId.platform])
        },
        {
            tabID: 'encryption',
            tabName: 'Encryption',
            tabContent: handleDataProtectionContent('encryption', dataProtectionSchema[formTypeAndId.platform])
        },
        {
            tabID: 'network',
            tabName: 'Network',
            tabContent: handleDataProtectionContent('network', dataProtectionSchema[formTypeAndId.platform])
        },
        {
            tabID: 'userExperience',
            tabName: 'User Experience',
            tabContent: handleDataProtectionContent('userExperience', dataProtectionSchema[formTypeAndId.platform])
        },
        {
            tabID: 'functionality',
            tabName: 'Functionality',
            tabContent: handleDataProtectionContent('functionality', dataProtectionSchema[formTypeAndId.platform])
        }
    ];

    const accessList = [
        {
            tabID: 'pinSettings',
            tabName: 'PIN Settings',
            tabContent: handleDataProtectionContent('pinSettings', ACCESS_REQUIREMENT_SCHEMA[formTypeAndId.platform])
        }
        // { tabID: 'MFA', tabName: 'MFA', tabContent: handleDataProtectionContent('mfa', ACCESS_REQUIREMENT_SCHEMA) }
    ];

    const conditionalLaunchList = [
        {
            tabID: 'app',
            tabName: 'App Conditions',
            tabContent: handleDataProtectionContent('app', CONDITIONAL_LAUNCH[formTypeAndId.platform])
        },
        {
            tabID: 'device',
            tabName: 'Device Conditions',
            tabContent: handleDataProtectionContent('device', CONDITIONAL_LAUNCH[formTypeAndId.platform])
        }
    ];

    const checkRegex = (val) => {
        const regex = exp;
        return !regex.test(val);
    };

    const handleValidation = () => {
        let flag = [];
        let errors = {};
        (formTypeAndId.platform === 'ios'
            ? [
                  'minAppVersionInput',
                  'minAppVersionInputWarn',
                  'minSDKVersionInputBlock',
                  'minSDKVersionInputWipe',
                  'mxOsVersionInputWipe',
                  'mxOsVersionInputWarn',
                  'mxOsVersionInputreq',
                  'minOsVersionInputWarn',
                  'minOsVersionInputWipe',
                  'minOsVersionInputReq'
              ]
            : [
                  'minOSVersionInput',
                  'minOSVersionInputWarn',
                  'minAppVersionInput',
                  'minAppVersionInputWarn',
                  'minAppVersionInput',
                  'minAppVersionInputWarn',
                  'minAppVersionInputWipe',
                  'minSDKVersionInputBlock',
                  'minSDKVersionInputWarn',
                  'minSDKVersionInputWipe',
                  'mxOsVersionInputWipe',
                  'mxOsVersionInputWarn',
                  'mxOsVersionInputreq',
                  'minOsVersionInputWarn',
                  'minOsVersionInputWipe',
                  'minOsVersionInputReq'
              ]
        )?.map((field) => {
            if (validation.values[field]) {
                let check = checkRegex(validation.values[field]);
                if (check) errors[field] = 'Invalid Version Number';
                flag.push(check);
            }
        });
        validation.setErrors(errors);
        return flag.includes(true);
    };

    const handleSave = () => {
        setLoading(true);
        let flag = handleValidation();
        if (flag) {
            setLoading(false);
            return;
        }
        const msApps = applications?.filter((app) => app.publisher === 'Microsoft Corporation');
        const apps = (
            validation.values.appsType === 'allApps'
                ? applications
                : validation.values.appsType === 'microsoftApps'
                ? msApps?.length > 0
                    ? msApps
                    : []
                : appsArray
        )?.map((app) => {
            return formTypeAndId.platform === 'android' ? { packageId: app.packageId } : { bundleId: app.bundleId };
        });
        let assignedGroups = groups
            ?.filter((group) => group.checked)
            ?.map((group) => {
                return { target: { groupId: group.groupId } };
            });
        let apiURL;
        let obj;
        switch (formTypeAndId.platform) {
            case 'android':
                obj = {
                    name: validation.values.name,
                    description: validation.values.descriptiohn,
                    androidConfig: {
                        pinRequired: validation.values.pinRequired === REQUIRED,
                        minimumPinLength:
                            validation.values.pinRequired === REQUIRED ? validation.values.minimumPinLength?.value : undefined,
                        pinCharacterSet: validation.values.pinRequired === REQUIRED ? validation.values.pinCharacterSet : undefined,
                        simplePinBlocked:
                            validation.values.pinRequired === REQUIRED ? validation.values.simplePinBlocked === RESTRICT : undefined,
                        periodBeforePinReset:
                            validation.values.pinRequired === REQUIRED
                                ? validation.values.periodBeforePinReset
                                    ? `P${validation.values.periodBeforePinReset}D`
                                    : undefined
                                : undefined,
                        maximumPinRetries: validation.values.pinRequired === REQUIRED ? validation.values.maximumPinRetries : undefined,
                        disableAppPinIfDevicePinIsSet:
                            validation.values.pinRequired === REQUIRED
                                ? validation.values.disableAppPinIfDevicePinIsSet === NOT_REQUIRED
                                : undefined,
                        organizationalCredentialsRequired: validation.values.organizationalCredentialsRequired === REQUIRED,
                        dataBackupBlocked: validation.values.dataBackupBlocked === RESTRICT,
                        deviceComplianceRequired: validation.values.deviceComplianceRequired === REQUIRED,
                        managedBrowserToOpenLinksRequired: validation.values.managedBrowser !== 'notConfigured',
                        saveAsBlocked: validation.values.saveAsBlocked === RESTRICT,
                        contactSyncBlocked: validation.values.contactSyncBlocked === RESTRICT,
                        printBlocked: validation.values.printBlocked === RESTRICT,
                        fingerprintBlocked: validation.values.fingerprintBlocked === RESTRICT,
                        periodOfflineBeforeAccessCheck: validation.values.offlineInput ? `PT${validation.values.offlineInput}M` : undefined,
                        periodOfflineBeforeWipeIsEnforced: validation.values.offlineInputWipe
                            ? `P${validation.values.offlineInputWipe}D`
                            : undefined,
                        /*
                         * periodOnlineBeforeAccessCheck: 'PT60M',
                         * [validation.values.maxAttemptsDropdown?.key]: `P${validation.values.pinMaxRetries}D`,
                         */
                        screenCaptureBlocked: validation.values.screenCaptureBlocked === RESTRICT,
                        disableAppEncryptionIfDeviceEncryptionIsEnabled:
                            validation.values.disableAppEncryptionIfDeviceEncryptionIsEnabled === RESTRICT,
                        encryptAppData: validation.values.encryptAppData === REQUIRED,
                        allowedInboundDataTransferSources: validation.values.allowedInboundDataTransferSources?.value,
                        allowedOutboundDataTransferDestinations: validation.values.allowedOutboundDataTransferDestinations?.value,
                        allowedOutboundClipboardSharingLevel: validation.values.allowedOutboundClipboardSharingLevel?.value,
                        managedBrowser:
                            validation.values.managedBrowser === 'noConfig' ? 'notConfigured' : validation.values.managedBrowser,
                        minimumRequiredOsVersion: validation.values.minOSVersionInput,
                        minimumWarningOsVersion: validation.values.minOSVersionInputWarn,
                        // minimumWarningOsVersion: '9.0',
                        minimumRequiredAppVersion: validation.values.minAppVersionInput,
                        minimumWarningAppVersion: validation.values.minAppVersionInputWarn,
                        // minimumWarningAppVersion: '7.5',
                        minimumRequiredPatchVersion: validation.values.minPatchVersionInput,
                        minimumWarningPatchVersion: validation.values.minPatchVersionInputWarn,
                        allowedDataStorageLocations:
                            validation.values.saveAsBlocked === RESTRICT &&
                            validation.values?.allowedOutboundDataTransferDestinations?.value === 'managedApps' &&
                            validation.values?.allowedDataStorageLocations?.length > 0
                                ? validation.values.allowedDataStorageLocations?.map((location) => location.value)
                                : [],
                        // minimumWarningPatchVersion: '2018-01-15',
                        customBrowserPackageId:
                            validation.values.managedBrowser === 'noConfig' && validation.values.customBrowserPackageId
                                ? validation.values.customBrowserPackageId
                                : undefined,
                        customBrowserDisplayName:
                            validation.values.managedBrowser === 'noCOnfig' && validation.values.customBrowserDisplayName
                                ? validation.values.customBrowserDisplayName
                                : undefined
                    },
                    apps: apps,
                    assignments: assignedGroups
                };
                apiURL = url.MS_APP_PROTECTION;
                break;
            case 'ios':
                obj = {
                    name: validation.values?.name,
                    apps: apps,
                    assignments: assignedGroups,
                    iosConfig: {
                        periodOfflineBeforeAccessCheck: validation.values.offlineInput ? `PT${validation.values.offlineInput}M` : undefined,
                        periodOfflineBeforeWipeIsEnforced: validation.values.offlineInputWipe
                            ? `P${validation.values.offlineInputWipe}D`
                            : undefined,
                        allowedInboundDataTransferSources: validation.values.allowedInboundDataTransferSources?.value,
                        allowedOutboundDataTransferDestinations: validation.values.allowedOutboundDataTransferDestinations?.value,
                        organizationalCredentialsRequired: validation.values.organizationalCredentialsRequired === ALLOW,
                        allowedOutboundClipboardSharingLevel: validation.values.allowedOutboundClipboardSharingLevel?.value,
                        dataBackupBlocked: validation.values.dataBackupBlocked === RESTRICT,
                        deviceComplianceRequired: validation.values.deviceComplianceRequired === REQUIRED,
                        saveAsBlocked: validation.values.saveAsBlocked === RESTRICT,
                        pinRequired: validation.values.pinRequired === REQUIRED,
                        periodOnlineBeforeAccessCheck: 'PT60M',
                        maximumPinRetries: 1,
                        gracePeriodToBlockAppsDuringOffClockHours: 'P1D',
                        allowWidgetContentSync: false,
                        /*
                         * maximumPinRetries: validation.values.maximumPinRetries,
                         * appActionIfMaximumPinRetriesExceeded: validation.values.appActionIfMaximumPinRetriesExceeded?.value,
                         */
                        simplePinBlocked: validation.values.simplePinBlocked === RESTRICT,
                        pinCharacterSet: validation.values.pinCharacterSet,
                        periodBeforePinReset: validation.values.periodBeforePinReset
                            ? `P${validation.values.periodBeforePinReset}D`
                            : undefined,
                        allowedDataStorageLocations:
                            validation.values.dataBackupBlocked === RESTRICT &&
                            validation.values?.allowedOutboundDataTransferDestinations?.value === 'managedApps' &&
                            validation.values?.allowedDataStorageLocations?.length > 0
                                ? validation.values.allowedDataStorageLocations?.map((location) => location.value)
                                : [],
                        contactSyncBlocked: validation.values.contactSyncBlocked === RESTRICT,
                        printBlocked: validation.values.printBlocked === RESTRICT,
                        fingerprintBlocked: validation.values.fingerprintBlocked === RESTRICT,
                        disableAppPinIfDevicePinIsSet: validation.values.disableAppPinIfDevicePinIsSet === NOT_REQUIRED,
                        maximumWipeOsVersion: validation.values.mxOsVersionInputWipe,
                        maximumWarningOsVersion: validation.values.mxOsVersionInputWarn,
                        maximumRequiredOsVersion: validation.values.mxOsVersionInputreq,
                        minimumWarningOsVersion: validation.values.minOsVersionInputWarn,
                        minimumWipeOsVersion: validation.values.minOsVersionInputWipe,
                        minimumRequiredOsVersion: validation.values.minOsVersionInputReq,
                        minimumRequiredAppVersion: validation.values.minAppVersionInput,
                        minimumWarningAppVersion: validation.values.minAppVersionInputWarn,
                        minimumWipeAppVersion: validation.values.minAppVersionInputWipe,
                        notificationRestriction: validation.values.notificationRestriction?.value,
                        managedBrowser: validation.values.managedBrowser !== 'custom' ? validation.values.managedBrowser : undefined,
                        managedBrowserToOpenLinksRequired: false,
                        isAssigned: assignedGroups?.length > 0,
                        // pinRequiredInsteadOfBiometricTimeout: `PT${validation.values.pinRequiredInsteadOfBiometricTimeout}M`,
                        appDataEncryptionType: validation.values.appDataEncryptionType,
                        minimumRequiredSdkVersion: validation.values.minSDKVersionInputBlock,
                        // minimumWarningSdkVersion: validation.values.minSDKVersionInputWarn,
                        minimumWipeSdkVersion: validation.values.minSDKVersionInputWipe,
                        faceIdBlocked: validation.values.faceIdBlocked === RESTRICT,
                        thirdPartyKeyboardsBlocked: validation.values.thirdPartyKeyboardsBlocked === RESTRICT,
                        appActionIfUnableToAuthenticateUser: validation.values.disabledAccountDropdown?.value
                        /*
                         * exemptedAppProtocols: validation.values.exemptedAppProtocols,
                         * exemptedUniversalLinks: validation.values.exemptedUniversalLinks,
                         * managedUniversalLinks: validation.values.managedUniversalLinks
                         */
                    }
                };
                apiURL = url.APPLE_APP_PROTECTION;
                break;
            default:
                break;
        }

        api.create(apiURL, obj, false, domains.MDM_COMMONS)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    toast.success(toastMessages.appProtecitonCreated);
                    history.push(`/appprotectionpolicy/${formTypeAndId.domain}`);
                }
                setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    const handleCheckboxChange = (e, ind, row) => {
        let obj = row.original;
        obj.checked = e.target.checked;
        let groupArr = JSON.parse(JSON.stringify(groups));
        // groupArr.splice(ind, 1, obj);
        setGroups(groupArr);
    };

    const handleCheckAll = (e) => {
        let arr = JSON.parse(JSON.stringify(groups));
        arr.forEach((group) => (group.checked = !!e.target.checked));
        setGroups(arr);
    };

    const stepperData = [
        {
            title: 'Applications',
            icon: 1,
            disabled: false,
            renderTab: (
                <Row>
                    <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                        <div className="fw-medium mb-1">Applications :</div>
                        <div className="fs-10 mb-4">Choose the application for which app protection policies are enforced.</div>
                        <DynamicComponents field={radioField} validation={validation} />
                        {validation.values.appsType === 'specificApps' && (
                            <div>
                                <div className="fs-13 fw-medium my-1">Specific App</div>
                                <div className="fs-11 text-muted mb-1	">App protection policies are enforced on selected applications</div>
                                <Select
                                    getOptionValue={(option) => (option.packageId ? option.packageId : option.bundleId)}
                                    getOptionLabel={(option) => option.name}
                                    options={sortBy(applicationsBackup, 'name')}
                                    placeholder="Select"
                                    onChange={(selectedOption) => {
                                        handleChange(selectedOption);
                                    }}
                                    value=""
                                    isSearchable={true}
                                    noOptionsMessage={() => 'No data found'}
                                />
                                <Row className="mt-3">
                                    {appsArray?.length > 0 &&
                                        appsArray.map((app, ind) => (
                                            <Col key={ind} sm={3}>
                                                <Card className="card-height-100 shadow-card mb-4 border-top">
                                                    <CardBody className="position-relative text-center">
                                                        <div
                                                            className="position-absolute top-0 end-0 cursor-pointer"
                                                            onClick={() => deleteApp(ind)}
                                                        >
                                                            <i className="ri-close-line fs-14" />
                                                        </div>
                                                        {/* <img src={''} alt="img" height={40} /> */}
                                                        <div className="pt-1 fw-semibold" id={`app-${ind}`}>
                                                            <EllipsisToolTip options={{ effect: 'solid', place: 'bottom' }}>
                                                                {app.name}
                                                            </EllipsisToolTip>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        ))}
                                </Row>
                            </div>
                        )}
                        {(validation.values.appsType === 'allApps' || validation.values.appsType === 'microsoftApps') && (
                            <div className="bg-soft-info py-2 px-4 rounded-3 fs-11">
                                <span className="pe-1 fs-13 fw-medium">Note :</span>
                                {validation.values.appsType === 'allApps'
                                    ? 'App protection policies are enforced on micorsoft and microsoft partnered apps'
                                    : 'App protection policies are enforced on microsoft apps'}
                            </div>
                        )}
                    </Col>
                </Row>
            )
        },
        {
            title: 'Data Protection',
            icon: 2,
            disabled: false,
            renderTab: (
                <div>
                    <div className="fw-medium">Data Protection :</div>
                    <div className="fs-11 mb-3">
                        Data protection policies ensures that sensitive information remains secure by enforcing specific security measures
                        based on various conditions.
                    </div>
                    <Row>
                        <TabsComponent
                            tabsList={threatsList?.filter(
                                (listItem) => dataProtectionSchema[formTypeAndId.platform]?.[listItem.tabID]?.length > 0
                            )}
                            defaultTabID={'dataTransfer'}
                            noPadding={true}
                            toggleTab={(tab) => setSelectedTab({ ...selectedTab, threats: tab })}
                        />
                    </Row>
                </div>
            )
        },
        {
            title: 'Access Requirements',
            icon: 3,
            disabled: false,
            renderTab: (
                <div>
                    <div className="fw-medium">Access Requirements :</div>
                    <div className="fs-11 mb-3">
                        Access requirements define the criteria that must be met for users to gain access to applications, ensuring security
                        based on various conditions.
                    </div>
                    <Row>
                        <TabsComponent
                            tabsList={accessList}
                            defaultTabID={'pinSettings'}
                            noPadding={true}
                            toggleTab={(tab) => setSelectedTab({ ...selectedTab, access: tab })}
                        />
                    </Row>
                </div>
            )
        },
        {
            title: 'Conditional Launch',
            icon: 4,
            disabled: false,
            renderTab: (
                <div>
                    <div className="fw-medium">Conditional Launch :</div>
                    <div className="fs-11 mb-3">
                        Conditional Launch enables you to establish criteria that must be met for an app to be accessed, ensuring that only
                        devices meeting your security standards can reach corporate data. You can also specify actions to be taken if a
                        device fails to meet these conditions.
                    </div>
                    <Row>
                        <TabsComponent
                            tabsList={conditionalLaunchList}
                            defaultTabID={'app'}
                            noPadding={true}
                            toggleTab={(tab) => setSelectedTab({ ...selectedTab, access: tab })}
                        />
                    </Row>
                </div>
            )
        },
        {
            title: 'Assignment',
            icon: 5,
            disabled: false,
            renderTab: (
                <div>
                    <div className="fw-medium">Assignment :</div>
                    <div className="fs-11 mb-3">
                        After configuring the policy settings, assign the policy to user groups. You can either select specific groups or
                        apply the policy to all users.
                    </div>
                    <div className="h-100 ">
                        <div className="mb-3 mb-lg-0 d-flex flex-column gap-4">
                            <div className="mb-3 mb-lg-0 w-100 shadow-card">
                                <div className="padding-8 fs-14 border-radius-top-left-4 border-radius-top-right-4">
                                    <div className="d-flex align-items-center fw-medium fs-12 justify-content-between">
                                        <div className="d-flex align-items-center ">
                                            <i className="ri-database-2-fill me-2" />
                                            <span>All Groups </span>({groups.length})
                                        </div>
                                    </div>
                                </div>
                                <div className="height-400 border-radius-bottom-left-4 border-radius-bottom-right-4 p-3 overflow-auto">
                                    {groups?.length > 0 ? (
                                        <TableContainer
                                            loading={loading}
                                            columns={groupsSchema}
                                            data={groups}
                                            hideCheckBoxTooltip={true}
                                            handleCheckAll={handleCheckAll}
                                            handleCheckboxChange={handleCheckboxChange}
                                            searchFilter2={true}
                                            isGlobalFilter={true}
                                            className="custom-header-css"
                                            divClass="table-responsive table-card"
                                            tableClass="table table-nowrap table-border table-centered align-middle"
                                            theadClass="bg-light text-muted"
                                        />
                                    ) : (
                                        <NoRecordsFound />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    ];
    const handleMandatoryFields = () => {
        let flag = [];
        [
            'minAppVersionInput',
            'minAppVersionInputWarn',
            'minAppVersionInputWipe',
            'minSDKVersionInputBlock',
            'minSDKVersionInputWipe',
            'mxOsVersionInputWipe',
            'mxOsVersionInputWarn',
            'mxOsVersionInputreq',
            'minOsVersionInputWarn',
            'minOsVersionInputWipe',
            'minOsVersionInputReq',
            'offlineInput',
            'offlineInputWipe'
        ]?.forEach((field) => {
            if (!validation.values?.[field]) flag.push(true);
        });
        return flag.includes(true);
    };
    const handleDisableSave = () => {
        if (formTypeAndId.platform === 'android') {
            let flag = [];
            flag.push(!validation.values?.name);
            if (validation.values?.pinRequired === REQUIRED) {
                flag.push(
                    validation.values?.pinCharacterSet === '' ||
                        validation.values?.fingerprintBlocked === '' ||
                        validation.values?.simplePinBlocked === '' ||
                        !validation.values?.maximumPinRetries ||
                        validation.values?.minimumPinLength === '' ||
                        !validation.values?.periodBeforePinReset ||
                        validation.values?.disableAppPinIfDevicePinIsSet === ''
                );
            }
            if (validation.values?.managedBrowser === '2')
                flag.push(!validation.values?.customBrowserPackageId || !validation.values?.customBrowserDisplayName);
            if (!validation.values.offlineInput || !validation.values.offlineInputWipe) flag.push(true);
            if (validation.values.offlineInput && validation.values.offlineInput > 43799) flag.push(true);
            if (validation.values.appsType === 'specificApps' && appsArray?.length === 0) flag.push(true);

            flag.push(handleValidation());
            if (flag.includes(true)) return true;
            else return false;
        } else {
            let flag = [];
            flag.push(!validation.values?.name);
            if (validation.values?.pinRequired === REQUIRED) {
                flag.push(
                    validation.values?.pinCharacterSet === '' ||
                        validation.values?.simplePinBlocked === '' ||
                        // validation.values?.minimumPinLength === '' ||
                        validation.values?.fingerprintBlocked === '' ||
                        // validation.values?.overrideBiometrics === '' ||
                        validation.values?.pinRequiredInsteadOfBiometricTimeout === '' ||
                        // validation.values?.faceIdBlocked === '' ||
                        !validation.values?.periodBeforePinReset
                    // validation.values?.disableAppPinIfDevicePinIsSet === ''
                );
            }

            if (handleMandatoryFields()) flag.push(true);
            if (validation.values.offlineInput && validation.values.offlineInput > 43799) flag.push(true);
            if (validation.values.appsType === 'specificApps' && appsArray?.length === 0) flag.push(true);
            if (
                validation.values.managedBrowser === '2' &&
                (!validation.values.customBrowserPackageId || !validation.values.customBrowserDisplayName)
            )
                flag.push(true);

            flag.push(handleValidation());
            if (flag.includes(true)) return true;
            else return false;
        }
    };

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className={`page-content ${loading ? 'postion-relative mask' : ''}`}>
                <Container fluid>
                    <BreadCrumb
                        pageTitle="App Protection"
                        showBack={true}
                        backLink={`appprotectionpolicy/${formTypeAndId.domain}`}
                        history={history}
                        homeLink="Dashboard"
                    />
                    <Card>
                        <CardHeader>
                            <div className="fw-semibold fs-15 mb-1">
                                App Protection Policy (Microsoft)
                                <span className="badge-soft-success ms-1 p-1 rounded-3 fs-12">
                                    <img src={platformObj[formTypeAndId.platform].icon} alt="img" height={15} className="pe-1" />
                                    {platformObj[formTypeAndId.platform].label}
                                </span>
                            </div>
                            <div className="fs-13">
                                App Protection Policies are a set of security controls designed to manage how corporate data is accessed and
                                used within mobile applications. These policies safeguard sensitive information by enforcing PIN
                                requirements, data encryption, compliance checks and restricting data sharing between apps.
                            </div>
                            <div className="d-flex align-items-center mt-3">
                                Configuration Name
                                {formType !== 'view' ? <span className="red-color ps-1">*</span> : <span className="ps-1">:</span>}
                                {formType !== 'view' ? (
                                    <div className="w-30 ms-5">
                                        <Input
                                            name={'name'}
                                            id={'name'}
                                            className={'form-control'}
                                            placeholder={'Enter Configuration Name'}
                                            type={'text'}
                                            maxLength={'30'}
                                            validate={{ required: { value: true } }}
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values['name'] || ''}
                                            invalid={validation.touched['name'] && validation.errors['name'] ? true : false}
                                        />
                                    </div>
                                ) : validation?.values['name'] ? (
                                    <span className="ps-1">{validation?.values['name']}</span>
                                ) : (
                                    '–'
                                )}
                                {validation.touched['name'] && validation.errors['name'] ? (
                                    <p className="m-0 mt-2 text-danger">{validation.errors['name']}</p>
                                ) : null}
                            </div>
                        </CardHeader>
                        <CardBody>
                            <CustomStepperComponent
                                cancelBtn={true}
                                stepperData={stepperData}
                                routeText={`appprotectionpolicy/${formTypeAndId.domain}`}
                                enableSaveBtn={true}
                                handleSave={handleSave}
                                formType={formType}
                                handleDisableSave={handleDisableSave()}
                            />
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default AddAPP;
