import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, CardHeader, Row } from 'reactstrap';
import WNSImage from '../../../assets/images/windows_push.png';
import { useFormik } from 'formik';
import { WPNDynamicComponents, WPNSchema } from './WPNConstants';
import { APIClient } from '../../../helpers/api_helper';
import { useEnv } from '../../../envContext';
import * as domains from '../../../helpers/domain_helper';
import * as url from '../../../helpers/url_helper';

const WindowsPushNotifications = ({ setLoading }) => {
    const api = new APIClient();
    const urlconf = useEnv();
    const [wpnLinkingData, setWPNLinkingData] = useState([]);
    const [showInitialScreen, setShowInitialScreen] = useState(true);

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: { domain: '', authmode: '', wnsclientid: '', wnspfn: '', wnssecret: '' }
    });

    const onConfigureWNS = () => {
        setShowInitialScreen(false);
    };

    useEffect(() => {
        getWPNData();
    }, []);

    const getWPNData = () => {
        setLoading(true);
        api.get(url.WPN_CONFIG, {}, domains.WINDOWS_V1)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.data && resp.data?.length > 0) {
                    setWPNLinkingData(resp.data);
                    validation.setValues(resp.data[0]);
                }
            })
            .finally(() => setLoading(false));
    };

    return (
        <Card className="card-height-100">
            <CardHeader>
                <div className="d-flex justify-content-between align-items-center fs-13 fw-semibold">
                    <div className="fs-14 ">Windows Push Notification Service (WNS)</div>
                    <div
                        className={`d-flex justify-content-center align-items-center fs-12 ${
                            wpnLinkingData.length === 0 ? 'text-danger' : 'text-primary'
                        }`}
                    >
                        {wpnLinkingData.length === 0 ? (
                            <i className="ri-error-warning-fill fs-16 px-1 text-danger"></i>
                        ) : (
                            <i className="ri-checkbox-circle-fill fs-16 px-1 text-primary"></i>
                        )}
                        {wpnLinkingData.length === 0 ? 'Not Configured' : 'Configured'}
                    </div>
                </div>
                <div className="padding-top-10 font-size-12 fw-medium text-muted">
                    Windows Push Notification Service is an initial set-up that helps to establish a connection between device and Tectoro
                    EMM Solution. Once configured, it registers a persistent connection with the WNS cloud (Battery Sense and Data Sense
                    conditions permitting).
                </div>
            </CardHeader>
            {wpnLinkingData.length === 0 && showInitialScreen ? (
                <div>
                    <CardBody>
                        <div className=" text-center py-20">
                            <div>
                                <img height={65} src={WNSImage} alt="img"></img>
                            </div>
                            <div className="fw-medium fs-14 py-2">Windows Push Notification Service (WNS)</div>
                            <div className="font-size-12 text-start fw-medium text-muted px-2">
                                Windows Push Notification Service is an initial set-up that helps to establish a connection between device
                                and Tectoro EMM Solution. Once configured, it registers a persistent connection with the WNS cloud (Battery
                                Sense and Data Sense conditions permitting).
                            </div>
                            <div className="py-4">
                                <Button className="bg-primary px-15 py-3 border-none fw-medium fs-12" onClick={() => onConfigureWNS()}>
                                    Configure
                                </Button>
                            </div>
                        </div>
                    </CardBody>
                </div>
            ) : wpnLinkingData.length === 0 ? (
                <CardBody>
                    <Row className="m-0 px-0 pt-3">
                        {WPNSchema.map((field, index) => {
                            return (
                                <WPNDynamicComponents key={index} field={field} index={index} formType={'add'} validation={validation} />
                            );
                        })}
                    </Row>
                    <div className="d-flex justify-content-end align-items-center margin-top-10 px-3">
                        <Button
                            className="bg-light border-primary text-primary mx-3 fs-12 fw-semibold px-5"
                            onClick={() => {
                                setShowInitialScreen(true);
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            className="bg-primary border-none px-8 fs-12 fw-semibold"
                            disabled={
                                validation.values.domain === '' ||
                                validation.values.authmode === '' ||
                                validation.values.wnsclientid === '' ||
                                validation.values.wnspfn === '' ||
                                validation.values.wnssecret === ''
                            }
                        >
                            Save
                        </Button>
                    </div>
                </CardBody>
            ) : (
                <CardBody>
                    <Row className="m-0 px-0 pt-3">
                        {WPNSchema.map((field, index) => {
                            return (
                                <WPNDynamicComponents key={index} field={field} index={index} formType={'view'} validation={validation} />
                            );
                        })}
                    </Row>
                </CardBody>
            )}
        </Card>
    );
};

export default WindowsPushNotifications;
