import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Col, Container, FormFeedback, Input, Label, Row } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import DeleteModal from '../../../Components/Common/DeleteModal';
import Loader from '../../../Components/Common/Loader';
import OffcanvasModal from '../../../Components/Common/OffcanvasModal';
import TableContainer from '../../../Components/Common/TableContainer';
import {
    AuthUser,
    TenantConfig,
    convertUTCtoIST,
    noCaseSensitiveSortBy,
    splitAndConvertPascalCase,
    uniqBy
} from '../../../Components/Common/Util';
import { priviliges } from '../../../Components/constants/constants';
import shutdown from '../../../assets/images/devicePower.png';
import factoryReset from '../../../assets/images/factoryReset.png';
import lockDevice from '../../../assets/images/lockDevice.png';
import rebootDevice from '../../../assets/images/rebootDevice.png';
import toastMessages from '../../../common/messages/toastMessages';
import { useEnv } from '../../../envContext';
import { APIClient } from '../../../helpers/api_helper';
import * as domains from '../../../helpers/domain_helper';
import * as url from '../../../helpers/url_helper';
import { generateFile } from '../../AndroidEnterprise/Devices/AndroidEnterpriseDevicesFunc';
import { IOSDevicesSchema } from '../TableSchema';
import Filters from './Filters';
import { useFormik } from 'formik';
import Select from 'react-select';
import * as Yup from 'yup';

const IOSDevices = () => {
    document.title = 'iOS Devices';
    const history = useHistory();
    const api = new APIClient();
    const tenantConfig = TenantConfig();
    const urlconf = useEnv();

    let FILTEROBJ = {
        deviceStatusArr: [
            { label: 'New', value: 'NEW', checked: false },
            { label: 'Active', value: 'ACTIVE', checked: false },
            { label: 'Deleted', value: 'DELETED', checked: false }
        ],
        policies: [],
        groups: [],
        flatGroups: [],
        selectedFiltersKeys: []
    };

    const [loading, setLoading] = useState(false);
    const [devices, setDevices] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [searchParams, setSearchParams] = useState({ page: 1, size: 10 });
    const [checkAll, setCheckAll] = useState(false);
    const [devicesTableSchema, setDevicesTableSchema] = useState([...IOSDevicesSchema]);
    const [searchValue, setSearchValue] = useState('');
    const [showMoreFilters, setShowMoreFilters] = useState(false);
    const [selectedDevices, setSelectedDevices] = useState([]);
    const [showActionModal, setShowActionModal] = useState(false);
    const [actionParams, setActionParams] = useState({ row: '', type: '' });
    const [exportAllLabel, setExportAllLabel] = useState('Export All');
    const [exportTooltip, setExportTooltip] = useState('Export Devices in CSV format (up to 5 Lakhs).');
    const [elasticSearchObj, setElasticSearchObj] = useState(FILTEROBJ);
    const [elasticSearchObjBackup, setElasticSearchObjBackup] = useState(JSON.parse(JSON.stringify(elasticSearchObj)));
    const [flatGroups, setFlatGroups] = useState([]);

    const filterObj = { deviceStatusArr: 'status' };

    useEffect(() => {
        setLoading(true);
        let userString = AuthUser();
        let user = userString ? JSON.parse(userString) : '';
        let findPriv = !user?.data?.privileges?.includes(priviliges.IOSDEVICE_EDITOR);
        let schema = [...devicesTableSchema];
        if (findPriv) schema = devicesTableSchema.filter((_device, index) => index < devicesTableSchema.length - 1);
        schema.forEach((schemaObj) => {
            if (schemaObj.Header === 'Actions') {
                schemaObj.lock = !!tenantConfig?.IOS_DEVICE_ACTIONS_MONITORING?.includes('LOCK');
                schemaObj.reboot = !!tenantConfig?.IOS_DEVICE_ACTIONS_MONITORING?.includes('REBOOT');
                schemaObj.shutdown = !!tenantConfig?.IOS_DEVICE_ACTIONS_MONITORING?.includes('SHUTDOWN');
                schemaObj.factoryreset = !!tenantConfig?.IOS_DEVICE_ACTIONS_MONITORING?.includes('ERASE_DEVICE');
            }
        });
        const actions = schema[schema.length - 1];
        if (actions.Header === 'Actions' && !actions.lock && !actions.reboot && !actions.shutdown && !actions.factoryReset)
            schema = schema.filter((schemaObj) => schemaObj.Header !== 'Actions');
        setDevicesTableSchema(schema);
        handlePromise();
    }, []);

    const setChildGroups = (groupArr, groupsData) => {
        groupArr.forEach((group) => {
            group.label = group.name;
            group.key = group.code;
            group.checked = false;
            group.nameCode = group.name + ' - ' + group.code;
            groupsData.push(group);
            if (group.children) {
                let obj = setChildGroups(group.children, groupsData);
                group.children = obj.children;
                groupsData = [...obj.groupsData];
            }
        });
        return { children: groupArr, groupsData: groupsData };
    };

    const prepareGroups = async (tagArr) => {
        let groupsData = [];
        await tagArr.forEach((group) => {
            group.label = group.name;
            group.key = group.code;
            group.checked = false;
            group.nameCode = group.name + ' - ' + group.code;
            groupsData.push(group);
            if (group.children) {
                let obj = setChildGroups(group.children, groupsData);
                group.children = obj.children;
                groupsData = [...obj.groupsData];
            }
        });
        setFlatGroups(groupsData);
        tagArr = await noCaseSensitiveSortBy(tagArr, 'name');
        return tagArr;
    };

    const preparePolicy = (policiesArr) => {
        policiesArr?.forEach((data) => {
            data.checked = false;
            data.label = data.name ? data.name : data.code;
            data.nameCode = data.name ? data.name + (data.code ? ' - ' + data.code : '') : data.code;
            data.value = data.code;
            data.count = data.deviceCount;
        });
        return policiesArr;
    };

    const handlePromise = () => {
        const devicePromise = new Promise((resolve, reject) => {
            api.get(url.DEVICES, searchParams, domains.IOS_V1)
                .then((resp) => {
                    resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                    if (resp.status === 'success') resolve(resp);
                    reject('Devices Failed.');
                })
                .catch((err) => reject('Devices Failed.'));
        });
        const groupPromise = new Promise((resolve, reject) => {
            let params = { page: 1, size: 100 };
            if (tenantConfig.DEVICE_GROUPS)
                api.get(url.DEVICE_GROUPS, params, domains.IDM)
                    .then(async (resp) => {
                        resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                        if (resp?.data) resolve(resp.data);
                        else reject('Groups Failed');
                    })
                    .catch((_err) => {
                        reject('Groups Failed');
                    });
            else reject('Groups not enabeld');
        });
        const policyPromise = new Promise((resolve, reject) => {
            let params = { page: 1, size: 100 };
            if (tenantConfig.DEVICE_POLICY)
                api.get(url.POLICIES, params, domains.IOS_V1)
                    .then((resp) => {
                        resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                        if (resp?.data) resolve(resp.data);
                        else reject('Policy Failed');
                    })
                    .catch((_err) => reject('Policy Failed'));
            else reject('Policy not enabled');
        });
        Promise.allSettled([devicePromise, groupPromise, policyPromise])
            .then(async (result) => {
                let policiesArr = [];
                let flatGroupsData = [];
                if (result[0].status === 'fulfilled') {
                    handleDeviceBinding(result[0].value, result[2].status === 'fulfilled' ? result[2].value : []);
                }
                if (result[1].status === 'fulfilled') {
                    flatGroupsData = await prepareGroups(result[1]?.value);
                }
                if (result[2].status === 'fulfilled') {
                    policiesArr = preparePolicy(result[2].value);
                }
                let searchObj = {
                    ...elasticSearchObj,
                    groups: result[1].status === 'fulfilled' ? result[1]?.value : [],
                    policies: policiesArr,
                    flatGroups: flatGroupsData
                };
                setElasticSearchObj({ ...searchObj });
                setElasticSearchObjBackup({ ...searchObj });
                setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    const handleDeviceBinding = (devicesData, polArr) => {
        devicesData.data?.forEach((data) => {
            let polObj = polArr.find((pol) => pol.code === data.assignment?.policy);
            data.serial = data.refs?.find((ref) => ref.type === 'SERIAL')?.value;
            data.version = data.software?.osversion || '—';
            data.platform = data?.hardware?.devicetype || '—';
            data.policy = polObj?.name || '—';
            data.group = data.assignment?.group || '—';
            data.deviceStatus = data.enroll?.status || '—';
            data.checked = false;
            data.showActions = data.enroll.status === 'ACTIVE';
            data.hideFactoryReset = data.enroll?.status !== 'ACTIVE';
            data.badgeClass = data.enroll?.status === 'NEW' ? 'primary' : data.enroll?.status === 'ACTIVE' ? 'success' : 'danger';
            data.rowView = data.enroll?.status !== 'NEW';
            data.model = data?.hardware?.model || '—';
            data.osversion = data?.software?.osversion || '—';
            data.enrolldate = data?.enroll?.enrolldate ? convertUTCtoIST(data?.enroll?.enrolldate) : '—';
            data.enrollmenttype = data?.enroll?.enrollmenttype ? splitAndConvertPascalCase(data?.enroll?.enrollmenttype, '_') : '—';
            data.issupervised = data?.enroll?.issupervised ? 'Yes' : 'No';
            data.notsupervised = ['ipad', 'iphone']?.includes(polObj?.platform.toLowerCase()) ? data?.enroll?.issupervised : '';
        });

        setDevices(devicesData.data || []);
        setTotalRecords(devicesData.totalRecords);
    };

    const getDevices = (params) => {
        setLoading(true);
        setSearchParams(params);
        api.get(url.DEVICES, params, domains.IOS_V1)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    handleDeviceBinding(resp, []);
                }
                setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    const handleCheckboxChange = (e, ind, row) => {
        let rows = [...devices];
        rows[ind].checked = e.target.checked;
        if (e.target.checked) setSelectedDevices([...selectedDevices, row.original]);
        else {
            let selDevices = [...selectedDevices];
            selDevices = selDevices.filter((device) => device.serial !== row.original.serial);
            setSelectedDevices(selDevices);
        }
        setDevices(rows);
        let unselectedRows = rows.filter((rowObj) => !rowObj.checked);
        /*
         * let selectedRows = rows.filter((rowObj) => rowObj.checked);
         * if (selectedRows?.length > 0) {
         *     setExportAllLabel('Export');
         *     setExportTooltip(`Export Devices in CSV format (up to ${Number(tenantConfig.MAX_IOS_DEVICE)}).`);
         * } else {
         *     setExportAllLabel('Export All');
         *     setExportTooltip('Export Devices in CSV format (up to 5 Lakhs).');
         * }
         */
        if (unselectedRows?.length > 0) setCheckAll(false);
        else setCheckAll(true);
    };

    const handleCheckAll = (e) => {
        let rows = [...devices];
        rows.forEach((row) => (row.checked = e.target.checked));
        if (e.target.checked) {
            let selDevices = [...selectedDevices, ...rows];
            selDevices = uniqBy(selDevices, 'serial');
            setSelectedDevices(selDevices);
        } else setSelectedDevices([]);
        setCheckAll(!checkAll);
        setDevices(rows);
    };

    const onPageChange = (page) => {
        let params = { ...searchParams, page: page };
        getDevices(params);
    };

    const handleDeviceStatusFilter = (e, element) => {
        const elasticObj = JSON.parse(JSON.stringify(elasticSearchObjBackup));
        elasticObj?.deviceStatusArr?.forEach((status) => {
            if (status.value === element.value) status.checked = e.target.checked;
        });
        if (
            elasticObj?.deviceStatusArr?.some((status) => status.checked) &&
            !elasticObj?.selectedFiltersKeys?.some((keys) => (keys.value = 'deviceStatusArr'))
        )
            elasticObj.selectedFiltersKeys?.push({ label: 'Device Status', value: 'deviceStatusArr' });
        else if (!e.target.checked && elasticObj?.deviceStatusArr?.every((status) => !status.checked)) {
            elasticObj?.selectedFiltersKeys?.splice(
                elasticObj?.selectedFiltersKeys?.indexOf((keys) => keys.vaue === 'deviceStatusArr'),
                1
            );
        }
        setElasticSearchObjBackup({ ...elasticObj });
    };

    const handlePolicyFilter = (e, element) => {
        const elasticObj = JSON.parse(JSON.stringify(elasticSearchObjBackup));
        elasticObj?.policy?.forEach((status) => {
            if (status.value === element.value) status.checked = e.target.checked;
        });
        if (
            elasticObj?.policy?.some((status) => status.checked) &&
            !elasticObj?.selectedFiltersKeys?.some((keys) => (keys.value = 'policy'))
        )
            elasticObj.selectedFiltersKeys?.push({ label: 'Policy', value: 'policy' });
        else if (!e.target.checked && elasticObj?.policy?.every((status) => !status.checked)) {
            elasticObj?.selectedFiltersKeys?.splice(
                elasticObj?.selectedFiltersKeys?.indexOf((keys) => keys.vaue === 'policy'),
                1
            );
        }
        setElasticSearchObjBackup({ ...elasticObj });
    };

    const handleRemoveFilter = (ele) => {
        const elasticObj = JSON.parse(JSON.stringify(elasticSearchObjBackup));

        elasticObj?.selectedFiltersKeys?.splice(
            elasticObj?.selectedFiltersKeys?.indexOf((keys) => keys.vaue === ele.value),
            1
        );
        elasticObj?.[ele.value]?.forEach((val) => (val.checked = false));
        setElasticSearchObjBackup(elasticObj);
    };

    const handleOffcanvasBody = () => {
        return (
            <Filters
                tenantConfig={tenantConfig}
                elasticSearchObj={elasticSearchObjBackup}
                searchParams={searchParams}
                handleRemoveFilter={handleRemoveFilter}
                handleDeviceStatusFilter={handleDeviceStatusFilter}
                handlePolicyFilter={handlePolicyFilter}
            />
        );
    };

    const handleSubmitFilter = () => {
        setElasticSearchObj(elasticSearchObjBackup);
        let params = { ...searchParams };
        Object.entries(elasticSearchObjBackup)?.forEach((search) => {
            if (filterObj[search[0]]) {
                let arr = [];
                search[1]?.forEach((val) => {
                    if (val.checked) arr.push(val.value);
                });
                if (arr.length > 0) params[filterObj[search[0]]] = arr;
                else delete params[filterObj[search[0]]];
            }
        });
        /*
         * if (selectedDevices?.length > 0) {
         *     setExportAllLabel('Export');
         *     setExportTooltip(`Export Devices in CSV format (up to ${Number(tenantConfig.MAX_IOS_DEVICE)}).`);
         * } else {
         *     setExportAllLabel('Export All');
         *     setExportTooltip('Export Devices in CSV format (up to 5 Lakhs).');
         * }
         */
        setShowMoreFilters(false);
        getDevices(params);
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            pin: '',
            phoneNumber: ''
        },
        validationSchema: Yup.object({
            pin: Yup.string()
                .matches(/^\d{6}$/, 'Pin must be of 6 digits')
                .required('Pin is required'),
            phoneNumber: Yup.string().matches(/^\d{10}$/, 'Phone Number must be of 10 digits')
        }),
        onSubmit: (values) => {}
    });

    useEffect(() => {
        if (!showActionModal) {
            validation.resetForm();
        }
    }, [showActionModal]);

    const actions = {
        reboot: {
            info: 'Are you sure you want to reboot the device?',
            image: rebootDevice,
            actionBody: {
                command: 'RestartDevice',
                params: { RequestType: 'RestartDevice' }
            },
            toastMessage: toastMessages.rebootDevice
        },
        shutdown: {
            info: 'Are you sure you want to shutdown the device?',
            image: shutdown,
            actionBody: {
                command: 'ShutDownDevice',
                params: { RequestType: 'ShutDownDevice' }
            },
            toastMessage: toastMessages.shutdownDevice
        },
        lock: {
            info: 'Are you sure you want to lock the device?',
            image: lockDevice,
            actionBody: {
                command: 'DeviceLock',
                params:
                    actionParams?.row?.platform?.toLowerCase() === 'mac'
                        ? {
                              RequestType: 'DeviceLock',
                              Message: 'Locked by your TT Admin',
                              PIN: validation?.values?.pin?.toString(),
                              PhoneNumber: validation?.values?.phoneNumber?.toString()
                          }
                        : { RequestType: 'DeviceLock', Message: 'Locked by your TT Admin' }
            },
            toastMessage: toastMessages.lockDevice
        },
        erase: {
            info: 'Are you sure you want to erase the device data?',
            image: factoryReset,
            actionBody: {
                command: 'EraseDevice',
                params:
                    actionParams?.row?.platform?.toLowerCase() === 'mac'
                        ? {
                              RequestType: 'EraseDevice',
                              PIN: validation?.values?.pin?.toString(),
                              ObliterationBehavior: validation?.values?.obliterationBehavior?.value
                          }
                        : { RequestType: 'EraseDevice' }
            },
            toastMessage: toastMessages.eraseDevice
        }
    };

    const handleActionSubmit = () => {
        setLoading(true);
        const actionParamsObj = JSON.parse(JSON.stringify(actionParams));
        setActionParams({ row: '', type: '' });
        setShowActionModal(false);
        const body = { ...actions?.[actionParamsObj?.type]?.actionBody, device: actionParamsObj?.row?._id };
        api.create(url.IOS_COMMANDS, body, false, domains.IOS_V1)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    toast.success(actions?.[actionParamsObj?.type]?.toastMessage);
                    getDevices(searchParams);
                }
                setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    const handleAction = (row, type) => {
        setActionParams({ row: row.original, type: type });
        setShowActionModal(true);
    };

    const handleActionsModalBody = () => {
        return (
            <React.Fragment>
                <div className="mt-3">
                    <div className="mb-4">{actions?.[actionParams.type]?.info}</div>
                    <div className="mb-4 d-flex align-items-center justify-content-center">
                        <img src={actions?.[actionParams.type]?.image} alt="device" width={60} height={60} />
                    </div>
                    <div>
                        Serial: <span className="fw-semibold">{actionParams.row?.serial}</span>
                    </div>
                </div>
                {actionParams?.row?.platform?.toLowerCase() === 'mac' &&
                    (actionParams.type === 'lock' || actionParams.type === 'erase') && (
                        <InputComponent
                            field={{ label: 'Pin', value: 'pin', type: 'number', mandatory: true, placeholder: 'Enter The Pin' }}
                            validation={validation}
                        />
                    )}
                {actionParams?.row?.platform?.toLowerCase() === 'mac' && actionParams.type === 'lock' && (
                    <InputComponent
                        field={{ label: 'Phone Number', value: 'phoneNumber', type: 'number', placeholder: 'Enter Phone Number' }}
                        validation={validation}
                    />
                )}
                {actionParams?.row?.platform?.toLowerCase() === 'mac' && actionParams.type === 'erase' && (
                    <Row className="mt-2 flex-row align-items-center">
                        <Col xs={12} md={12} sm={5} lg={5} xl={5}>
                            <Label className="mb-0 fs-14 fw-medium d-flex jusify-content-start">ObliterationBehavior</Label>
                        </Col>
                        <Col xs={12} md={12} sm={7} lg={7} xl={7}>
                            <Select
                                getOptionValue={(option) => option.value}
                                getOptionLabel={(option) => option.label}
                                name="obliterationBehavior"
                                options={[
                                    { label: 'Default', value: 'default' },
                                    { label: 'DoNotObliterate', value: 'doNotObliterate' },
                                    { label: 'ObliterateWithWarning', value: 'obliterateWithWarning' },
                                    { label: 'Always', value: 'always' }
                                ]}
                                placeholder="Select"
                                onChange={(selectedOption) => {
                                    validation.handleChange({ target: { name: 'obliterationBehavior', value: selectedOption } });
                                }}
                                value={validation?.values['obliterationBehavior'] || ''}
                                isSearchable={true}
                                noOptionsMessage={() => 'No data found'}
                            />
                        </Col>
                    </Row>
                )}
            </React.Fragment>
        );
    };

    const handleSearch = (val) => {
        getDevices({ page: 1, size: 10, serial: val?.trim() });
        /*
         * if (selectedDevices?.length > 0) {
         *     setExportAllLabel('Export');
         *     setExportTooltip(`Export Devices in CSV format (up to ${Number(tenantConfig.MAX_IOS_DEVICE)}).`);
         * } else {
         *     setExportAllLabel('Export All');
         *     setExportTooltip('Export Devices in CSV format (up to 5 Lakhs).');
         * }
         */
    };

    const handleIsExportAll = () => {
        setLoading(true);
        // if (exportAllLabel === 'Export All' && selectedDevices?.length === 0)
        api.get(url.IOS_DEVICES_EXPORT, '', domains.IOS_V1)
            .then((resp) => {
                generateFile(resp);
                setLoading(false);
            })
            .catch((_err) => setLoading(false));
        // else handleIsExport(exportObj, api, tenantConfig);
    };

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className={`page-content h-100 ${loading ? 'postion-relative mask' : ''}`}>
                <Container fluid>
                    <BreadCrumb pageTitle={'Devices'} history={history} />
                    <div className="mt-4">
                        <Row className="m-0">
                            <TableContainer
                                loading={loading}
                                handleMap={(row) => history.push(`/iosdevices/viewmap/${row.original.id}`)}
                                uploadTooltip="Upload Devices"
                                doNotJustify={true}
                                value={searchValue}
                                moreFilters={false}
                                searchFilter2={true}
                                handleGlobalSearch={handleSearch}
                                handleMoreFilters={() => setShowMoreFilters(true)}
                                isGlobalFilter={true}
                                checkAll={checkAll}
                                data={devices}
                                handleClickView={(row) => history.push(`/iosdevices/view/${row.original.id}`)}
                                columns={devicesTableSchema}
                                totalRecords={totalRecords}
                                pageNum={searchParams.page}
                                reader={priviliges.DEVICE_READER}
                                editor={priviliges.DEVICE_EDITOR}
                                customPageSize={25}
                                handleCheckboxChange={handleCheckboxChange}
                                handleCheckAll={handleCheckAll}
                                onPageChange={onPageChange}
                                handleDeviceReboot={(row) => handleAction(row, 'reboot')}
                                handleDeviceShutdown={(row) => handleAction(row, 'shutdown')}
                                handleDeviceLock={(row) => handleAction(row, 'lock')}
                                handleFactoryReset={(row) => handleAction(row, 'erase')}
                                searchPlaceHolder="Serial"
                                className="custom-header-css"
                                divClass="table-responsive table-card"
                                tableClass="table table-nowrap table-border table-centered align-middle"
                                theadClass="bg-light text-muted"
                                exportTooltip={exportTooltip}
                                exportFile5l={true}
                                exportAllLabel={exportAllLabel}
                                showTooltip={false}
                                handleIsExportAll={handleIsExportAll}
                            />
                        </Row>
                    </div>
                    <OffcanvasModal
                        direction="end"
                        toggle={() => {
                            setShowMoreFilters(!showMoreFilters);
                            setElasticSearchObjBackup(elasticSearchObj);
                        }}
                        open={showMoreFilters}
                        hideSaveButton={false}
                        handleCloseClick={() => setShowMoreFilters(!showMoreFilters)}
                        OffcanvasModalID="announcementModal"
                        handleOffcanvasBody={handleOffcanvasBody}
                        modalClassName={'w-25'}
                        offcanvasHeader={'Filters'}
                        handleSaveClick={handleSubmitFilter}
                    />
                    <DeleteModal
                        hideIcon={true}
                        show={showActionModal}
                        hideDeleteIcon={true}
                        hideDeleteMessage={true}
                        message={handleActionsModalBody()}
                        confirmText="Submit"
                        onDeleteClick={handleActionSubmit}
                        disabled={
                            actionParams?.row?.platform?.toLowerCase() === 'mac' &&
                            (actionParams.type === 'lock' || actionParams.type === 'erase')
                                ? validation?.errors?.pin || !validation.values.pin
                                : false
                        }
                        onCloseClick={() => {
                            setActionParams({ row: '', type: '' });
                            setShowActionModal(false);
                        }}
                    />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default IOSDevices;

export const InputComponent = ({ field, validation }) => {
    return (
        <Row className="mt-2 flex-row align-items-center">
            <Col xs={12} md={12} sm={5} lg={5} xl={5}>
                <Label className={'mb-0 fs-14 fw-medium d-flex align-items-center mb-1'}>
                    {field?.label}
                    {field?.mandatory && <span className="red-color ps-1">*</span>}
                </Label>
            </Col>
            <Col xs={12} md={12} sm={7} lg={7} xl={7}>
                <div className="input-group">
                    <Input
                        name={field?.value}
                        id={field?.value}
                        className="form-control"
                        placeholder={field?.placeholder}
                        type={field?.type}
                        onChange={validation?.handleChange}
                        onBlur={validation?.handleBlur}
                        value={validation?.values[field?.value]}
                        invalid={validation?.errors?.[field?.value] && validation?.touched[field?.value] ? true : false}
                    />
                    {validation?.touched[field?.value] && validation?.errors[field?.value] && (
                        <FormFeedback type="invalid">{validation?.errors[field?.value]}</FormFeedback>
                    )}
                </div>
            </Col>
        </Row>
    );
};
