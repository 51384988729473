import { useFormik } from 'formik';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FilePond } from 'react-filepond';
import Flatpickr from 'react-flatpickr';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Card, CardBody, CardFooter, CardHeader, Col, Container, Input, Label, Row } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import Loader from '../../../Components/Common/Loader';
import { AuthUser, DownloadBunnyFile, Enterprise, fileValidation, getDate, getFormTypeAndRecordId } from '../../../Components/Common/Util';
import { fileTypeValidations } from '../../../Components/constants/constants';
import toastMessages from '../../../common/messages/toastMessages';
import { useEnv } from '../../../envContext';
import { APIClient } from '../../../helpers/api_helper';
import * as url from '../../../helpers/url_helper';
import AppInfoParser from 'app-info-parser';

const AddAndroidTvApp = () => {
    const urlconf = useEnv();
    let history = useHistory();
    const userObj = AuthUser();
    const user = userObj ? JSON.parse(userObj) : '';
    let api = new APIClient();
    const fp = useRef(null);

    const formTypeAndId = getFormTypeAndRecordId(window.location.hash);
    let formType = formTypeAndId['formType'];
    let recordID = formTypeAndId['recordID'];
    let enterprise = Enterprise();
    document.title = formType === 'edit' ? 'Edit TV App' : formType === 'add' ? 'Add TV App' : 'View TV App';
    let enterpriseObj = enterprise ? JSON.parse(enterprise) : {};

    const [loading, setLoading] = useState(false);
    const [apkFiles, setAPKFiles] = useState('');
    const [logo, setLogo] = useState('');
    const [formValues, setFormValues] = useState('');

    useEffect(() => {
        if (recordID) {
            setLoading(true);
            getAppById();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getAppById = () => {
        api.get(url.TV_APPS + '/' + recordID)
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp?.data) {
                    setFormValues({
                        packageID: resp?.data?.packageName,
                        appName: resp?.data?.appName,
                        version: resp?.data?.appVersion,
                        appVersionDate: getDate(resp?.data?.appVersionDate),
                        logo: resp?.data?.iconServerFileName,
                        logoUrl: resp?.data?.iconUrl,
                        apkFile: resp?.data?.appServerFileName,
                        appUrl: resp?.data?.appUrl
                    });
                }
                setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            logo: formValues?.logo ? formValues?.logo : '',
            apkFile: formValues?.apkFile ? formValues?.apkFile : '',
            packageID: formValues?.packageID ? formValues?.packageID : '',
            appName: formValues?.appName ? formValues?.appName : '',
            version: formValues?.version ? formValues?.version : '',
            appVersionDate: formValues?.appVersionDate ? formValues?.appVersionDate : '',
            appUrl: formValues?.appUrl ? formValues?.appUrl : '',
            logoUrl: formValues?.logoUrl ? formValues?.logoUrl : ''
        }
    });

    const handleDownload = (fileUrl, fileName, type) => {
        DownloadBunnyFile(fileUrl, fileName, setLoading);
    };

    const handleSubmit = () => {
        let fileSize = Number(apkFiles?.[0]?.fileSize) + Number(logo?.[0]?.fileSize);
        if (apkFiles[0]?.fileExtension !== 'apk') {
            toast.error('File Extension should be ' + fileTypeValidations['apps']?.join());
            return;
        }
        let logoObj = fileValidation('image', logo[0]?.fileExtension, fileTypeValidations);
        if (!logoObj.valid) {
            toast.error('File Extension should be ' + logoObj.message);
            return;
        }
        if (fileSize > 3000000000) {
            toast.error(toastMessages.filesSizeGT300);
            return;
        }
        setLoading(true);
        let date = new Date(validation.values.appVersionDate);
        let appVersionDate = date.getFullYear() + '-' + Number(date.getMonth()) + 1 + '-' + date.getDate();
        let formData = new FormData();
        formData.append('packageName', validation.values.packageID);
        formData.append('appName', validation.values.appName);
        formData.append('appVersion', validation.values.version);
        formData.append('apkFile', apkFiles?.[0]?.file);
        formData.append('iconFile', logo?.[0]?.file);
        formData.append('appVersionDate', appVersionDate);
        let apiService;
        if (formType === 'edit') apiService = api.update(url.TV_APPS + '/' + recordID, formData, true);
        else apiService = api.create(url.TV_APPS, formData, true);
        apiService
            .then((resp) => {
                resp = urlconf.REACT_APP_ENCRYPTION_ENABLED === 'true' ? JSON.parse(resp) : resp;
                if (resp.status === 'success') {
                    toast.success(formType === 'edit' ? toastMessages.androidTVAppUpdated : toastMessages.androidTvAppPublished);
                    history.push('/atvapps');
                }
                setLoading(false);
            })
            .catch((err) => setLoading(false));
    };

    const checkSaveDisable = () => {
        return (
            !validation.values.packageID ||
            validation.values.packageID === '' ||
            !validation.values.appName ||
            validation.values.appName === '' ||
            !validation.values.version ||
            validation.values.version === '' ||
            apkFiles?.length === 0 ||
            !apkFiles ||
            apkFiles === '' ||
            logo?.length === 0 ||
            !logo ||
            logo === ''
        );
    };

    const getImage = (icon) => {
        fetch(icon)
            .then((response) => response.blob())
            .then((blob) => {
                const file = new File([blob], 'image.png', { type: blob.type });
                setLogo([file]); // Pass the file to FilePond
            })
            .catch((error) => {});
    };

    const handleAPKFileUpload = useCallback(async (fileItems) => {
        if (fileItems?.length > 0) {
            const fileData = fileItems[0].file;
            const parser = new AppInfoParser(fileData);
            parser
                .parse()
                .then((result) => {
                    validation.setValues({
                        ...validation.values,
                        packageID: result.package || '',
                        appName: result?.application?.label?.[0] || '',
                        version: result?.versionCode || 1
                    });
                    if (!Array.isArray(result.icon)) getImage(result.icon);
                })
                .catch((err) => {});
        } else {
            setLogo('');
            validation.setValues({ ...validation.values, packageID: '', appName: '', version: '' });
        }
        setAPKFiles(fileItems);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleLogoUpload = (fileItems) => {
        setLogo(fileItems);
    };

    const handleVersionChange = (e) => {
        if (e.target.value?.includes('..')) return;
        let regex = new RegExp(/^[0-9.]+$/);
        let num = e.target.value;
        let valid = regex.test(num);
        if (valid) validation.setValues({ ...validation.values, version: num });
    };

    return (
        <React.Fragment>
            {loading && <Loader />}
            <div className={`page-content ${loading ? 'postion-relative mask' : ''}`}>
                <Container fluid>
                    <BreadCrumb
                        pageTitle={formType === 'view' ? 'View App' : formType === 'edit' ? 'Edit App' : 'Add App'}
                        history={history}
                        homeLink="Dashboard"
                        showBack={true}
                        backLink="atvapps"
                    />
                    <div className="h-100">
                        <Card className="card-height-100">
                            <CardHeader>
                                <Row className="d-flex gap-2 justify-content-center flex-column">
                                    <Col lg={6}>
                                        <Row className="d-flex align-items-center">
                                            <Col xs={4} md={4} sm={4} lg={4} xl={4}>
                                                <Label className="form-label d-flex align-items-center">Application Type :</Label>
                                            </Col>
                                            <Col lg={6} className="d-flex flex-column">
                                                <div className="input-group text-muted fw-medium">Custom App</div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Row className="d-flex gap-2 justify-content-center flex-column">
                                    <Col lg={6} className="d-flex gap-4 justify-content-center flex-column">
                                        <Row>
                                            <Col xs={4} md={4} sm={4} lg={4} xl={4} className="d-flex align-items-center">
                                                <Label className={'mb-0 fw-medium d-flex align-items-center'}>
                                                    File
                                                    {formType !== 'view' ? <span className="red-color ps-1">*</span> : ''}
                                                </Label>
                                            </Col>
                                            <Col className="filepondmarginremoval">
                                                {formType !== 'view' ? (
                                                    <FilePond
                                                        name="files"
                                                        minFileSize="5KB"
                                                        maxFileSize="300MB"
                                                        maxFiles={1}
                                                        allowMultiple={true}
                                                        files={apkFiles}
                                                        acceptedFileTypes={['application/vnd.android.package-archive']}
                                                        className="filepond filepond-input-multiple"
                                                        onupdatefiles={(fileItems) => handleAPKFileUpload(fileItems)}
                                                    />
                                                ) : validation.values?.appUrl ? (
                                                    <span
                                                        onClick={() =>
                                                            handleDownload(validation.values?.appUrl, validation.values?.apkFile, 'apk')
                                                        }
                                                        className="text-success text-decoration-underline cursor-pointer"
                                                    >
                                                        {validation.values?.apkFile}
                                                    </span>
                                                ) : (
                                                    '–'
                                                )}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={4} md={4} sm={4} lg={4} xl={4} className="d-flex align-items-center">
                                                <Label className={'mb-0 fw-medium d-flex align-items-center'}>
                                                    Logo
                                                    {formType !== 'view' ? <span className="red-color ps-1">*</span> : ''}
                                                </Label>
                                            </Col>
                                            <Col className='filepondmarginremoval"'>
                                                {formType !== 'view' ? (
                                                    <FilePond
                                                        name="files"
                                                        minFileSize="5KB"
                                                        maxFileSize="300MB"
                                                        maxFiles={1}
                                                        allowMultiple={true}
                                                        files={logo}
                                                        className="filepond filepond-input-multiple"
                                                        onupdatefiles={(fileItems) => handleLogoUpload(fileItems)}
                                                    />
                                                ) : validation.values?.logoUrl ? (
                                                    <span
                                                        onClick={() =>
                                                            handleDownload(validation.values?.logoUrl, validation.values?.logo, 'logo')
                                                        }
                                                        className="text-success text-decoration-underline cursor-pointer"
                                                    >
                                                        {validation.values?.logo}
                                                    </span>
                                                ) : (
                                                    '–'
                                                )}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={4} md={4} sm={4} lg={4} xl={4} className="d-flex align-items-center">
                                                <Label className={'mb-0 fw-medium d-flex align-items-center'}>
                                                    Package ID
                                                    {formType === 'add' ? <span className="red-color ps-1">*</span> : ''}
                                                </Label>
                                            </Col>
                                            <Col lg={6} className="d-flex flex-column">
                                                <div className="input-group text-muted fw-medium">
                                                    {formType === 'add' ? (
                                                        <Input
                                                            name={'packageID'}
                                                            id={'packageID'}
                                                            className="form-control"
                                                            placeholder={'Enter package ID'}
                                                            type={'text'}
                                                            validate={{ required: { value: true } }}
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values['packageID'] || ''}
                                                            invalid={
                                                                validation.touched['packageID'] && validation.errors['packageID']
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                    ) : validation?.values['packageID'] ? (
                                                        validation?.values['packageID']
                                                    ) : (
                                                        '–'
                                                    )}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={4} md={4} sm={4} lg={4} xl={4} className="d-flex align-items-center">
                                                <Label className={'mb-0 fw-medium d-flex align-items-center'}>
                                                    Application Name
                                                    {formType !== 'view' ? <span className="red-color ps-1">*</span> : ''}
                                                </Label>
                                            </Col>
                                            <Col lg={6} className="d-flex flex-column">
                                                <div className="input-group text-muted fw-medium">
                                                    {formType !== 'view' ? (
                                                        <Input
                                                            name={'appName'}
                                                            id={'appName'}
                                                            className="form-control"
                                                            placeholder={'Enter application name'}
                                                            type={'text'}
                                                            validate={{ required: { value: true } }}
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values['appName'] || ''}
                                                            invalid={
                                                                validation.touched['appName'] && validation.errors['appName'] ? true : false
                                                            }
                                                        />
                                                    ) : validation?.values['appName'] ? (
                                                        validation?.values['appName']
                                                    ) : (
                                                        '–'
                                                    )}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={4} md={4} sm={4} lg={4} xl={4} className="d-flex align-items-center">
                                                <Label className={'mb-0 fw-medium d-flex align-items-center'}>
                                                    Version
                                                    {formType !== 'view' ? <span className="red-color ps-1">*</span> : ''}
                                                </Label>
                                            </Col>
                                            <Col lg={6} className="d-flex flex-column">
                                                <div className="input-group text-muted fw-medium">
                                                    {formType !== 'view' ? (
                                                        <Input
                                                            name={'version'}
                                                            id={'version'}
                                                            className="form-control"
                                                            placeholder={'Enter version number'}
                                                            type={'text'}
                                                            validate={{ required: { value: true } }}
                                                            onChange={handleVersionChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values['version'] || ''}
                                                            invalid={
                                                                validation.touched['version'] && validation.errors['version'] ? true : false
                                                            }
                                                        />
                                                    ) : validation?.values['version'] ? (
                                                        validation?.values['version']
                                                    ) : (
                                                        '–'
                                                    )}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={4} md={4} sm={4} lg={4} xl={4} className="d-flex align-items-center">
                                                <Label className={'mb-0 fw-medium d-flex align-items-center'}>
                                                    App Version Date
                                                    {formType !== 'view' ? <span className="red-color ps-1">*</span> : ''}
                                                </Label>
                                            </Col>
                                            <Col lg={6} className="d-flex flex-column">
                                                <div className="input-group text-muted fw-medium">
                                                    {formType !== 'view' ? (
                                                        <div className="input-group flex-nowrap">
                                                            <Flatpickr
                                                                ref={fp}
                                                                value={validation.values.appVersionDate}
                                                                placeholder="Select Start Date"
                                                                className="form-control w-50 p-2"
                                                                options={{ mode: 'single', dateFormat: 'd M, Y', maxDate: 'today' }}
                                                                onChange={(e) => {
                                                                    validation.setValues({
                                                                        ...validation.values,
                                                                        appVersionDate: e[0]
                                                                    });
                                                                }}
                                                            />
                                                            <div className="input-group-text bg-primary border-primary text-white">
                                                                <i className="ri-calendar-2-line"></i>
                                                            </div>
                                                        </div>
                                                    ) : validation?.values['appVersionDate'] ? (
                                                        validation?.values['appVersionDate']
                                                    ) : (
                                                        '–'
                                                    )}
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter>
                                <Row className="p-0 m-0">
                                    <Col>
                                        <div className="gap-2 d-flex justify-content-end">
                                            <button type="reset" className="btn btn-light" onClick={() => history.push('/atvapps')}>
                                                Cancel
                                            </button>
                                            {formType !== 'view' && (
                                                <button
                                                    className="btn btn-success"
                                                    id="add-btn"
                                                    disabled={checkSaveDisable()}
                                                    onClick={() => handleSubmit()}
                                                >
                                                    {formType === 'edit' ? 'Update' : 'Save'}
                                                </button>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            </CardFooter>
                        </Card>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default AddAndroidTvApp;
